import React from 'react';
// Redux
import {
  Box,
} from '@material-ui/core';
import { Tab, Tabs } from './Styles';

interface ITabs {
  tab1: JSX.Element;
  tab2: JSX.Element;
}

interface TabPanelProps {
  children: JSX.Element;
  index: any;
  value: any;
}

function TabPanel( props: TabPanelProps ): JSX.Element {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          { children }
        </Box>
      )}
    </div>
  );
}

function a11yProps( index: number ): any {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabsGrade: React.FC<ITabs> = ( props ) => {
  const { tab1, tab2 } = props;
  const [value, setValue] = React.useState<number>( 0 );

  const handleChange = (
    event: React.ChangeEvent<any>,
    newValue: number,
  ): void => {
    setValue( newValue );
  };
  return (
    <>
      <Box display="flex" justifyContent="center">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable tabs"
        >
          <Tab label="Esquema" {...a11yProps( 0 )} />
          <Tab label="Calificaciones" {...a11yProps( 1 )} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {tab1}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {tab2}
      </TabPanel>
    </>
  );
};

export default TabsGrade;
