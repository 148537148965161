import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

import exam from '../../assets/svg/exam.svg';
import ownBook from '../../assets/svg/ownBook.svg';

const useStyles = makeStyles({
  cardContent: {
    textAlign: 'center',
  },
  paper: {
    borderRadius: '50%',
    minHeight: '7em',
    maxWidth: '7em',
    width: '7em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#69686F',
    background: 'transparent',
  },
  svgs: {
    maxHeight: '3em',
  },
  bold: {
    fontWeight: 'bold',
    marginTop: '2em',
  },
  card: {
    minHeight: '100%',
    '&:hover': {
      backgroundColor: '#bbbbbb2c',
    },
  },
});

interface ICardGradeType {
  handleOpenTransferList: any;
  handleOpenDirectForm: any;
}

const CardGradeType: React.FC<ICardGradeType> = ( props ) => {
  const { handleOpenTransferList, handleOpenDirectForm } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="center"
      spacing={4}
    >
      <Grid item xs={12} md={4}>
        <Card className={classes.card} onClick={handleOpenTransferList}>
          <CardContent className={classes.cardContent}>
            <Box display="flex" justifyContent="center">
              <Paper variant="outlined" className={classes.paper}>
                <img
                  src={ownBook}
                  className={classes.svgs}
                  alt="ownHomeworks"
                />
              </Paper>
            </Box>
            <Typography
              className={classes.bold}
              color="textPrimary"
            >
              Tareas enviadas
            </Typography>
            <Typography
              component="p"
              color="textPrimary"
            >
              Lista de las tareas enviadas durante el periodo actual.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card className={classes.card} onClick={handleOpenDirectForm}>
          <CardContent className={classes.cardContent}>
            <Box display="flex" justifyContent="center">
              <Paper variant="outlined" className={classes.paper}>
                <img src={exam} className={classes.svgs} alt="noOwnHomeworks" />
              </Paper>
            </Box>
            <Typography
              className={classes.bold}
              color="textPrimary"
            >
              Actividades externas
            </Typography>
            <Typography
              component="p"
              color="textPrimary"
            >
              Calificaciones que se han generado fuera de la plataforma.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CardGradeType;
