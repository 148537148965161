import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Material Ui
import { Grid } from '@material-ui/core';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
// components
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { actions } from '../../../redux/ducks/TeacherDucks';
import { RootState } from '../../../redux/store';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import LabelInfo from '../../../components/LabelInfo/LabelInfo';
import UserDefault from '../../../assets/img/user-photo-default.png';
import Image from '../../../components/Image/Image';
import CenterInLine from '../../../components/Center/CenterInLine';
import ContainerImageForm from '../../../components/ContainerImageForm/ContainerImageForm';
import Card from '../../../components/Card/Card';

interface Params {
  id: string;
}

const TeacherView: React.FC<InjectedFormProps> = () => {
  const dispatch = useDispatch();

  const params = useParams<Params>();

  const { teacherById: formData, loading } = useSelector(
    ( state: RootState ) => state.teacher,
  );

  useEffect(() => {
    if ( params.id ) {
      dispatch( actions.getTeacher( params.id ));
    }
  }, []);

  const handleGetStatus = ( status: boolean ): string => (
    status ? 'Activo' : 'Inactivo'
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'docentes', url: 'admin/docentes' }]}
              itemsText={['ver-docente']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          {!loading && formData && (
            <Grid container>
              <Grid item xs={12} sm={8}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Cédula" text={formData.identification} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Nombre" text={formData.name} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Apellido" text={formData.surname} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Correo" text={formData.email} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Teléfono" text={formData.phone} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo
                      label="Estado"
                      text={handleGetStatus( formData.status )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CenterInLine>
                  <ContainerImageForm>
                    {formData && <Image src={formData.photo || UserDefault} />}
                  </ContainerImageForm>
                </CenterInLine>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default TeacherView;
