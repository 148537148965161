import { db } from './firebase';

const COLLECTION_USER = 'users';

export const existUserByEmail = async ( email: string ): Promise<boolean> => {
  try {
    const user = await db.collection( COLLECTION_USER )
      .where( 'email', '==', email ).get();
    if ( user.empty ) {
      return false;
    }
    return true;
  } catch ( error ) {
    return false;
  }
};
