import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})( MuiAccordion );

export const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    // boxShadow: 'none!important',
    borderRadius: ' 0.5em!important',
    // marginBottom: 2,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },
  content: {
    margin: '0px 0px',
    '&$expanded': {
      margin: '0px 0px',
    },
  },
  expanded: {},
})( MuiAccordionSummary );

export const AccordionDetails = withStyles({
  root: {
    marginTop: '1em!important',
    background: '#eee',
  },
})( MuiAccordionDetails );

export const useStyles = makeStyles(() => ({
  noPadding: {
    padding: '0!important',
  },
  accordion: {
    background: '#eee',
  },
  squemeList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: 'center',
    rowGap: 10,
    width: '100%',
  },
  namePartial: {
    background: '#9ED8EF!important',
  },
  averagePartial: {
    background: '#fff',
  },
}));
