import React from 'react';

// Material UI
import {
  Box, CircularProgress,
} from '@material-ui/core';

const Spinner: React.FC = () => (
  <Box textAlign="center">
    <CircularProgress />
    <h3>Cargando...</h3>
  </Box>
);

export default Spinner;
