import React from 'react';

interface IFormReduxProps {
  children: any | any[];
  onSubmit: any;
}

const FormRedux: React.FC<IFormReduxProps> = ( props ) => {
  const { children, onSubmit } = props;

  return (
    <div style={{ margin: 10 }}>
      <form
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        {children}
      </form>
    </div>
  );
};

export default FormRedux;
