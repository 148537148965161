import React from 'react';

// Material Ui
import { Box } from '@material-ui/core';

import { useStyles } from './Styles';

const HeaderSubjectList: React.FC = () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
    >
      <Box className={classes.subject}>
        <span className={classes.title}>Asignaturas</span>
      </Box>
      <Box className={classes.calification}>
        <span className={classes.title}>Promedio</span>
      </Box>
    </Box>
  );
};

export default HeaderSubjectList;
