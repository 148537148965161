import React from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import {
  Grid,
} from '@material-ui/core';
import CenterInLine from '../Center/CenterInLine';
import FormRedux from '../FormRedux/FormRedux';
import Input from '../Input/Input';
import Button from '../Button/Button';
import {
  minLength8,
  required,
} from '../../helpers/validate-forms';
import ContainerForm from '../ContainerForm/ContainerForm';

const PasswordForm: React.FC<InjectedFormProps> = ( props ) => {
  const { handleSubmit, submitting } = props;

  return (
    <ContainerForm>
      <FormRedux onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Field
              name="currentPassword"
              type="password"
              label="Contraseña Actual"
              component={Input}
              placeholder="Contraseña"
              validate={[required, minLength8]}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="newPassword"
              type="password"
              label="Nueva Contraseña"
              component={Input}
              placeholder="Contraseña"
              validate={[required, minLength8]}
            />
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12}>
            <CenterInLine>
              <Button type="submit" disabled={submitting}>
                Enviar
              </Button>
            </CenterInLine>
          </Grid>
        </Grid>
      </FormRedux>
    </ContainerForm>
  );
};

export default reduxForm({
  form: 'password',
})( PasswordForm );
