import axios, { AxiosRequestConfig } from 'axios';
import { auth } from './firebase';

const BASE_URL = process.env.NODE_ENV === 'development'
  ? `/mybook-2c73d/us-central1/api`
  : '';

export const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use( async ( config ): Promise<AxiosRequestConfig> => {
  if ( auth.currentUser ) {
    const token = await auth.currentUser.getIdToken();
    Object.assign( config.headers, {
      ...config.headers,
      Authorization: `${token}`,
    });
    return config;
  }
  return config;
});
