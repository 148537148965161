import React from 'react';
import { useSelector } from 'react-redux';
import TestComponent from '../TestComponent/TestComponent';
import { RootState } from '../../../../../../redux/store';
import { IStudent } from '../../../../../../interfaces/IStudent';

interface IProps {
  idSubject: string;
}

const ReportsList: React.FC<IProps> = ( ) => {
  const { studentList } = useSelector(
    ( state: RootState ) => state.subject,
  );

  return (
    <div>
      {
        studentList.map(( student: IStudent, i: number ) => (
          <div key={i.toString()}>
            <TestComponent
              student={student}
            />
          </div>
        ))
      }
    </div>
  );
};

export default ReportsList;
