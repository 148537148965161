/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import {
  makeStyles, IconButton, TextField, Theme,
} from '@material-ui/core';
import * as IconM from '@material-ui/icons/';
import ErrorInput from '../ErrorInput/ErrorInput';

const useStyles = makeStyles(( theme: Theme ) => ({
  containerInput: {
    padding: 10,
  },
  input: {
    display: 'none',
  },
  button: {
    textTransform: 'initial',
  },
  block: {
    display: 'block',
    marginBottom: 10,
    color: theme.palette.error.main,
  },
}));

interface IInputFileProps {
  // eslint-disable-next-line react/require-default-props
  label?: string;
}

const FileInput: React.FC<WrappedFieldProps & IInputFileProps> = ( props ) => {
  const classes = useStyles();

  const { label, input, meta } = props;
  const { touched, error, warning } = meta;
  const { onChange } = input;
  const [valueInput, setValueInput] = useState( '' );
  const [labelValue, setLabelValue] = useState( label );

  const handleChange = ( e: ChangeEvent<HTMLInputElement> ): void => {
    if ( e.target.files && e.target.files.length > 0 ) {
      onChange( e.target.files[0]);
      setLabelValue( e.target.files[0].name );
    }
    setValueInput( e.target.value );
    // onChange( e.target.files ? e.target.files[0] : e.target.files );
  };

  return (
    <div>
      <TextField
        {...input}
        type="file"
        value={valueInput}
        onChange={handleChange}
        className={classes.input}
        id="contained-button-file"
      />
      <label htmlFor="contained-button-file">
        <IconButton
          color="primary"
          component="span"
        >
          <IconM.InsertDriveFile />
        </IconButton>
      </label>
      <span>{labelValue}</span>
      {touched
        && <ErrorInput error={error} warning={warning} />}
    </div>
  );
};

export default FileInput;
