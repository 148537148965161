import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ButtonMaterial from '@material-ui/core/Button';

const useStyles = makeStyles(( theme: Theme ) => ({
  button: ( data: any ) => ({
    border: '0px',
    borderRadius: 5,
    paddingLeft: '20px',
    paddingRight: '20px',
    width: data.block ? '100%' : undefined,
    color: '#fff',
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: getColorDark( data.color, theme ),
    },
    textTransform: 'none',
    backgroundColor: getColor( data.color, theme ),
  }),
}));

const getColor = ( color: TypeColor = 'primary', theme: Theme ): string => {
  const { main } = theme.palette[color];
  return main;
};

const getColorDark = ( color: TypeColor = 'primary', theme: Theme ): string => {
  const { dark } = theme.palette[color];
  return dark;
};

type TypeButton = 'button' | 'submit';
type TypeSize = 'small' | 'medium' | 'large' | undefined;
type TypeColor = 'primary' | 'secondary'
| 'error' | 'warning' | 'info' | 'success';
type TypeVariant = 'contained' | 'outlined';

interface IButtonProps {
  // eslint-disable-next-line react/require-default-props
  block?: boolean;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  type?: TypeButton;
  // eslint-disable-next-line react/require-default-props
  onClick?: any;
  // eslint-disable-next-line react/require-default-props
  size?: TypeSize;
  // eslint-disable-next-line react/require-default-props
  color?: TypeColor;
  // eslint-disable-next-line react/require-default-props
  variant?: TypeVariant
}

const Button: React.FC<IButtonProps> = ( props ) => {
  const {
    block,
    disabled = false,
    type = 'button',
    size = 'small',
    onClick,
    color,
    variant = 'contained',
    ...button
  } = props;

  const classes = useStyles({ block, color });

  return (
    <ButtonMaterial
      {...button}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      type={type === 'button' ? 'button' : 'submit'}
      className={classes.button}
      size={size}
    />
  );
};

export default Button;
