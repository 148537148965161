import { createStore, combineReducers, applyMiddleware } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
// reducers
import LoginReducer from './ducks/LoginDucks';
import bookReducer from './ducks/BookDucks';
import teacherReducer from './ducks/TeacherDucks';
import studentReducer from './ducks/StudentDucks';
import courseReducer from './ducks/CourseDucks';
import instituteReducer from './ducks/InstituteDucks';
import activityReducer from './ducks/ActivityDucks';
import taskReducer from './ducks/TaskDucks';
import subjectReducer from './ducks/SubjectDucks';
import unitReducer from './ducks/UnitDucks';
import schemeReducer from './ducks/SchemeDucks';

// services
import services from '../services';

const rootReducer = combineReducers({
  login: LoginReducer,
  form: formReducer,
  book: bookReducer,
  student: studentReducer,
  teacher: teacherReducer,
  course: courseReducer,
  institute: instituteReducer,
  activity: activityReducer,
  task: taskReducer,
  subject: subjectReducer,
  unit: unitReducer,
  scheme: schemeReducer,
});

export default function generateStore(): any {
  const store = createStore( rootReducer,
    composeWithDevTools(
      applyMiddleware( thunk.withExtraArgument( services )),
    ));
  return store;
}

export type RootState = ReturnType<typeof rootReducer>
