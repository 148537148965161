import React, { useEffect, useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';
import DialogConfirm from '../../../components/DialogConfirm/DialogConfirm';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import TableSubject from '../../../components/TableSubject/TableSubject';
import {
  getSubjectsAllThunk,
  subjectDeleteThunk,
  getSubjectByIdThunk,
  updateSubjectBooksThunk,
  updateSubjectScheduleThunk,
} from '../../../redux/ducks/SubjectDucks';
import { actions as actionsCourse } from '../../../redux/ducks/CourseDucks';
import { actions as actionsTeacher } from '../../../redux/ducks/TeacherDucks';
import { actions as actionsBook } from '../../../redux/ducks/BookDucks';
import Dialog from '../../../components/Dialog/Dialog';
// eslint-disable-next-line max-len
import TransferListAny from '../../../components/TransferList/TransferListAny';
import Schedule from '../../../components/ScheduleIntegrate/Schedule';
import Card from '../../../components/Card/Card';

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const SubjectList: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { subjects, loading, formData } = useSelector(
    ( state: RootState ) => state.subject,
  );
  const { books: booksDB } = useSelector(
    ( state: RootState ) => state.book,
  );
  const { teachers } = useSelector(( state: RootState ) => state.teacher );
  const [open, setOpen] = useState( false );
  const [openAddBook, setOpenAddBook] = useState( false );
  const [openSchedule, setOpenSchedule] = useState( false );
  const [closedTransfer, setClosedTransfer] = useState( false );
  const [idDoc, setIdDoc] = useState( '' );

  useEffect(() => {
    dispatch( getSubjectsAllThunk());
    dispatch( actionsCourse.getCourses());
    dispatch( actionsTeacher.getTeachers());
    dispatch( actionsBook.getBooks());
  }, []);

  useEffect(() => {
    dispatch( getSubjectByIdThunk( idDoc ));
  }, [idDoc]);

  const handleOpenConfirm = ( id: string ): void => {
    setIdDoc( id );
    setOpen( true );
  };

  const handleDelete = ( confirm: boolean ): void => {
    if ( confirm ) {
      dispatch( subjectDeleteThunk( idDoc ));
    }
    setOpen( false );
  };

  const handleNew = (): void => {
    history.push( `/admin/materias/agregar` );
  };

  const handleEdit = ( id: string ): void => {
    history.push( `/admin/materias/editar/${id}` );
  };

  const handleShow = ( id: string ): void => {
    history.push( `/admin/materias/ver/${id}` );
  };

  const handleShowOpenAddBook = ( id: string ): void => {
    setIdDoc( id );
    setOpenAddBook( !openAddBook );
    setClosedTransfer( false );
  };

  const handleSaveData = ( data: string[]): void => {
    dispatch( updateSubjectBooksThunk( idDoc, data ));
    dispatch( getSubjectByIdThunk( idDoc ));
  };

  const handleOpenSchedule = ( id: string ): void => {
    setIdDoc( id );
    setOpenSchedule( true );
  };

  const handlerSubmitSchedule = (
    values: any, indexDay: number,
  ): void => {
    const day = days[indexDay];
    const data = {
      ...formData.schedule,
      [day]: { ...values },
    };
    dispatch( updateSubjectScheduleThunk( idDoc, data ));
    dispatch( getSubjectByIdThunk( idDoc ));
  };

  const closeTransferList = (): void => {
    setOpenAddBook( false );
    setClosedTransfer( true );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              back={false}
              itemsLinks={[{ name: 'Materias', url: 'admin/materias' }]}
              itemsText={['Todas las materias']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <TableSubject
            data={subjects}
            handleNew={handleNew}
            handleShow={handleShow}
            handleDelete={handleOpenConfirm}
            handleEdit={handleEdit}
            handleOpenAdd={handleShowOpenAddBook}
            handleOpenSchedule={handleOpenSchedule}
            teachers={teachers}
          />
        </Card>
      </Grid>
      <Dialog
        open={openAddBook}
        title="Agregar un libro"
        onClose={() => closeTransferList()}
        size="md"
      >
        {formData && (
          <TransferListAny
            itemsRIGHT={formData.books}
            itemsLEFT={booksDB}
            handleSaveData={handleSaveData}
            handleDeleteData={handleSaveData}
            loading={loading}
            closedTransfer={closedTransfer}
          />
        )}
      </Dialog>
      <Dialog
        open={openSchedule}
        title="Editar horario"
        onClose={() => setOpenSchedule( false )}
        size="md"
      >
        {formData && (
          <Paper>
            <Schedule
              onSave={handlerSubmitSchedule}
              data={formData ? formData.schedule : undefined}
            />
          </Paper>
        )}
      </Dialog>
      <DialogConfirm
        open={open}
        title="¿ Desea eliminar la materia ?"
        onClose={handleDelete}
      />
    </Grid>
  );
};
export default SubjectList;
