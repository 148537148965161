import React, { useEffect } from 'react';
// Interface
import { useDispatch, useSelector } from 'react-redux';
// Redux
import {
  Grid,
} from '@material-ui/core';
import { RootState } from '../../../../redux/store';
import {
  getSubjectsByTeacherIdThunk,
} from '../../../../redux/ducks/SubjectDucks';
import {
  actions as actionsTeachers,
} from '../../../../redux/ducks/TeacherDucks';
// Components
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import SubjectGradeItemTeacher from '../../../../components/SubjectGradeItemTeacher/SubjectGradeItemTeacher';
import { getAllTaskByCurrentDayCalendarAndTeacherIdThunk } from '../../../../redux/ducks/TaskDucks';
import { ITask } from '../../../../interfaces/ITask';
import TaskItemTeacher from '../../../../components/TaskItemTeacher/TaskItemTeacher';
import Spinner from '../../../../components/Spinner/Spinner';
import MessageCard from '../../../../components/MessageCard/MessageCard';

const TeacherSubjectDay: React.FC = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((
    state: RootState,
  ) => state.login );

  const { subjects } = useSelector(( state: RootState ) => state.subject );

  const tasks: ITask[] = useSelector(
    ( state: RootState ) => state.task.taskByDay,
  );

  const { loading: loadingSubjects } = useSelector(
    ( state: RootState ) => state.subject,
  );

  const { loading: loadingTask } = useSelector(
    ( state: RootState ) => state.task,
  );

  useEffect(() => {
    dispatch( actionsTeachers.getTeachers());
    // dispatch( actionsBooks.getBooks());
    dispatch( getSubjectsByTeacherIdThunk( userData.id ));
    dispatch( getAllTaskByCurrentDayCalendarAndTeacherIdThunk( userData.id ));
  }, []);

  return (
    <>
      <ContainerBreadcrumbs>
        <Breadcrumbs
          back={false}
          itemsLinks={[{ name: 'Materias', url: 'docente/inicio' }]}
          itemsText={['Todas tus materias']}
        />
      </ContainerBreadcrumbs>
      <Grid container spacing={4} direction="row" justify="center">
        {loadingSubjects ? (
          <Spinner />
        ) : (
          <>
            {subjects && subjects.length > 0 ? (
              <>
                <Grid item xs={12} md={6}>
                  {subjects && subjects.length > 0 && subjects.map(( subject ) => (
                    <SubjectGradeItemTeacher grades={false} subject={subject} key={subject.id} />
                  ))}
                </Grid>
                <Grid item xs={12} md={6}>
                  {loadingTask ? (
                    <Spinner />
                  ) : (
                    <>
                      {tasks && tasks.length > 0 ? tasks.map(( task, i: number ) => (
                        <Grid item xs={12} key={i.toString()}>
                          <TaskItemTeacher task={task} />
                        </Grid>
                      )) : (
                        <MessageCard>
                          <h3>No existen tareas para hoy.</h3>
                        </MessageCard>
                      )}
                    </>
                  )}
                </Grid>
              </>
            ) : (
              <MessageCard>
                <h3>No hay materias asignadas.</h3>
              </MessageCard>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default TeacherSubjectDay;
