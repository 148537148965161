import React, { useEffect } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Paper,
  List,
  Grid,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import Spinner from '../../../../../../components/Spinner/Spinner';
import SearchBar from '../../../../../../components/SearchBar/SearchBar';
import { IBook } from '../../../../../../interfaces/IBook';
import { IActivity } from '../../../../../../interfaces/IActivity';

const useStyles = makeStyles(( theme: Theme ) => createStyles({
  root: {
    margin: 'auto',
  },
  paper: {
    minHeight: 300,
    minWidth: 200,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing( 0.5, 0 ),
  },
}));

interface IProps {
  // eslint-disable-next-line react/require-default-props
  bookId?: string;
  // eslint-disable-next-line react/require-default-props
  unitId?:string;
  // eslint-disable-next-line react/require-default-props
  itemsRIGHT?: string[];
  // eslint-disable-next-line react/require-default-props
  itemsLEFT?: any[] | null;
  handleSaveData: any;
  handleDeleteData: any;
  // eslint-disable-next-line react/require-default-props
  loading?: boolean;
}

function not( a: string[], b: string[]): string[] {
  return a.filter(( value ) => b.indexOf( value ) === -1 );
}

function intersection( a: string[], b: string[]): string[] {
  return a.filter(( value ) => b.indexOf( value ) !== -1 );
}

const TransferListActivities: React.FC<IProps> = ( props ) => {
  const {
    itemsRIGHT = [],
    itemsLEFT = [],
    handleSaveData,
    handleDeleteData,
    bookId = '',
    loading,
    unitId = '',
  } = props;

  const classes = useStyles();
  const [checked, setChecked] = React.useState<string[]>([]);
  const [left, setLeft] = React.useState<string[]>([]);
  const [right, setRight] = React.useState<string[]>([]);
  const [input, setInput] = React.useState( '' );

  const leftChecked = intersection( checked, left );
  const rightChecked = intersection( checked, right );

  const filterObjects = ( data: string ): void => {
    if ( itemsLEFT ) {
      const bookFound = itemsLEFT.filter(
        ( item: any ) => item.name.toLowerCase().includes( data.toLowerCase()),
      );
      if ( bookFound.length > 0 ) {
        const onlyIds = bookFound.map(( item: any ) => item.id );
        const items = onlyIds.filter(
          ( item: any ) => !right.includes( item ),
        );
        setLeft( items );
      } else {
        const onlyIds = itemsLEFT.map(( item: any ) => item.id );
        const items = onlyIds.filter(
          ( item: any ) => !right.includes( item ),
        );
        setLeft( items );
      }
      setInput( data );
    }
  };

  useEffect(() => {
    if ( itemsLEFT && itemsRIGHT ) {
      const arrayLeft = itemsLEFT.filter(
        ( item: IActivity ) => item.bookId === bookId && item.unitId === unitId,
      );
      if ( arrayLeft.length > 0 ) {
        const aloneIds = arrayLeft.map(
          ( x: IActivity ) => x.id,
        );
        let leftItems: any[] = [];
        leftItems = aloneIds.filter(
          ( item: any ) => !itemsRIGHT.includes( item ),
        );
        setLeft( leftItems );
        setRight( itemsRIGHT );
        setChecked([]);
        return;
      }
      setLeft([]);
      setRight([]);
    }
  }, [props]);

  const handleGetNameLeft = ( id: string ): string => {
    if ( itemsLEFT ) {
      const item = itemsLEFT.find(( x: IBook ) => x.id === id );
      return item ? `${item.name}` : '';
    }
    return '';
  };

  const handleToggle = ( value: string ) => () => {
    const currentIndex = checked.indexOf( value );
    const newChecked = [...checked];

    if ( currentIndex === -1 ) {
      newChecked.push( value );
    } else {
      newChecked.splice( currentIndex, 1 );
    }
    setChecked( newChecked );
  };

  const handleCheckedRight = (): void => {
    setRight( right.concat( leftChecked ));
    setLeft( not( left, leftChecked ));
    setChecked( not( checked, leftChecked ));
    const dataToSave = leftChecked.concat( right );
    handleSaveData( dataToSave );
  };

  const handleCheckedLeft = (): void => {
    setLeft( left.concat( rightChecked ));
    setRight( not( right, rightChecked ));
    setChecked( not( checked, rightChecked ));
    const dataToSave = not( right, rightChecked );
    handleDeleteData(
      dataToSave, rightChecked,
    );
  };

  const customList = ( items: any[]): any => (
    <Paper className={classes.paper}>
      {!loading
        ? (
          <List dense component="div" role="list">
            {items.map(( value: string, index: number ) => {
              const labelId = `transfer-list-item-${value}-label`;
              return (
                <ListItem
                  key={index.toString()}
                  role="listitem"
                  button
                  onClick={handleToggle( value )}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf( value ) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={handleGetNameLeft( value )}
                  />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        )
        : <Spinner />}
      { !loading && items.length === 0 && <span>Sin datos</span>}
    </Paper>
  );

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xl={12} sm={12}>
        <SearchBar keyword={input} setKeyword={filterObjects} />
      </Grid>
      <Grid item xl={12} sm={5}>
        {customList( left )}
      </Grid>
      <Grid item xl={12} sm={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xl={12} sm={5}>
        {customList( right )}
      </Grid>
    </Grid>
  );
};

export default TransferListActivities;
