import React, { useEffect, useState } from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';
// material ui
import CircularProgress from '@material-ui/core/CircularProgress';
// React-Toastify
import { ToastContainer } from 'react-toastify';
// components
import { useSelector, useDispatch } from 'react-redux';
import Center from '../components/Center/Center';
import { NotFound } from '../components/errors';

// services
import services from '../services';

// redux
import { actions, getUserByIdThunk } from '../redux/ducks/LoginDucks';
import { RootState } from '../redux/store';

// interfaces
import { IUser } from '../interfaces/IUser';

// login
import Login from '../views/Login/Login';

// routes type
import RoutePrivate from './RoutePrivate';

// User - Profile
import Profile from '../views/User/Profile';
import { routesList, IRoute } from './RoutesList';

const Routes: React.FC = () => {
  const { auth } = services;
  const {
    loading,
    userData,
  } = useSelector(( state: RootState ) => state.login );
  const [typeUser, setTypeUser] = useState<number>( 0 );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch( actions.loginRequest());
    auth.onAuthStateChanged(( user ) => {
      const { location } = history;
      if ( user ) {
        if (['/'].indexOf( location.pathname ) > -1 ) {
          dispatch( getUserByIdThunk( user.uid ));
          return;
        }

        dispatch( actions.loginSuccess());
        dispatch( getUserByIdThunk( user.uid ));
      } else {
        if ( /admin\/./.test( location.pathname )
            || /estudiante\/./.test( location.pathname )
            || /docente\/./.test( location.pathname )
            || /perfil/.test( location.pathname )) {
          handleRoute( '/' );
        }
        dispatch( actions.loginSuccess());
      }
    });
  }, []);

  useEffect(() => {
    const user = userData as IUser;
    if ( user ) {
      setTypeUser( user.type );
      if ( user.type === 1 ) {
        handleRoute( '/admin/inicio' );
      }
      if ( user.type === 2 ) {
        handleRoute( '/docente/inicio' );
      }
      if ( user.type === 3 ) {
        handleRoute( '/estudiante/inicio' );
      }
    }
  }, [userData]);

  const handleRoute = ( route: string ): void => {
    history.push( route );
  };

  return (
    <>
      {
        loading
          ? (
            <Center>
              <CircularProgress />
              <h3>Cargando...</h3>
            </Center>
          )
          : (
            <Switch>
              <Route exact path="/" component={Login} />
              {routesList.map(( route: IRoute, i: number ) => (
                route.type.includes( typeUser ) && (
                  <RoutePrivate
                    key={i.toString()}
                    exact
                    path={route.path}
                    component={route.component}
                  />
                )
              ))}
              <RoutePrivate
                exact
                path="/perfil"
                component={Profile}
              />
              <Route component={NotFound} />
            </Switch>
          )
      }
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default Routes;
