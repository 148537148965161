import React, { useEffect } from 'react';

// Material Ui
import {
  Card,
  CardActions,
  makeStyles,
  Grid,
  Box,
  Fab,
  Avatar,
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ISubject } from '../../interfaces/ISubject';
import { actions } from '../../redux/ducks/TeacherDucks';
import { RootState } from '../../redux/store';
import ToolTip from '../ToolTip/ToolTip';

interface ISubjectClassUrlItemStudentProps {
  subject: ISubject;
}

const useStyles = makeStyles({
  shadow: {
    boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
  },
  cardActionsStudent: {
    padding: '1em 2em 1em 2em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  card: {
    minHeight: '100%',
  },
  playButton: {
    color: '#fff',
  },
  teacherText: {
    margin: '0',
    fontWeight: 'bold',
  },
  playButtonText: {
    fontWeight: 'bold',
    marginBottom: '0',
  },
  large: {
    width: '5.6em',
    height: '5.6em',
  },
  avatar: {
    width: '100%',
    height: '100%',
  },
});

const SubjectClassUrlItemStudent: React.FC<ISubjectClassUrlItemStudentProps> = ( props ) => {
  const {
    subject,
  } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const { teacherById } = useSelector(( state: RootState ) => state.teacher );

  useEffect(() => {
    dispatch( actions.getTeacher( subject.teacherId ));
  }, [props]);

  return (
    <Grid item xs={12} sm={5} md={4}>
      <Card className={`${classes.shadow} ${classes.card}`}>
        <CardActions className={classes.cardActionsStudent}>
          <Box display="block" textAlign="center">
            <Box className={classes.large}>
              <Avatar
                className={classes.avatar}
                src={teacherById && teacherById.photo}
              />
            </Box>
            <p className={classes.teacherText}>
              {teacherById && teacherById.name}
            </p>
          </Box>
          <Box display="block" textAlign="center" mt={1}>
            <a
              href={subject.urlZoom}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ToolTip
                title={
                  subject.urlZoom ? 'Ir a Zoom!' : 'No hay link a la sala'
                }
              >
                <Fab
                  className={classes.playButton}
                  color="secondary"
                  aria-label="goToUrl"
                  disabled={!subject.urlZoom}
                >
                  <PlayArrowIcon />
                </Fab>

              </ToolTip>
            </a>
            <p className={classes.playButtonText}>Enlace de la clase</p>
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default SubjectClassUrlItemStudent;
