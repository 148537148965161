import React from 'react';
import {
  reduxForm, InjectedFormProps, Field,
} from 'redux-form';
import { Box, Grid } from '@material-ui/core';
import {
  required, minLength5, maxLength30, lessThan100Validator,
} from '../../helpers/validate-forms';
import Input from '../Input/Input';
import Button from '../Button/Button';
import ContainerForm from '../ContainerForm/ContainerForm';
import FormRedux from '../FormRedux/FormRedux';
import ContainerInputs from '../ContainerInputs/ContainerInputs';

const SchemeForm: React.FC<InjectedFormProps> = ( props ) => {
  const {
    handleSubmit, submitting,
  } = props;

  return (
    <ContainerForm>
      <FormRedux
        onSubmit={handleSubmit}
      >
        <ContainerInputs>
          <Grid item xs={12}>
            <Field
              name="name"
              type="text"
              component={Input}
              placeholder="Nombre"
              validate={[required, minLength5, maxLength30]}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="description"
              type="text"
              component={Input}
              placeholder="Descripción"
              validate={[required, minLength5, maxLength30]}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="percent"
              type="number"
              component={Input}
              placeholder="Porcentaje"
              validate={[required, lessThan100Validator]}
            />
          </Grid>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button type="submit" disabled={submitting}>
              Guardar
            </Button>
          </Box>
        </ContainerInputs>
      </FormRedux>
    </ContainerForm>
  );
};

export default reduxForm({
  form: 'scheme',
  enableReinitialize: true,
})( SchemeForm );
