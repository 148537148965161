import React, { useEffect } from 'react';
import { useParams } from 'react-router';
// Material Ui
import {
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
// Interface
import { useDispatch, useSelector } from 'react-redux';
// Redux
import { RootState } from '../../../../redux/store';
import { actions } from '../../../../redux/ducks/UnitDucks';

interface IParams {
  unitId: string;
  topicId: string;
}

const StudentActivity: React.FC = () => {
  const { unitId, topicId } = useParams<IParams>();
  const dispatch = useDispatch();

  const topicById: any = useSelector(
    ( state: RootState ) => state.unit.topicById,
  );

  useEffect(() => {
    dispatch( actions.getTopicById( unitId, topicId ));
  }, []);

  return (
    <>
      {topicById && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card style={{ height: '86vh' }}>
              <CardContent style={{ height: '86vh' }}>
                <h2>
                  Tema:
                  {' '}
                  {topicById.name}
                </h2>
                <iframe
                  width="100%"
                  height="93%"
                  src={topicById.url}
                  title={topicById.name}
                  seamless={false}
                  // allowTransparency
                  frameBorder="0"
                  allowFullScreen
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StudentActivity;
