import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 10px',
    borderRadius: 10,
    backgroundColor: '#E7E6E6',
  },
  title: {
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
    fontSize: 18,
  },
  titleItem: {
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: 5,
  },
  rubric: {
    flex: 1,
  },
  calification: {
    flex: 0.3,
    backgroundColor: '#fff',
    width: 50,
    height: 30,
    borderRadius: 10,
    padding: 5,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  itemRubric: {
    marginBottom: 10,
  },
  footer: {
    marginTop: 10,
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: '10px 5px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    fontSize: 18,
  },
}));

export default useStyles;
