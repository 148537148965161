import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Material Ui
import { Grid } from '@material-ui/core';
// Interface
import { useDispatch, useSelector } from 'react-redux';
import { IUnit } from '../../../interfaces/IUnit';
// Redux
import { RootState } from '../../../redux/store';
import { actions } from '../../../redux/ducks/UnitDucks';
// Components
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import LabelInfo from '../../../components/LabelInfo/LabelInfo';
import Card from '../../../components/Card/Card';

interface Params {
  id: string;
}

const UnitView: React.FC = () => {
  const params = useParams<Params>();

  const dispatch = useDispatch();

  const unitById: IUnit = useSelector(
    ( state: RootState ) => state.unit.unitById,
  );

  useEffect(() => {
    if ( params.id ) {
      dispatch( actions.getUnit( params.id ));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'Unidades', url: 'admin/unidades' }]}
              itemsText={['Ver Unidad']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>

          {unitById && (
            <Grid container>
              <Grid item xs={12} sm={8}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Nombre" text={unitById.name} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Libro" text={unitById.book.name} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h3>Temas</h3>
                    {unitById.topics?.map(( topic ) => (
                      <div key={topic.name}>
                        <LabelInfo label="Nombre" text={topic.name} />
                        <LabelInfo label="Url" text={topic.url} />
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default UnitView;
