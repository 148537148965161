import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Grid } from '@material-ui/core';
import BoxResponse from '../BoxQuestion/BoxQuestion';
import { IQuestion } from '../../../../../../interfaces/IQuestion';
import useStyles from './Styles';

interface IProps {
  data: IQuestion;
  response: string;
}

const ItemDragAndDrop: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const { data, response } = props;

  return (
    <div className={classes.root}>
      <DndProvider backend={HTML5Backend}>
        <Grid container wrap="wrap">
          <Grid item xs={12} sm={8}>
            <BoxResponse
              title={data.sentence}
              id="question"
              data={data}
              response={response}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <BoxOptions title="Opciones" id="options">
              {returnItemsForColumn( 'options' )}
            </BoxOptions>
          </Grid> */}
        </Grid>
      </DndProvider>
    </div>
  );
};

export default ItemDragAndDrop;
