import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  shadow: {
    boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
      rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
      rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
  },
  cardHeader: {
    padding: '1em 2em 1em 2em',
    minHeight: '3.5em',
    maxHeight: '3.5em',
  },
  cardActions: {
    padding: '1em 2em 1em 2em',
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    minHeight: '100%',
  },
  playButton: {
    backgroundColor: '#FECA19',
    color: '#fff',
  },
  textBold: {
    fontWeight: 'bold',
  },
  exclamationIcon: {
    width: '2.5em',
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  overlay: {
    display: 'block',
    backgroundColor: 'rgba(0,0,0,.45)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 10,
  },
  paddingStatus: {
    margin: '0 24px 0 24px!important',
  },
  paperColor: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0.1em',
    height: '1.5em!important',
    maxHeight: '1.5em!important',
  },
  svgEdit: {
    maxWidth: '1.3em',
  },
  svgDelete: {
    maxWidth: '1.5em',
  },
  titleContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    whiteSpace: 'nowrap',
    maxWidth: '8em',
  },
  exclamationText: {
    margin: '0',
    fontWeight: 'bold',
    color: 'white',
  },
  svg: {
    maxWidth: '3em',
    minWidth: '3em',
  },
  fabEdit: {
    padding: '0!important',
    margin: '0!important',
    maxHeight: '1em',
    width: '2.6em',
  },
  fabEditContainer: {
    maxWidth: '3em',
  },
});
