import React from 'react';
import DialogMaterial from '@material-ui/core/Dialog';
import ButtonMaterial from '@material-ui/core/Button';
import { DialogTitle, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../Button/Button';

const useStyles = makeStyles(() => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  footerDialog: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
    columnGap: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  description: {
    padding: '5px 20px',
    textAlign: 'center',
  },
  buttonCancel: {
    textTransform: 'none',
    borderRadius: 5,
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}));

type TypeSizeDialog = 'xs' | 'sm' | 'md';

interface IDialogProps {
  onClose: any;
  // eslint-disable-next-line react/require-default-props
  open?: boolean;
  title: string;
  // eslint-disable-next-line react/require-default-props
  description?: string;
  // eslint-disable-next-line react/require-default-props
  size?: TypeSizeDialog;
  // eslint-disable-next-line react/require-default-props
  textOk?: string;
  // eslint-disable-next-line react/require-default-props
  textCancel?: string;
}

const DialogConfirm : React.FC<IDialogProps> = ( props ) => {
  const classes = useStyles();
  const {
    onClose,
    open = false,
    title,
    description,
    size = 'sm',
    textOk = 'Aceptar',
    textCancel = 'Cancelar',
  } = props;

  const handleClose = ( option = false ): void => {
    onClose( option );
  };

  return (
    <DialogMaterial
      onClose={() => handleClose()}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth={size}
    >
      <div className={classes.body}>
        <DialogTitle id="simple-dialog-title">
          <span className={classes.title}>{ title }</span>
        </DialogTitle>
        <Box className={classes.description}>
          <span>{description}</span>
        </Box>
        <Box className={classes.footerDialog}>
          <ButtonMaterial
            variant="outlined"
            onClick={() => handleClose( false )}
            size="small"
            color="primary"
            className={classes.buttonCancel}
          >
            {textCancel}
          </ButtonMaterial>
          <Button onClick={() => handleClose( true )}>
            {textOk}
          </Button>
        </Box>
      </div>
    </DialogMaterial>
  );
};

export default DialogConfirm;
