import { db } from './firebase';
import { IBook } from '../interfaces/IBook';

export const getBookById = async ( id: string ): Promise<any> => {
  try {
    const collection = await db.collection( 'books' )
      .doc( id ).get();
    let book = {};
    if ( collection.exists ) {
      const data = collection.data() as IBook;
      book = { ...data, id };
    }
    return book;
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    return {};
  }
};
