import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
// components
import UnitForm from '../../../components/UnitForm/UnitForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { actions } from '../../../redux/ducks/UnitDucks';
import { RootState } from '../../../redux/store';
import { IUnit } from '../../../interfaces/IUnit';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import { IBook } from '../../../interfaces/IBook';
import Spinner from '../../../components/Spinner/Spinner';
import Card from '../../../components/Card/Card';

interface Params {
  id: string;
}

const UnitEdit: React.FC<InjectedFormProps> = () => {
  const params = useParams<Params>();

  const dispatch = useDispatch();

  const history = useHistory();

  const { unitById, loading } = useSelector(
    ( state: RootState ) => state.unit,
  );

  const booksInStore: IBook[] = useSelector(
    ( state: RootState ) => state.book.books,
  );

  const handleSubmit = ( values: any ): void => {
    const book: any = booksInStore.find(
      ( item: IBook ) => item.id === values.bookId,
    );
    const { ...data } = values;
    const unit = { ...data } as IUnit;
    dispatch( actions.updateUnit( params.id, unit, book ));
    return history.push( '/admin/unidades' );
  };

  useEffect(() => {
    if ( params.id ) {
      dispatch( actions.getUnit( params.id ));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'Unidades', url: 'admin/unidades' }]}
              itemsText={['Editar Unidad']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          { !loading
            ? <UnitForm onSubmit={handleSubmit} initialValues={unitById} />
            : (
              <Spinner />
            )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default UnitEdit;
