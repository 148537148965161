import React from 'react';
import AlertMaterial from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  alert: {
    marginBottom: 10,
  },
}));

type TypeAlert = 'error' | 'info' | 'warning' | 'success';

interface IAlertProps {
  errors: string;
  // eslint-disable-next-line react/require-default-props
  type?: TypeAlert;
}

const Alert: React.FC<IAlertProps> = ( props ) => {
  const classes = useStyles();
  const { errors, type } = props;

  return (
    <div className={classes.alert}>
      <AlertMaterial severity={type}>{errors}</AlertMaterial>
    </div>
  );
};

export default Alert;
