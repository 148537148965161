import React from 'react';
import { TableHead, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(( theme: Theme ) => ({
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
}));

interface IHeaderTableProps {
  children: JSX.Element | JSX.Element[];
}

const HeaderTable: React.FC<IHeaderTableProps> = ( props ) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <TableHead className={classes.tableHead}>
      { children }
    </TableHead>
  );
};

export default HeaderTable;
