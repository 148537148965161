import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  squeme: {
    width: '80%',
    // backgroundColor: 'red',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 20,
  },
  comlumn1: {
    flex: 1,
    minHeight: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '5px 10px',
    borderRadius: 5,
  },
  comlumn2: {
    flex: 0.2,
    minHeight: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
}));
