import React, { useState } from 'react';
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@material-ui/core';
import {
  change,
  initialize,
} from 'redux-form';
import { useDispatch } from 'react-redux';
import * as IconM from '@material-ui/icons/';
import IconButton from '@material-ui/core/IconButton';
import OpenQuestionForm from './OpenQuestionForm';
import { IQuestion } from '../../../interfaces/IQuestion';

const useStyles = makeStyles(() => ({
  containerList: {
    maxHeight: 200,
    overflowY: 'scroll',
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10,
  },
}));

interface IListOpenQuestionProps {
  // eslint-disable-next-line react/require-default-props
  handleSave?: any;
  questions: IQuestion[];
  // eslint-disable-next-line react/require-default-props
  handleUpdate?: any;
  // eslint-disable-next-line react/require-default-props
  handleDelete?: any;
}

const ListOpenQuestion: React.FC<IListOpenQuestionProps> = ( props ) => {
  const classes = useStyles();
  const {
    handleSave, questions = [], handleUpdate, handleDelete,
  } = props;
  const [form, setForm] = useState<any>( undefined );
  const [idSudDoc, setIdSudDoc] = useState( '' );
  const dispatch = useDispatch();

  const handleSaveQuestion = ( values: any ): void => {
    const question: IQuestion = {
      id: '',
      sentence: values.sentence,
      type: 'openQuestion',
    };
    if ( !idSudDoc ) {
      handleSave( question );
    } else {
      question.id = idSudDoc;
      handleUpdate( question );
    }
    dispatch( initialize( 'open-question', undefined ));
  };

  const handleEdit = ( data: IQuestion ): void => {
    setIdSudDoc( data.id || '' );
    if ( form?.sentence !== data.sentence ) {
      dispatch( initialize( 'open-question', undefined ));
      setForm({ sentence: data.sentence });
    } else {
      dispatch( change( 'open-question', 'sentence', data.sentence ));
    }
  };

  const handleRemove = ( id: string ): void => {
    handleDelete( id );
  };

  return (
    <div>
      <OpenQuestionForm onSubmit={handleSaveQuestion} initialValues={form} />
      <div className={classes.containerList}>
        {questions.map(( item: IQuestion, i: number ) => (
          <div key={i.toString()}>
            <List dense component="span">
              <ListItem>
                <ListItemIcon>
                  {`${i + 1}.-`}
                </ListItemIcon>
                <ListItemText primary={item.sentence} />
                <ListItemIcon>
                  <IconButton onClick={() => handleEdit( item )}>
                    <IconM.Edit />
                  </IconButton>
                </ListItemIcon>
                <ListItemIcon>
                  <IconButton onClick={() => handleRemove( item.id || '' )}>
                    <IconM.Delete />
                  </IconButton>
                </ListItemIcon>
              </ListItem>
            </List>
            <Divider />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListOpenQuestion;
