import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import {
  reduxForm, InjectedFormProps, Field, initialize,
} from 'redux-form';
import { useDispatch } from 'react-redux';
import Button from '../../Button/Button';
import FormRedux from '../../FormRedux/FormRedux';
import { required } from '../../../helpers/validate-forms';
import Input from '../../Input/Input';

const useStyles = makeStyles(() => ({
  items: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttonContainer: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10,
  },
}));

const OpenQuestionForm:
React.FC<InjectedFormProps> = ( props ) => {
  const classes = useStyles();
  const { handleSubmit } = props;
  const dispatch = useDispatch();

  const handleReset = (): void => {
    dispatch( initialize( 'open-question', undefined ));
  };

  return (
    <FormRedux
      onSubmit={handleSubmit}
    >
      <Grid container className={classes.items} spacing={1}>
        <Grid item xs={12} sm={7} md={8}>
          <Field
            name="sentence"
            type="text"
            label="Oración a completar"
            component={Input}
            validate={[required]}
            multiline
          />
        </Grid>
        <Grid item xs={6} sm={2} md={1} className={classes.buttonContainer}>
          <Button type="submit">
            Guardar
          </Button>
          <Button color="error" onClick={handleReset}>
            Limpiar
          </Button>
        </Grid>
      </Grid>
    </FormRedux>
  );
};

export default reduxForm({
  form: 'open-question',
})( OpenQuestionForm );
