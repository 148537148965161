import * as XLSX from 'xlsx';

export const handleReadFile = (
  file: File, sheetName: string,
): Promise<any[]> => new Promise<any[]>(( resolve ) => {
  const reader = new FileReader();
  reader.readAsBinaryString( file );
  reader.onload = ( e ) => {
    const data = e.target?.result;
    const workbook = XLSX.read( data, { type: 'binary' });
    // const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const dataSheet = XLSX.utils.sheet_to_json( sheet );
    resolve( dataSheet );
  };
});

export const writeFile = (
  idTable: string, sheetName: string, fileName: string, period: string,
): Promise<any[]> => new Promise<any[]>(( resolve ) => {
  const ws = XLSX.utils.table_to_sheet( document.getElementById( idTable ));
  if ( ws.C2 ) {
    ws.C2.t = 's';
    ws.C2.v = period;
  }
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet( wb, ws, sheetName );
  const file = XLSX.writeFile( wb, `${fileName}.xlsx` );
  resolve( file );
});

// workbook.SheetNames.forEach(( sheet ) => {
//   /* Get first worksheet */
//   const wsname = sheet;
//   const ws = workbook.Sheets[wsname];
//   const rowOject = XLSX.utils.sheet_to_json( ws );
//   // eslint-disable-next-line no-console
//   console.log( rowOject );
// });
