import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { IQuestion } from '../../../../../interfaces/IQuestion';
import useStyles from './Styles';

interface IProps {
  data: IQuestion;
  onSave: any;
}

const ItemOpenQuestion: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const { data, onSave } = props;
  const [valueInput, setValueInput] = useState<string>( '' );

  const onChange = ( e: any ): void => {
    if ( e.target.value !== '' ) {
      onSave( data.id, e.target.value );
    }
    setValueInput( e.target.value );
  };

  return (
    <div className={classes.root}>
      <span className={classes.sentenceItem}>
        {data.sentence}
      </span>
      <div className={classes.response}>
        <TextField
          value={valueInput}
          onChange={onChange}
          variant="outlined"
          size="small"
          fullWidth
          multiline
          className={classes.input}
        />
      </div>
    </div>
  );
};

export default ItemOpenQuestion;
