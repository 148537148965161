import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import { Grid } from '@material-ui/core';
import ActivityBookForm, {
  activityTypes,
} from '../../../components/ActivityBookForm/ActivityBookForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { actions } from '../../../redux/ducks/ActivityDucks';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import { IActivity } from '../../../interfaces/IActivity';
import { RootState } from '../../../redux/store';
import Card from '../../../components/Card/Card';
import { getRoute } from '../../../helpers/get-user-by-type';

const ActivityBookCreate: React.FC<InjectedFormProps> = ( ) => {
  const dispatch = useDispatch();
  const { idActivity } = useSelector(( state: RootState ) => state.activity );
  const [typeActivity, setTypeActivity] = useState( '' );
  const history = useHistory();

  const {
    userData,
  } = useSelector(( state: RootState ) => state.login );

  const handleSubmit = ( values: any ): void => {
    const { guideDocument, ...data } = values;
    const activity = { ...data, taskId: '' } as IActivity;
    activity.isBook = true;
    setTypeActivity( activity.type );
    dispatch( actions.createActivity( activity, guideDocument ));
  };

  useEffect(() => {
    if ( idActivity && typeActivity === activityTypes[1].id ) {
      history.replace( `/admin/actividades/editar/${idActivity}` );
    } else if ( typeActivity ) {
      history.replace( `/admin/actividades` );
    }
  }, [idActivity]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[
                {
                  name: 'Actividades',
                  url: `${getRoute( userData.type )}/actividades`,
                }]}
              itemsText={['Crear Actividad']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <ActivityBookForm onSubmit={handleSubmit} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default ActivityBookCreate;
