import React from 'react';
import { TableRow, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableRow: {
    '& > *': {
      fontWeight: 600,
      color: '#fff',
    },
  },
}));

interface IRowHeaderTableProps {
  children: JSX.Element | JSX.Element[];
}

const RowHeaderTable: React.FC<IRowHeaderTableProps> = ( props ) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <TableRow className={classes.tableRow}>
      { children }
    </TableRow>
  );
};

export default RowHeaderTable;
