import React from 'react';
// Material Ui
import { Grid } from '@material-ui/core';
// Interface
import { useSelector } from 'react-redux';
import { IBook } from '../../../../interfaces/IBook';
// Redux
import { RootState } from '../../../../redux/store';
// Components
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import BookItem from '../../../../components/BookItem/BookItem';
import Card from '../../../../components/Card/Card';
import { getRoute } from '../../../../helpers/get-user-by-type';

const StudentBookView: React.FC = () => {
  const { userData: { type } } = useSelector(
    ( state: RootState ) => state.login,
  );

  const { booksCourse: books } = useSelector(
    ( state: RootState ) => state.book,
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              back={false}
              itemsLinks={[
                { name: 'Libros', url: `${getRoute( type )}/libros` }]}
              itemsText={['Todos los libros']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          {books && (
            <Grid container spacing={4}>
              {books.map(( book: IBook ) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={book.id}>
                  <BookItem book={book} />
                </Grid>
              ))}
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default StudentBookView;
