import React, { useState, useEffect } from 'react';

// Material Ui
import {
  Card,
  Fab,
  CardContent,
  Box,
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

// Redux
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { ISubject } from '../../interfaces/ISubject';
import happyFace from '../../assets/svg/happyFace.svg';
import alertIconYellow from '../../assets/svg/alertIconYellow.svg';
import alertIconGreen from '../../assets/svg/alertIconGreen.svg';
import useStyles from './Styles';
import { getTotalStudetsByCourse } from '../../services/Courses.Services';
import { getTotalTaskBySubject } from '../../services/Task.Services';
import { getSubjectByIdThunk } from '../../redux/ducks/SubjectDucks';

interface ISubjectGradeItemTeacherProps {
  subject: ISubject;
  // eslint-disable-next-line react/require-default-props
  grades?: boolean;
}

const SubjectGradeItemTeacher:
React.FC<ISubjectGradeItemTeacherProps> = ( props ) => {
  const {
    subject,
    grades = true,
  } = props;

  const [totalStudents, setTotalStudents] = useState( 0 );
  const [statusTasks, setStatusTasks] = useState<boolean>( false );
  const [totalTasks, setTotalTasks] = useState( 0 );
  const [isMounted, setIsMounted] = useState<boolean>( true );
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if ( isMounted ) {
      getTotalStudents();
    }
    return () => { setIsMounted( false ); };
  }, []);

  useEffect(() => {
    if ( isMounted ) {
      getTotalTask();
    }
    return () => { setIsMounted( false ); };
  }, [totalStudents]);

  const getTotalStudents = async (): Promise<void> => {
    const total = await getTotalStudetsByCourse( subject.courseId );
    setTotalStudents( total );
  };

  const getTotalTask = async (): Promise<void> => {
    const total = await getTotalTaskBySubject( subject.id );
    const totalTasksAux = totalStudents * total.tasks;
    const totalNotes = total.notes;
    const statusTask = totalNotes >= totalTasksAux;
    setTotalTasks( totalTasksAux );
    setStatusTasks( statusTask );
  };

  const goToGradesAndChargeData = async ( subjecId: string ): Promise<void> => {
    history.push(
      `/docente/${grades ? 'calificaciones' : 'materias'}/detalle/${subject.id}/${grades ? subject.courseId : ''}`,
    );
    dispatch( getSubjectByIdThunk( subjecId ));
  };

  return (
    <Card className={`${classes.shadow} ${classes.card}`}>
      <CardContent className={classes.cardContent}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="flex"
            alignItems="center"
            className={classes.textTruncate}
          >
            <p className={`${classes.textTruncate} 
            ${classes.subjectName}`}
            >
              {subject.name}
            </p>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="block" textAlign="center" ml={3} mr={3}>
              {statusTasks ? (
                <img className={classes.svg} src={alertIconGreen} alt="" />
              ) : (
                <img className={classes.svg} src={alertIconYellow} alt="" />
              )}
              <p className={classes.exclamationText}>
                {totalTasks}
              </p>
            </Box>
            <Box display="block" textAlign="center" ml={3} mr={3} mb={1.4}>
              <img className={classes.svg} src={happyFace} alt="" />
              <p className={classes.exclamationText}>
                {totalStudents}

              </p>
            </Box>
            <Fab
              className={classes.playButton}
              aria-label="add"
              size="small"
              onClick={
                () => goToGradesAndChargeData( subject.id )
              }
            >
              <PlayArrowIcon />
            </Fab>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SubjectGradeItemTeacher;
