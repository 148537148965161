import React from 'react';
import { useDrag } from 'react-dnd';
import IItem from '../../interfaces/IItem';
import useStyles from './Styles';

interface IProps {
  name: string;
  setItems: any;
  id: number;
  onSave: any;
}

const ItemMovable: React.FC<IProps> = ( props ) => {
  const {
    setItems, name, id, onSave,
  } = props;

  const changeItemColumn = (
    currenItem: IItem, column1: string, column2?: string,
  ): void => {
    setItems((
      prevState: IItem[],
    ) => prevState.map(( e ) => ({
      ...e,
      column: e.name === currenItem.name
        ? column1
        : ( column2 || e.column ),
    })));
  };

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: 'ITEM',
    item: { name },
    end: ( item, monitor ) => {
      const dropResult = monitor.getDropResult<any>();
      if ( dropResult && dropResult.id === 'options' ) {
        changeItemColumn( item, 'options' );
        onSave();
      } else if ( dropResult ) {
        onSave( item.name, id );
        changeItemColumn( item, 'question', 'options' );
      }
    },
    collect: ( monitor ) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const classes = useStyles( isDragging );

  return (
    <div
      className={classes.root}
    >
      <div ref={dragPreview}>
        <span className={classes.item} ref={drag}>{name}</span>
      </div>
    </div>
  );
};

export default ItemMovable;
