import React from 'react';
import { IQuestion } from '../../../../../../interfaces/IQuestion';
import useStyles from './Styles';

interface IProps {
  data: IQuestion;
  response: string;
}

const ItemOpenQuestion: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const { data, response } = props;

  return (
    <div className={classes.root}>
      <span className={classes.sentenceItem}>
        {data.sentence}
      </span>
      <div className={classes.response}>
        <span>
          {response}
        </span>
      </div>
    </div>
  );
};

export default ItemOpenQuestion;
