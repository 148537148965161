import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
    height: '100%',
  },
}));

interface IImageProps {
  // eslint-disable-next-line react/require-default-props
  src?: string;
  // eslint-disable-next-line react/require-default-props
  alt?: string;
}

const Image: React.FC<IImageProps> = ( props ) => {
  const classes = useStyles();

  const { src, alt } = props;

  return (
    <img src={src} alt={alt} className={classes.image} />
  );
};

export default Image;
