import React, { useState } from 'react';
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Collapse,
} from '@material-ui/core';
import {
  initialize,
} from 'redux-form';
import { useDispatch } from 'react-redux';
import * as IconM from '@material-ui/icons/';
import IconButton from '@material-ui/core/IconButton';
import QuestionFormDnD from './QuestionFormDnD';
import { IQuestion } from '../../../interfaces/IQuestion';
import ToolTip from '../../ToolTip/ToolTip';

const useStyles = makeStyles(() => ({
  containerList: {
    maxHeight: 200,
    overflowY: 'scroll',
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10,
  },
  collapse: {
    backgroundColor: '#eee',
  },
}));

interface IListQuestionsDnDProps {
  // eslint-disable-next-line react/require-default-props
  handleSave?: any;
  questions: IQuestion[];
  // eslint-disable-next-line react/require-default-props
  handleUpdate?: any;
  // eslint-disable-next-line react/require-default-props
  handleDelete?: any;
}

// interface IOption {
//   name: string;
//   isRes: boolean;
// }

const ListQuestionsDnD: React.FC<IListQuestionsDnDProps> = ( props ) => {
  const classes = useStyles();
  const {
    handleSave, questions = [], handleUpdate, handleDelete,
  } = props;
  const [form, setForm] = useState<any>( undefined );
  const [idSudDoc, setIdSudDoc] = useState( '' );
  const [expanded, setExpanded] = useState<string>( '' );
  const dispatch = useDispatch();

  const handleSaveQuestion = ( values: any ): void => {
    const question: IQuestion = {
      ...values,
      sentence: values.sentence,
      type: 'dragAndDrop',
    };
    if ( !idSudDoc ) {
      handleSave( question );
    } else {
      question.id = idSudDoc;
      handleUpdate( question );
    }
    dispatch( initialize( 'question-dnd', undefined ));
  };

  const handleEdit = ( data: IQuestion ): void => {
    setIdSudDoc( data.id || '' );
    if ( form?.sentence !== data.sentence ) {
      dispatch( initialize( 'question-dnd', undefined ));
      setForm( data );
    } else {
      dispatch( initialize( 'question-dnd', data ));
    }
  };

  const handleRemove = ( id: string ): void => {
    handleDelete( id );
  };

  const handleExpanded = ( id: string ): void => {
    setExpanded( expanded === id ? '' : id );
  };

  return (
    <>
      <QuestionFormDnD onSubmit={handleSaveQuestion} initialValues={form} />
      <div className={classes.containerList}>
        {questions.map(( item: IQuestion, i: number ) => (
          <div key={i.toString()}>
            <List dense component="span">
              <ListItem>
                <ListItemIcon>
                  {`${i + 1}.-`}
                </ListItemIcon>
                <ListItemText primary={item.sentence} />
                <ListItemIcon>
                  <ToolTip title="Mostrar opciones de respuesta">
                    <IconButton
                      onClick={() => handleExpanded( item.id || '' )}
                    >
                      {expanded === item.id
                        ? <IconM.ExpandLess />
                        : <IconM.ExpandMore />}
                    </IconButton>
                  </ToolTip>
                </ListItemIcon>
                <ListItemIcon>
                  <ToolTip title="Editar">
                    <IconButton onClick={() => handleEdit( item )}>
                      <IconM.Edit />
                    </IconButton>
                  </ToolTip>
                </ListItemIcon>
                <ListItemIcon>
                  <ToolTip title="Eliminar">
                    <IconButton onClick={() => handleRemove( item.id || '' )}>
                      <IconM.Delete />
                    </IconButton>
                  </ToolTip>
                </ListItemIcon>
              </ListItem>
            </List>
            <Collapse
              in={expanded === item.id}
              timeout="auto"
              unmountOnExit
              className={classes.collapse}
            >
              <List dense component="span">
                <ListItem>
                  <ListItemIcon>
                    *
                  </ListItemIcon>
                  <ListItemText primary={item.optionCorrect} />
                  <ListItemIcon>
                    <ListItemText
                      primary="Correcta"
                    />
                  </ListItemIcon>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    *
                  </ListItemIcon>
                  <ListItemText primary={item.optionIncorrect1} />
                  <ListItemIcon>
                    <ListItemText
                      primary="Incorrecta"
                    />
                  </ListItemIcon>
                </ListItem>
                {item.optionIncorrect2 && (
                  <ListItem>
                    <ListItemIcon>
                      *
                    </ListItemIcon>
                    <ListItemText primary={item.optionIncorrect2} />
                    <ListItemIcon>
                      <ListItemText
                        primary="Incorrecta"
                      />
                    </ListItemIcon>
                  </ListItem>
                )}
                {item.optionIncorrect3 && (
                  <ListItem>
                    <ListItemIcon>
                      *
                    </ListItemIcon>
                    <ListItemText primary={item.optionIncorrect3} />
                    <ListItemIcon>
                      <ListItemText
                        primary="Incorrecta"
                      />
                    </ListItemIcon>
                  </ListItem>
                )}
              </List>
            </Collapse>
            <Divider />
          </div>
        ))}
      </div>
    </>
  );
};

export default ListQuestionsDnD;
