import React, { useEffect } from 'react';
// Interface
import { useDispatch, useSelector } from 'react-redux';
// Redux
import {
  Fab,
  Grid,
  Paper,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router';
import * as IconM from '@material-ui/icons/';
import {
  getSubjectByIdThunk,
  getStudentBySubjectThunk,
} from '../../../../redux/ducks/SubjectDucks';
// Components
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import CardHomework from '../../../../components/CardHomework/CardHomeworks';
import { RootState } from '../../../../redux/store';
import Card from '../../../../components/Card/Card';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import useStyles from './Styles';
import { actions as actionsTask } from '../../../../redux/ducks/TaskDucks';

interface Params {
  id: string;
}

const Homeworks: React.FC = () => {
  const classes = useStyles();
  const params = useParams<Params>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formData, studentList } = useSelector(
    ( state: RootState ) => state.subject,
  );
  const { formData: instituteData } = useSelector(
    ( state: RootState ) => state.institute,
  );

  useEffect(() => {
    dispatch( getStudentBySubjectThunk( params.id ));
    dispatch( getSubjectByIdThunk( params.id ));
  }, []);
  const handleCreate = (): void => {
    dispatch( actionsTask.setInicitalFormTask());
    dispatch( actionsTask.setIdTask( '' ));
    history.push( `/docente/tareas/agregar` );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <div className={classes.card}>
            <ContainerBreadcrumbs>
              <Breadcrumbs
                itemsLinks={[
                  {
                    name: formData.name,
                    url: `docente/materias/detalle/${formData.id}`,
                  }]}
                itemsText={['Calificar', 'Todas las tareas']}
              />
            </ContainerBreadcrumbs>
            <div className={classes.send}>
              <ToolTip title="Agregar tarea">
                <Fab
                  size="small"
                  color="primary"
                  className={classes.whiteText}
                  onClick={
                    handleCreate
                  }
                >
                  <IconM.Add />
                </Fab>
              </ToolTip>
            </div>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {
          instituteData
          && instituteData.partial.map(( item: any, i: number ) => (
            <div key={i.toString()}>
              <div className={classes.dividerPartial}>
                <hr className={classes.line} />
                <Paper className={classes.labelDivider}>
                  {item.name}
                </Paper>
                <hr className={classes.line} />
              </div>
              <CardHomework
                students={studentList || []}
                partial={item.name}
              />
            </div>
          ))
        }
      </Grid>
    </Grid>
  );
};

export default Homeworks;
