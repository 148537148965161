import { ThunkAction } from 'redux-thunk';
import { Dispatch, AnyAction } from 'redux';
import { toast } from 'react-toastify';
import { IUser } from '../../interfaces/IUser';
import { RootState } from '../store';
import {
  db, auth, authPersistenceSession, authPersistenceLocal, storage,
} from '../../services/firebase';
import { reauthenticate } from '../../services/Auth.Services';
import { api } from '../../services/api-functions';

export const actionType = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SET_USER_DATA: 'SET_USER_DATA',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
  UPLOADING_REQUEST: 'UPLOADING_REQUEST',
  UPLOADING_SUCCES: 'UPLOADING_SUCCES',
  UPLOAD_PHOTO_SUCCESS: 'UPLOAD_PHOTO_SUCCESS',
  UPLOAD_PHOTO_ERROR: 'UPLOAD_PHOTO_ERROR',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
};

// interfaces
export interface ILogin {
  email: string;
  password: string;
  rememberme?: boolean;
}

export interface IInitialStateLogin {
  loading: boolean;
  uploading: boolean;
  changing: boolean;
  errors: string;
  userData?: IUser;
}

export interface ILoginRequest {
  type: typeof actionType.LOGIN_REQUEST;
}

export interface ILoginSuccess {
  type: typeof actionType.LOGIN_SUCCESS;
}

export interface ILoginError {
  type: typeof actionType.LOGIN_ERROR;
  payload: string;
}

export interface ISetUserData {
  type: typeof actionType.SET_USER_DATA;
  payload: IUser;
}

export interface ILogoutError {
  type: typeof actionType.LOGOUT_ERROR;
  payload: string;
}

export interface IUploadingRequest {
  type: typeof actionType.UPLOADING_REQUEST;
}

export interface IUploadingSuccess {
  type: typeof actionType.UPLOADING_SUCCES;
}

export interface IChangePasswordRequest {
  type: typeof actionType.UPLOADING_REQUEST;
}

export interface IChangePasswordSuccess {
  type: typeof actionType.UPLOADING_SUCCES;
}

const initialState: IInitialStateLogin = {
  loading: false,
  uploading: false,
  changing: false,
  errors: '',
};

export default function loginReducer(
  state = initialState, action: AnyAction,
) : any {
  const { type, payload } = action;

  switch ( type ) {
    case actionType.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.UPLOADING_REQUEST:
      return {
        ...state,
        uploading: true,
      };
    case actionType.UPLOADING_SUCCES:
      return {
        ...state,
        uploading: false,
      };
    case actionType.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changing: true,
      };
    case actionType.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changing: false,
        succesMessage: 'Contraseña cambiada',
      };
    case actionType.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        errors: payload,
      };
    case actionType.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.SET_USER_DATA:
      return {
        ...state,
        userData: payload,
      };
    case actionType.LOGOUT_ERROR:
      return {
        ...state,
        errors: payload,
      };
    default:
      return state;
  }
}

export const actions = {
  loginRequest: (): ILoginRequest => ({ type: actionType.LOGIN_REQUEST }),
  loginSuccess: (): ILoginSuccess => ({ type: actionType.LOGIN_SUCCESS }),
  loginError: ( payload: string ): ILoginError => ({
    type: actionType.LOGIN_ERROR,
    payload,
  }),
  setUserData: ( payload: IUser ): ISetUserData => ({
    type: actionType.SET_USER_DATA,
    payload,
  }),
  logoutError: ( payload: string ): ILogoutError => ({
    type: actionType.LOGOUT_ERROR,
    payload,
  }),
  uploadingRequest: (): ILoginRequest => ({
    type: actionType.UPLOADING_REQUEST,
  }),
  uploadingSuccess: (): ILoginSuccess => ({
    type: actionType.UPLOADING_SUCCES,
  }),
  changePasswordRequest: (): IChangePasswordRequest => ({
    type: actionType.CHANGE_PASSWORD_REQUEST,
  }),
  changePasswordSuccess: (): IChangePasswordSuccess => ({
    type: actionType.CHANGE_PASSWORD_SUCCESS,
  }),
  changePasswordError: ( payload: string ): AnyAction => ({
    type: actionType.CHANGE_PASSWORD_ERROR,
    payload,
  }),
  uploadPhotoSuccess: ( payload: string ): AnyAction => ({
    type: actionType.UPLOAD_PHOTO_SUCCESS,
    payload,
  }),
  uploadPhotoError: ( payload: string ): AnyAction => ({
    type: actionType.UPLOAD_PHOTO_ERROR,
    payload,
  }),
};

export const loginThunk = ({
  email,
  password,
  rememberme = false,
}: ILogin ): ThunkAction<
void, RootState, null, AnyAction
> => async ( dispatch: Dispatch ) => {
  try {
    const typeSession = rememberme
      ? authPersistenceLocal
      : authPersistenceSession;
    await auth.setPersistence( typeSession );
    await auth.signInWithEmailAndPassword( email, password );
    toast.success( 'Te has logeado correctamente.' );
  } catch ( error: any ) {
    // eslint-disable-next-line no-console
    console.log( error );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { code } = error;
    let msg = '';
    if ( code === 'auth/user-not-found' ) {
      msg = 'Correo incorrecto';
    }

    if ( code === 'auth/wrong-password' ) {
      msg = 'Constraseña incorrecta';
    }

    if ( code === 'auth/too-many-requests' ) {
      msg = `Acceso a la cuenta desactivado 
      por muchos intentos fallidos, intentelo más tarde.`;
    }

    dispatch( actions.loginError( msg ));
  }
};

export const getUserByIdThunk = ( id: string ):
ThunkAction<
void, RootState, null, AnyAction
> => async ( dispatch: Dispatch ) => {
  dispatch( actions.loginRequest());
  try {
    const result = await db.collection( 'users' ).doc( id ).get();
    if ( result.exists ) {
      const userData = result.data() as IUser;
      userData.id = auth.currentUser?.uid || '';
      dispatch( actions.setUserData( userData ));
    }
    dispatch( actions.loginSuccess());
  } catch ( error ) {
    // console.log( error );
  }
};

export const logoutThunk = ():
ThunkAction<
void, RootState, null, AnyAction
> => async ( dispatch: Dispatch ) => {
  try {
    await auth.signOut();
    toast.success( 'Te has deslogeado correctamente.' );
  } catch ( error ) {
    dispatch( actions.logoutError( 'Error al cerrar sesión' ));
  }
};

export const sendEmailResetPasswordThunk = ( email: string ):
ThunkAction<
void, RootState, null, AnyAction
> => async ( ) => {
  try {
    const result = await db.collection( 'users' )
      .where( 'email', '==', email )
      .get();
    if ( !result.empty ) {
      await auth.sendPasswordResetEmail( email );
      toast.success( 'Correo enviado exitosamente reviselo por favor.' );
    } else {
      toast.error(
        'El correo no esta registrado, ingrese el correo con el que inicia sesión.',
      );
    }
  } catch ( error ) {
    //
  }
};

export const uploadPhotoFileThunk = (
  id: string, file: File, type: number, userData: IUser,
): ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  toast.info( 'Empezando a subir tu foto.', { autoClose: 2000 });
  dispatch( actions.uploadingRequest());
  let userType = '';
  switch ( type ) {
    case 1:
      userType = 'admins';
      break;
    case 2:
      userType = 'teachers';
      break;
    case 3:
      userType = 'students';
      break;
    default:
      userType = 'admins';
      break;
  }
  try {
    if ( file ) {
      const storageRef = storage.ref();
      const response = await storageRef
        .child( userType )
        .child( `${id}-photo.docx` )
        .put( file );
      const url = await response.ref.getDownloadURL();
      await db.collection( 'users' ).doc( id ).update({ photo: url });
      dispatch( actions.uploadPhotoSuccess( url ));
      const user = userData;
      user.photo = url;
      dispatch( actions.setUserData( user ));
      toast.success( 'Foto actualizada correctamente.' );
      return;
    }
    dispatch(
      actions.uploadPhotoError(
        'No se pudo subir el archivo',
      ),
    );
  } catch ( err ) {
    toast.error( 'A ocurrido un error intenta denuevo.' );
    dispatch(
      actions.uploadPhotoError(
        'Error al subir el archivo de respuesta',
      ),
    );
  }
};

export const reAutheticateThunk = (
  currentPassword: string, newPassword: string,
): ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  try {
    dispatch( actions.changePasswordRequest());
    await reauthenticate( currentPassword );
    await auth.currentUser?.updatePassword( newPassword );
    dispatch( actions.changePasswordSuccess());
    toast.success( 'Contraseña cambiada correctamente' );
  } catch ( err ) {
    toast.error( 'A ocurrido un error intenta denuevo.' );
    dispatch(
      actions.changePasswordError(
        'Error al subir el archivo de respuesta',
      ),
    );
  }
};

export const deleteUserAccountThunk = (
  idUser: string,
): ThunkAction<
void, RootState, null, AnyAction> => async ( ) => {
  try {
    if ( auth.currentUser ) {
      await api.post(
        '/api/delete-account-user', {
          uid: idUser,
        },
      );
      toast.success( 'Se elimino la cuenta del usuario.' );
      return;
    }
    toast.error( 'No se pudo eliminar la cuenta.' );
  } catch ( err ) {
    // eslint-disable-next-line no-console
    console.log( err );
  }
};
