import React, { useState } from 'react';
import { Link } from 'react-router-dom';
/* React Material Imports */
import {
  AppBar, Avatar, Box, Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ExitToApp,
  MenuOutlined,
} from '@material-ui/icons';
/* Components custom */
import { useDispatch, useSelector } from 'react-redux';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import DialogConfirm from '../DialogConfirm/DialogConfirm';
/* Redux */
import { logoutThunk } from '../../redux/ducks/LoginDucks';
import UserDefault from '../../assets/img/user-photo-default.png';
import { RootState } from '../../redux/store';

const drawerWidth = 110;

const useStyles = makeStyles(( theme ) => ({
  menuButton: {
    marginRight: theme.spacing( 2 ),
    display: 'none',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
    },
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    [theme.breakpoints.down( 'sm' )]: {
      borderTopRightRadius: '0!important',
      marginLeft: '0!important',
      width: '100%!important',
      borderBottomLeftRadius: '0!important',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  shadow: {
    boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
  },
}));

interface INavbarProps {
  handleOpen: any
}

const Navbar: React.FC<INavbarProps> = ( props ) => {
  const { handleOpen } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState( false );
  const userData = useSelector(( state: RootState ) => state.login.userData );

  const handleOpenConfirm = (): void => {
    setOpen( true );
  };

  const handleLogout = ( confirm: boolean ): void => {
    if ( confirm ) {
      dispatch( logoutThunk());
    }
    setOpen( false );
  };

  return (
    <>
      <AppBar
        color="inherit"
        position="fixed"
        className={classes.appBar}
        style={{ borderBottomLeftRadius: `15px` }}
      >
        <Toolbar className={classes.toolbar}>
          <Box className={classes.menuButton}>
            <ButtonIcon handleClick={handleOpen}>
              <MenuOutlined />
            </ButtonIcon>
          </Box>
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <Link to="/perfil">
                <Avatar
                  className={classes.shadow}
                  src={userData && userData.photo ? userData.photo : UserDefault}
                />
              </Link>
            </Box>
            <ButtonIcon handleClick={handleOpenConfirm}>
              <ExitToApp />
            </ButtonIcon>
          </Box>
        </Toolbar>
      </AppBar>
      <DialogConfirm
        open={open}
        title="¿ Desea cerrar la sesión ?"
        onClose={handleLogout}
      />
    </>
  );
};

export default Navbar;
