import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  reduxForm, InjectedFormProps, Field,
} from 'redux-form';
import { Grid } from '@material-ui/core';
import {
  required, minLength5, maxLength50,
} from '../../helpers/validate-forms';
import Input from '../Input/Input';
import Button from '../Button/Button';
import CenterLine from '../Center/CenterInLine';
import { actions } from '../../redux/ducks/TeacherDucks';
import ContainerInputs from '../ContainerInputs/ContainerInputs';
import ContainerForm from '../ContainerForm/ContainerForm';
import FormRedux from '../FormRedux/FormRedux';

const CourseForm: React.FC<InjectedFormProps> = ( props ) => {
  const {
    handleSubmit, submitting,
  } = props;

  const dispatch = useDispatch();

  // const teachersInStore = useSelector(
  //   ( state: RootState ) => state.teacher.teachers,
  // );

  useEffect(() => {
    dispatch( actions.getTeachers());
  }, []);

  return (
    <ContainerForm>
      <FormRedux onSubmit={handleSubmit}>
        <ContainerInputs>
          <Grid item xs={12} sm={6}>
            <Field
              name="course"
              type="text"
              label="Letra que lo identifica"
              component={Input}
              placeholder="Letra de identificación"
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="name"
              type="text"
              label="Nombre"
              component={Input}
              placeholder="Ingrese un nombre"
              validate={[required, minLength5, maxLength50]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="description"
              type="text"
              label="Descripción"
              component={Input}
              validate={[required]}
              multiline
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Field
              name="tutorId"
              component={Select}
              placeholder="Escoja un docente"
              validate={[required]}
              label="Tutor"
            >
              {teachersInStore.map(( item: any, index: number ) => (
                <MenuItem
                  key={index.toString()}
                  value={`${item.name} ${item.surname}+${item.id}`}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Field>
          </Grid> */}
          <Grid item xs={12} sm={12}>
            <CenterLine>
              <Button block type="submit" disabled={submitting}>
                Enviar
              </Button>
            </CenterLine>
          </Grid>
        </ContainerInputs>
      </FormRedux>
    </ContainerForm>
  );
};

export default reduxForm({
  form: 'course',
})( CourseForm );
