import React, { Fragment } from 'react';
import { GridValueGetterParams, GridColDef } from '@material-ui/data-grid';
import * as IconM from '@material-ui/icons/';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ContainerTable from '../Table/ContainerTable/ContainerTable';
import Table from '../Table/Table';
import { IUnit } from '../../interfaces/IUnit';
import CenterInLine from '../Center/CenterInLine';
import ToolTip from '../ToolTip/ToolTip';
import Topic from '../Topic/Topic';
import BookActivity from '../BookActivity/BookActivity';

interface ITableProps{
  units: IUnit[];
  handleEdit: any;
  handleDelete: any;
  handleNew: any;
  handleShow: any;
}

const TableStudent: React.FC<ITableProps> = ( props ) => {
  const {
    units,
    handleDelete,
    handleEdit,
    handleNew,
    handleShow,
  } = props;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 250,
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Nombre',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'bookName',
      headerName: 'Libro',
      type: 'string',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 200,
      renderCell: ( params: GridValueGetterParams ) => (
        <CenterInLine isRow>
          <ToolTip title="Mostrar detalle del libro">
            <ButtonIcon handleClick={() => handleShow( params.id )}>
              <IconM.Visibility />
            </ButtonIcon>
          </ToolTip>
          <ToolTip title="Editar libro">
            <ButtonIcon handleClick={() => handleEdit( params.id )}>
              <IconM.Edit />
            </ButtonIcon>
          </ToolTip>
          <ToolTip title="Eliminar libro">
            <ButtonIcon handleClick={() => handleDelete( params.id )}>
              <IconM.Delete />
            </ButtonIcon>
          </ToolTip>
        </CenterInLine>

      ),
    },
    {
      field: 'themes',
      headerName: 'Temas',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 150,
      renderCell: ({ id }: GridValueGetterParams ) => (
        <Topic unitId={id} />
      ),
    },
    {
      field: 'activities',
      headerName: 'Actividades',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 150,
      renderCell: ({ id }: GridValueGetterParams ) => (
        <BookActivity unitId={id} />
      ),
    },
  ];

  return (
    <>
      <ButtonIcon handleClick={handleNew}>
        <IconM.Add />
      </ButtonIcon>
      <span>Agregar nueva unidad</span>
      <ContainerTable>
        <Table
          columns={columns}
          data={units}
        />
      </ContainerTable>
    </>
  );
};

export default TableStudent;
