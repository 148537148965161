import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    // padding: 10,
    // border: '1px solid #000',
  },
}));

const ContainerForm: React.FC = ( props: any ) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <Grid container className={classes.root} spacing={1}>
      {children}
    </Grid>
  );
};

export default ContainerForm;
