import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  hourStrong: {
    backgroundColor: 'rgb(10, 197, 247)',
    padding: '0.5em',
    color: 'white',
    minWidth: '6.6em',
    maxWidth: '6.6em',
  },
  daysWeak: {
    backgroundColor: 'rgb(149, 231, 247)',
    padding: '0.5em',
    color: 'white',
    minWidth: '6.6em',
    maxWdth: '6.6em',
  },
  hourWeak: {
    backgroundColor: 'rgb(149, 231, 247)',
    padding: '0.5em',
    color: 'white',
    minWidth: '6.6em',
    maxWidth: '6.6em',
  },
  daysStrong: {
    backgroundColor: 'rgb(10, 197, 247)',
    padding: '0.5em',
    color: 'white',
    minWidth: '6.6em',
    maxWidth: '6.6em',
  },
  noMargin: {
    margin: 0,
  },
  break: {
    backgroundColor: 'rgb(242, 168, 56)',
    padding: '0.5em',
    color: 'white',
    minWidth: '6.6em',
    maxWidth: '6.6em',
  },
}));
