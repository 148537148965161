import { db } from './firebase';
import { ICourse } from '../interfaces/ICourse';

const COLLECTION_COURSE = 'courses';

export const getTotalStudetsByCourse = async ( id: string ): Promise<any> => {
  try {
    const collection = await db.collection( COLLECTION_COURSE )
      .doc( id ).get();
    let total = 0;
    if ( collection.exists ) {
      const data = collection.data() as ICourse;
      total = data.totalStudents;
    }
    return total;
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    return 0;
  }
};
