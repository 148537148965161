import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { toast } from 'react-toastify';
import { ICourse } from '../../interfaces/ICourse';
import { RootState } from '../store';
import { db } from '../../services/firebase';

// Const with name of bd table
const COLLECTION_COURSE = 'courses';

// action types
export const actionType = {
  GET_COURSE_REQUEST: 'GET_COURSE_REQUEST',
  GET_COURSES_FROM_DB: 'GET_COURSES_FROM_DB',
  SET_CREATE_COURSE_SUCCESS: 'SET_CREATE_COURSE_SUCCESS',
  SET_COURSE_TO_EDIT_SUCCESS: 'SET_COURSE_TO_EDIT_SUCCESS',
  SET_DELETE_COURSE_SUCCESS: 'SET_DELETE_COURSE_SUCCESS',
  SET_UPDATE_COURSE_SUCCESS: 'SET_UPDATE_COURSE_SUCCESS',
  SET_ADD_ITEM_TO_COURSE_SUCCESS: 'SET_ADD_ITEM_TO_COURSE_SUCCESS',
  SET_BOOKS_IN_COURSEBYID: 'SET_BOOKS_IN_COURSEBYID',
  SET_LOADING_COURSE: 'SET_LOADING_COURSE',
  SET_CLEAN_COURSEBYID: 'SET_CLEAN_COURSEBYID',
  SET_LOADING_USERS_BY_COURSE: 'SET_LOADING_USERS_BY_COURSE',
  SET_USERS_BY_COURSE_SUCCESS: 'SET_USERS_BY_COURSE_SUCCESS',
};

// Interfaces
export interface BookState {
  courses: ICourse[] | null;
  courseById: null;
}

export interface setGetCoursesSuccess {
  type: typeof actionType.GET_COURSES_FROM_DB;
  payload: ICourse[];
}

export interface setCreateCourseSuccess {
  type: typeof actionType.SET_CREATE_COURSE_SUCCESS;
}

export interface setDeleteCourseSuccess {
  type: typeof actionType.SET_CREATE_COURSE_SUCCESS;
}

export interface setCourseToEditSuccess {
  type: typeof actionType.SET_COURSE_TO_EDIT_SUCCESS;
  payload: ICourse;
}

export interface setCourseUpdateSuccess {
  type: typeof actionType.SET_UPDATE_COURSE_SUCCESS;
}

export interface setAddItemToCourseSuccess {
  type: typeof actionType.SET_ADD_ITEM_TO_COURSE_SUCCESS;
}

export interface setBooksInCourseById {
  type: typeof actionType.SET_BOOKS_IN_COURSEBYID,
  payload: any;
}

export interface setLoadingCourse {
  type: typeof actionType.SET_LOADING_COURSE;
  payload: boolean;
}

export interface setLoadingUsersCourse {
  type: typeof actionType.SET_LOADING_USERS_BY_COURSE;
  payload: boolean;
}

export interface setCleanCourseById {
  type: typeof actionType.SET_CLEAN_COURSEBYID,
}

export interface setUsersInCourseById {
  type: typeof actionType.SET_USERS_BY_COURSE_SUCCESS,
  payload: any;
}

// Thunk Types
type CourseActions =
  | setGetCoursesSuccess
  | setCreateCourseSuccess
  | setDeleteCourseSuccess
  | setCourseToEditSuccess
  | setAddItemToCourseSuccess
  | setBooksInCourseById
  | setLoadingCourse
  | setCleanCourseById
  | setLoadingUsersCourse
  | setUsersInCourseById;

// initial state
const initialState = {
  courses: [] as any,
  courseById: {
    id: null,
    course: null,
    name: null,
    description: null,
    tutor: null,
    teachers: [],
    students: [],
    books: [],
    tutorId: null,
    subCollectionsItems: null,
  },
  usersByCourse: [],
  loading: false,
  loadingUsers: false,
};

// reducer
export default function courseReducer(
  state = initialState, action: AnyAction,
) : any {
  const { type, payload } = action;

  switch ( type ) {
    case actionType.GET_COURSES_FROM_DB:
      return {
        ...state,
        courses: payload,
      };
    case actionType.SET_COURSE_TO_EDIT_SUCCESS:
      return {
        ...state,
        courseById: payload,
      };
    case actionType.GET_COURSE_REQUEST:
      return {
        ...state,
      };
    case actionType.SET_BOOKS_IN_COURSEBYID:
      return {
        ...state,
        courseById: {
          ...state.courseById,
          books: payload,
          subCollectionsItems: payload,
        },
      };
    case actionType.SET_LOADING_COURSE:
      return {
        ...state,
        loading: payload,
      };
    case actionType.SET_LOADING_USERS_BY_COURSE:
      return {
        ...state,
        loadingUsers: payload,
      };
    case actionType.SET_CLEAN_COURSEBYID:
      return {
        ...state,
        courseById: initialState.courseById,
      };
    case actionType.SET_USERS_BY_COURSE_SUCCESS:
      return {
        ...state,
        usersByCourse: payload,
      };
    case actionType.SET_CREATE_COURSE_SUCCESS:
    case actionType.SET_DELETE_COURSE_SUCCESS:
    case action.SET_ADD_ITEM_TO_COURSE_SUCCESS:
      return state;
    default:
      return state;
  }
}

// actions creators
export const actions = {
  getCourses:
    (): ThunkAction<
    void, RootState, null, CourseActions> => async ( dispatch ) => {
      try {
        db.collection( COLLECTION_COURSE )
          .where( 'deleted', '==', false )
          .onSnapshot(( querySnapshot ) => {
            const courses: any = [];
            querySnapshot.forEach(( course ) => {
              courses.push({ ...course.data(), id: course.id });
            });
            dispatch({
              type: actionType.GET_COURSES_FROM_DB,
              payload: courses,
            });
          });
      } catch ( err ) {
        // console.error( err );
      }
    },
  getCourse:
    ( id: string ): ThunkAction<
    void, RootState, null, CourseActions> => async ( dispatch ) => {
      try {
        dispatch( actions.cleanCourseById());
        dispatch( actions.setLoadingCourse( true ));
        const collection = await db.collection( COLLECTION_COURSE )
          .doc( id ).get();
        if ( collection.exists ) {
          const courseSelected: any = {
            ...collection.data(), id, students: [], teachers: [], books: [],
          };
          const { name, id: idTutor } = courseSelected.tutor;
          const tutorId = `${name}+${idTutor}`;
          Object.assign( courseSelected, { tutorId });
          dispatch({
            type: actionType.SET_COURSE_TO_EDIT_SUCCESS,
            payload: courseSelected,
          });
        }
        dispatch( actions.setLoadingCourse( false ));
      } catch ( err ) {
        // console.error( err );
      }
    },
  createCourse:
    ( data: any ): ThunkAction<
    void, RootState, null, CourseActions> => async ( dispatch ) => {
      // const idTutor = data.tutorId.split( '+' )[1];
      // const nameTutor = data.tutorId.split( '+' )[0];
      const dataToSave: ICourse = {
        course: data.course,
        name: data.name,
        description: data.description,
        deleted: false,
        totalStudents: 0,
        totalTeachers: 0,
        tutor: {
          id: '',
          name: '',
        },
      };
      try {
        await db.collection( COLLECTION_COURSE ).add( dataToSave );
        dispatch({
          type: actionType.SET_CREATE_COURSE_SUCCESS,
        });
        toast.success( 'Nuevo curso agregado.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  updateCourse:
    ( id: string, data: any ): ThunkAction<
    void, RootState, null, CourseActions> => async ( dispatch ) => {
      try {
        const idTutor = data.tutorId.split( '+' )[1];
        const nameTutor = data.tutorId.split( '+' )[0];
        const dataToSave = {
          course: data.course,
          name: data.name,
          description: data.description,
          tutor: {
            id: idTutor,
            name: nameTutor,
          },
        };
        await db.collection( COLLECTION_COURSE ).doc( id ).update( dataToSave );
        dispatch({
          type: actionType.SET_UPDATE_COURSE_SUCCESS,
        });
        toast.success( 'Curso editado correctamente.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  deleteCourse:
    ( id: string ): ThunkAction<
    void, RootState, null, CourseActions> => async ( dispatch ) => {
      try {
        await db
          .collection( COLLECTION_COURSE )
          .doc( id )
          .update({ deleted: true })
          .then(() => {
            dispatch({
              type: actionType.SET_DELETE_COURSE_SUCCESS,
            });
          });
        toast.success( 'Curso eliminado correctamente.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  addToCourse:
    ( data: any, id: string | undefined, type: number ): ThunkAction<
    void, RootState, null, CourseActions> => async ( dispatch ) => {
      try {
        const batch = db.batch();
        // eslint-disable-next-line array-callback-return
        data.forEach(( item: any ) => {
          if ( type === 1 ) {
            const doc = db.collection( COLLECTION_COURSE )
              .doc( id ).collection( 'students' ).doc( item.id );
            batch.set( doc, item );
          } else if ( type === 2 ) {
            const doc = db.collection( COLLECTION_COURSE )
              .doc( id ).collection( 'teachers' ).doc( item.id );
            batch.set( doc, item );
          } else if ( type === 3 ) {
            const doc = db.collection( COLLECTION_COURSE )
              .doc( id ).collection( 'books' ).doc( item.id );
            batch.set( doc, item );
          }
        });
        await batch.commit();
        dispatch({
          type: actionType.SET_ADD_ITEM_TO_COURSE_SUCCESS,
        });
        toast.success( 'Libro agregado correctamente.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  deleteFromCourse:
  ( data: any, id: string | undefined, type: number ): ThunkAction<
  void, RootState, null, CourseActions> => async ( dispatch ) => {
    try {
      data.forEach( async ( item: any ) => {
        if ( type === 1 ) {
          await db.collection( COLLECTION_COURSE )
            .doc( id ).collection( 'students' ).doc( item )
            .delete();
          await db.collection( 'users' )
            .doc( item ).update({ courseId: '' });
        } else if ( type === 2 ) {
          await db.collection( COLLECTION_COURSE )
            .doc( id ).collection( 'teachers' ).doc( item )
            .delete();
        } else if ( type === 3 ) {
          await db.collection( COLLECTION_COURSE )
            .doc( id ).collection( 'books' ).doc( item )
            .delete();
        }
      });
      dispatch({
        type: actionType.SET_ADD_ITEM_TO_COURSE_SUCCESS,
      });
      toast.success( 'Libro eliminado correctamente.' );
    } catch ( err ) {
      // console.error( err );
    }
  },
  setLoadingCourse:
( state: boolean ): ThunkAction<
void, RootState, null, CourseActions> => async ( dispatch ) => {
  dispatch({
    type: actionType.SET_LOADING_COURSE,
    payload: state,
  });
},
  setLoadingUsersCourse:
( state: boolean ): ThunkAction<
void, RootState, null, CourseActions> => async ( dispatch ) => {
  dispatch({
    type: actionType.SET_LOADING_USERS_BY_COURSE,
    payload: state,
  });
},
  cleanCourseById:
( ): ThunkAction<
void, RootState, null, CourseActions> => async ( dispatch ) => {
  dispatch({
    type: actionType.SET_CLEAN_COURSEBYID,
  });
},
  getUsersByCourseId:
    ( id: string ): ThunkAction<
    void, RootState, null, CourseActions> => async ( dispatch ) => {
      const subCollectionsItems: any = [];
      try {
        dispatch( actions.setLoadingUsersCourse( true ));
        await db.collection( 'users' )
          .where( 'courseId', '==', id )
          .get()
          .then(
            ( all ) => {
              all.docs.forEach(( item ) => {
                subCollectionsItems.push( item.data());
              });
            },
          );
        dispatch({
          type: actionType.SET_USERS_BY_COURSE_SUCCESS,
          payload: subCollectionsItems,
        });
        dispatch( actions.setLoadingUsersCourse( false ));
      } catch ( err ) {
        // console.error( err );
      }
    },
};
