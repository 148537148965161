import React from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// components
import TeacherForm from '../../../components/TeacherForm/TeacherForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { actions } from '../../../redux/ducks/TeacherDucks';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import { ITeacher } from '../../../interfaces/ITeacher';
import Card from '../../../components/Card/Card';

const TeacherCreate = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = ( values: any ): Promise<void> => {
    const { photo, ...data } = values;
    const teacher: ITeacher = { ...data };
    teacher.type = 2;
    dispatch( actions.createTeacher( teacher, photo ));
    history.push( '/admin/docentes' );
    return Promise.resolve();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'docentes', url: 'admin/docentes' }]}
              itemsText={['crear-docente']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <TeacherForm onSubmit={handleSubmit} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default TeacherCreate;
