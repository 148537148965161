import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
// components
import { Grid } from '@material-ui/core';
import TeacherForm from '../../../components/TeacherForm/TeacherForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { actions } from '../../../redux/ducks/TeacherDucks';
import { RootState } from '../../../redux/store';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
// Interfaces
import { ITeacher } from '../../../interfaces/ITeacher';
import Card from '../../../components/Card/Card';
import Spinner from '../../../components/Spinner/Spinner';

interface Params {
  id: string;
}

const TeacherEdit: React.FC<InjectedFormProps> = () => {
  const dispatch = useDispatch();
  const params = useParams<Params>();
  const history = useHistory();

  const { teacherById, loading } = useSelector(
    ( state: RootState ) => state.teacher,
  );

  const handleSubmit = ( values: any ): void => {
    const { photo, ...data } = values;
    const teacher = { ...data } as ITeacher;
    if ( !String( photo ).includes( 'https' )) {
      dispatch( actions.updateTeacher( params.id, teacher, photo ));
    } else {
      dispatch( actions.updateTeacher( params.id, teacher, undefined ));
    }
    history.push( '/admin/docentes' );
  };

  useEffect(() => {
    if ( params.id ) {
      dispatch( actions.getTeacher( params.id ));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'docentes', url: 'admin/docentes' }]}
              itemsText={['editar-docente']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          { !loading
            ? (
              <TeacherForm
                onSubmit={handleSubmit}
                initialValues={teacherById}
              />
            )
            : (
              <Spinner />
            )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default TeacherEdit;
