import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../redux/ducks/UnitDucks';
import { RootState } from '../../../redux/store';

// Components
import TableUnit from '../../../components/TableUnit/TableUnit';
import DialogConfirm from '../../../components/DialogConfirm/DialogConfirm';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import Card from '../../../components/Card/Card';

const UnitList: React.FC = () => {
  const history = useHistory();

  const [open, setOpen] = useState( false );
  const [idDoc, setIdDoc] = useState( '' );

  const dispatch = useDispatch();

  const unitsInStore = useSelector(( state: RootState ) => state.unit.units );

  useEffect(() => {
    dispatch( actions.getUnits());
  }, []);

  const handleOpenConfirm = ( id: string ): void => {
    setIdDoc( id );
    setOpen( true );
  };

  const handleNew = (): void => {
    history.push( `/admin/unidades/agregar` );
  };

  const handleDelete = ( confirm: boolean ): void => {
    if ( confirm ) {
      dispatch( actions.deleteUnit( idDoc ));
    }
    setOpen( false );
  };

  const handleShow = ( id: string ): void => {
    history.push( `/admin/unidades/ver/${id}` );
  };

  const handleEdit = ( id: string ): void => {
    history.push( `/admin/unidades/editar/${id}` );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              back={false}
              itemsLinks={[{ name: 'Unidades', url: 'admin/unidades' }]}
              itemsText={['Todas las unidades']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <TableUnit
            units={unitsInStore}
            handleDelete={handleOpenConfirm}
            handleEdit={handleEdit}
            handleNew={handleNew}
            handleShow={handleShow}
          />
        </Card>
      </Grid>
      <DialogConfirm
        open={open}
        title="¿ Desea eliminar ésta unidad ?"
        onClose={handleDelete}
      />
    </Grid>
  );
};

export default UnitList;
