import React, { useEffect, useState } from 'react';
import { reset } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { actions as actionCourse } from '../../../redux/ducks/CourseDucks';
import { actions as actionBook } from '../../../redux/ducks/BookDucks';
import { getStudentAllThunk } from '../../../redux/ducks/StudentDucks';
import { RootState } from '../../../redux/store';

// Components
import TableCourse from '../../../components/TableCourse/TableCourse';
import DialogConfirm from '../../../components/DialogConfirm/DialogConfirm';
import Dialog from '../../../components/Dialog/Dialog';
import CourseForm from '../../../components/CourseForm/CourseForm';
import TransferList from '../../../components/TransferList/TransferList';
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import Card from '../../../components/Card/Card';

const CourseList: React.FC = () => {
  const history = useHistory();

  const [open, setOpen] = useState( false );
  const [idDoc, setIdDoc] = useState( '' );
  const [openDialogCreateCourse, setOpenDialogCreateCourse] = useState( false );
  const [openDialogAddStudent, setOpenDialogAddStudent] = useState( false );
  const [openDialogAddTeacher, setOpenDialogAddTeacher] = useState( false );
  const [openDialogAddBook, setOpenDialogAddBook] = useState( false );

  const dispatch = useDispatch();

  const coursesInStore = useSelector((
    state: RootState,
  ) => state.course.courses );

  const teachersInStore = useSelector((
    state: RootState,
  ) => state.teacher.teachers );

  const booksInStore = useSelector((
    state: RootState,
  ) => state.book.books );

  const studentsInStore = useSelector((
    state: RootState,
  ) => state.student.students );

  const courseById = useSelector((
    state: RootState,
  ) => state.course.courseById );

  const loading = useSelector((
    state: RootState,
  ) => state.course.loading );

  useEffect(() => {
    dispatch( actionCourse.getCourses());
    dispatch( actionBook.getBooks());
    dispatch( getStudentAllThunk());
  }, []);

  const handleOpenConfirm = ( id: string ): void => {
    setIdDoc( id );
    setOpen( true );
  };

  const handleNew = ( value: number, id: string ): void => {
    switch ( value ) {
      case 1:
        setOpenDialogCreateCourse( true );
        break;
      case 2:
        setOpenDialogAddStudent( true );
        dispatch( actionCourse.getCourse( id ));
        break;
      case 3:
        setOpenDialogAddTeacher( true );
        dispatch( actionCourse.getCourse( id ));
        break;
      case 4:
        setOpenDialogAddBook( true );
        dispatch( actionCourse.getCourse( id ));
        break;
      default:
        break;
    }
  };

  const handleEdit = ( id: string ): void => {
    history.push( `/admin/cursos/editar/${id}` );
  };

  const handleDelete = ( confirm: boolean ): void => {
    if ( confirm ) {
      dispatch( actionCourse.deleteCourse( idDoc ));
    }
    setOpen( false );
  };

  const handleSubmit = ( values: any ): void => {
    dispatch( actionCourse.createCourse( values ));
    dispatch( reset( 'course' ));
    setOpenDialogCreateCourse( false );
  };

  const handleClose = ( ): void => {
    setOpenDialogCreateCourse( false );
    setOpenDialogAddStudent( false );
    setOpenDialogAddTeacher( false );
    setOpenDialogAddBook( false );
  };

  const handleSaveData = ( type: number, data: any[]): void => {
    if ( type === 1 ) {
      dispatch( actionCourse.addToCourse( data, courseById.id, type ));
    } else if ( type === 2 ) {
      dispatch( actionCourse.addToCourse( data, courseById.id, type ));
    } else if ( type === 3 ) {
      dispatch( actionCourse.addToCourse( data, courseById.id, type ));
    }
  };

  const handleDeleteData = ( type: number, rightChecked: any[]): void => {
    if ( type === 1 ) {
      dispatch( actionCourse.deleteFromCourse(
        rightChecked, courseById.id, type,
      ));
    } else if ( type === 2 ) {
      dispatch( actionCourse.deleteFromCourse(
        rightChecked, courseById.id, type,
      ));
    } else if ( type === 3 ) {
      dispatch( actionCourse.deleteFromCourse(
        rightChecked, courseById.id, type,
      ));
    }
  };

  const handleShow = ( id: string ): void => {
    history.push( `/admin/cursos/ver/${id}` );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              back={false}
              itemsLinks={[{ name: 'Cursos', url: 'admin/cursos' }]}
              itemsText={['Todos los cursos']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <TableCourse
            courses={coursesInStore}
            handleDelete={handleOpenConfirm}
            handleShow={handleShow}
            handleEdit={handleEdit}
            handleNew={handleNew}
          />
        </Card>
      </Grid>
      <Dialog
        open={openDialogCreateCourse}
        title="Crear un nuevo curso"
        onClose={handleClose}
      >
        {openDialogCreateCourse && <CourseForm onSubmit={handleSubmit} />}
      </Dialog>
      <Dialog
        open={openDialogAddStudent}
        title="Agregar Estudiante"
        onClose={handleClose}
        size="md"
      >
        {openDialogAddStudent && (
          <TransferList
            itemsRIGHT={courseById}
            itemsLEFT={studentsInStore}
            type={1}
            handleSaveData={handleSaveData}
            handleDeleteData={handleDeleteData}
            loading={loading}
          />
        )}
      </Dialog>
      <Dialog
        open={openDialogAddTeacher}
        title="Agregar docente"
        onClose={handleClose}
        size="md"
      >
        <TransferList
          itemsRIGHT={courseById}
          itemsLEFT={teachersInStore}
          type={2}
          handleSaveData={handleSaveData}
          handleDeleteData={handleDeleteData}
          loading={loading}
        />
      </Dialog>
      <Dialog
        open={openDialogAddBook}
        title="Agregar Libro"
        onClose={handleClose}
        size="md"
      >
        <TransferList
          itemsRIGHT={courseById}
          itemsLEFT={booksInStore}
          type={3}
          handleSaveData={handleSaveData}
          handleDeleteData={handleDeleteData}
          loading={loading}
        />
      </Dialog>
      <DialogConfirm
        open={open}
        title="Eliminar curso"
        onClose={handleDelete}
      />
    </Grid>
  );
};

export default CourseList;
