import React, { useState, useEffect } from 'react';
import {
  Paper, Box, Typography, Avatar, CircularProgress,
} from '@material-ui/core';
import * as IconM from '@material-ui/icons/';
import { IStudent } from '../../../../../../interfaces/IStudent';
import CenterInLine from '../../../../../../components/Center/CenterInLine';
import { useStyles } from './Styles';
import InputFormatDecimal from '../InputFormatDecimal/InputFormatDecimal';
import {
  createNoteInScheme,
  getNoteInScheme,
} from '../../../../../../services/Scheme.Services';
import {
  stringToTimeStamp,
} from '../../../../../../helpers/convert-to-timestamp';

interface IProps {
  student: IStudent;
  idScheme: string;
}

const ItemNote: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const { student, idScheme } = props;
  const [loadingSave, setLoadingSave] = useState<boolean>( false );
  const [noteInitial, setNoteInitial] = useState<string>( );

  useEffect(() => {
    handleGetNote();
  }, []);

  const handleGetNote = async (): Promise<void> => {
    const note = await getNoteInScheme( idScheme, student.id );
    if ( note === 0 ) {
      setNoteInitial( '0.0' );
      return;
    }
    setNoteInitial( note.toString());
  };

  const handleSaveNote = async ( note: number ): Promise<void> => {
    const data = {
      creation: stringToTimeStamp( new Date().toISOString()),
      noteFinal: note,
    };
    handleShowLoading( true );
    const res = await createNoteInScheme( idScheme, student.id, data );
    if ( res ) {
      handleShowLoading( false );
    }
  };

  const handleShowLoading = (
    value: boolean,
  ): void => setLoadingSave( value );

  return (
    <Box
      className={classes.root}
    >
      <Paper
        className={`${classes.card} ${classes.student}`}
        elevation={5}
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="wrap"
          gridColumnGap={20}
          gridRowGap={10}
        >
          <Avatar
            src={student && ( student.photo )}
            className={classes.avatar}
          />
          <Typography
            className={classes.title}
          >
            {student.name}
          </Typography>
        </Box>
      </Paper>
      <Paper
        className={`${classes.card} ${classes.calification}`}
        elevation={5}
      >
        <CenterInLine isRow>
          { noteInitial && (
            <InputFormatDecimal
              valueInitial={noteInitial}
              onSave={handleSaveNote}
            />
          )}
          { loadingSave
            ? (
              <div className={classes.progress}>
                <CircularProgress
                  size="2rem"
                />
              </div>
            )
            : (
              <IconM.CheckCircle
                className={classes.iconCheck}
              />
            )}
        </CenterInLine>
      </Paper>
    </Box>
  );
};

export default ItemNote;
