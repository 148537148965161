import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 10,
  },
  response: ( isOver ) => ({
    backgroundColor: isOver ? '#aaa' : '#fff',
    // border: '1px solid #000',
    borderRadius: 5,
    minWidth: 100,
    minHeight: 30,
    marginRight: 5,
    textAlign: 'center',
  }),
  sentenceItem: {
    marginRight: 5,
  },
}));

export default useStyles;
