import { withStyles } from '@material-ui/core/styles';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';

export const Tab = withStyles({
  root: {
    background: 'white',
    borderRadius: '2em',
  },
  selected: {
    background: '#0CAFEF',
    color: 'white!important',
    fontWeight: 'bold',
  },
})( MuiTab );

export const Tabs = withStyles({
  root: {
    background: 'white',
    width: 'fit-content',
    borderRadius: '2em',
    boxShadow: `rgb(0 0 0 / 5%) 0px 10px 10px -4px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
    marginBottom: '2em',
  },
  indicator: {
    background: 'transparent',
  },
})( MuiTabs );
