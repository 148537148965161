import React from 'react';
import { useDispatch } from 'react-redux';
import NavbarLogin from '../../components/Navbar/NavbarLogin';
import Container from '../../components/Container/Container';
// eslint-disable-next-line max-len
import ContainerSections from '../../components/ContainerSections/ContainerSections';
import LoginForm from './components/LoginForm/LoginForm';
import LeftSection from '../../components/SectionsLogin/LeftSection';
import { loginThunk, actions } from '../../redux/ducks/LoginDucks';

const Login : React.FC = () => {
  const dispatch = useDispatch();

  const handleLogin = ( values: any ): void => {
    dispatch( actions.loginRequest());
    dispatch( loginThunk( values ));
  };

  return (
    <Container>
      <NavbarLogin />
      <ContainerSections>
        <LeftSection>
          <LoginForm onSubmit={handleLogin} />
        </LeftSection>
      </ContainerSections>
    </Container>
  );
};

export default Login;
