import React from 'react';
import { Grid } from '@material-ui/core';

const ContainerInputs: React.FC = ( props: any ) => {
  const { children } = props;
  return (
    <Grid container spacing={1}>
      {children}
    </Grid>
  );
};

export default ContainerInputs;
