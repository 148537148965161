import React, { useEffect } from 'react';
import { useParams } from 'react-router';
// Material Ui
// Interface
import { useDispatch, useSelector } from 'react-redux';
// Redux
import { Grid, makeStyles } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  getSubjectByIdThunk,
} from '../../../../redux/ducks/SubjectDucks';
// Components
import { RootState } from '../../../../redux/store';
import SubjectClassUrlItem from '../../../../components/SubjectClassUrlItemStudent/SubjectClassUrlItemStudent';
import SubjectClassTaskItem from '../../../../components/SubjectClassTaskItemStudent/SubjectClassTaskItemStudent';
import SubjectTaskItemStudent from '../../../../components/SubjectTaskItemStudent/SubjectTaskItemStudent';

// Icons
import arrowRight from '../../../../assets/svg/arrowRight.svg';
import {
  getAllTaskBySubjectIdStudentThunk,
  actions as actionsTask,
} from '../../../../redux/ducks/TaskDucks';
import { ITask } from '../../../../interfaces/ITask';
import NoHomeworksStudent from '../../../../components/NoHomeworksStudent/NoHomeworksStudent';
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import Spinner from '../../../../components/Spinner/Spinner';

interface Params {
  id: string;
}
const useStyles = makeStyles({
  dFlex: {
    display: 'flex',
  },
});
const StudentSubjectDetail: React.FC = () => {
  const classes = useStyles();

  const userData = useSelector(( state: RootState ) => state.login.userData );

  const dispatch = useDispatch();

  const params = useParams<Params>();

  const { formData } = useSelector((
    state: RootState,
  ) => state.subject );

  const {
    taskNotes: tasks,
    loading,
  } = useSelector(( state: RootState ) => state.task );

  useEffect(() => {
    dispatch( actionsTask.setInicitalFormTask());
    dispatch( getSubjectByIdThunk( params.id ));
    dispatch( getAllTaskBySubjectIdStudentThunk( params.id, userData.id ));
  }, []);

  return (
    <>
      <ContainerBreadcrumbs>
        <Breadcrumbs
          itemsLinks={[
            {
              name: 'Materias',
              url: `docente/inicio`,
            }]}
          itemsText={[formData ? formData.name : '']}
        />
      </ContainerBreadcrumbs>
      {formData && (
        <Grid container spacing={6}>
          <SubjectClassUrlItem subject={formData} />
          <SubjectClassTaskItem subject={formData} tasks={tasks} />
        </Grid>
      )}
      <h2 className={classes.dFlex}>
        <img src={arrowRight} alt="arrowRight" />
        Tareas
      </h2>
      {loading ? (
        <Spinner />
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Swiper
              spaceBetween={15}
              slidesPerView={5}
              breakpoints={{
                400: {
                  slidesPerView: 1,
                },
                800: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 5,
                },
              }}
            >
              {tasks && tasks.length > 0
                ? tasks
                  .sort(( a: any, b:any ) => b.creation.toDate() - a.creation.toDate())
                  .map(( task: ITask, i: number ) => (
                    <SwiperSlide key={i.toString()}>
                      <SubjectTaskItemStudent task={task} />
                    </SwiperSlide>
                  ))
                : (
                  <Grid item xs={2}>
                    <NoHomeworksStudent />
                  </Grid>
                )}
            </Swiper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StudentSubjectDetail;
