import React, { useEffect, useState } from 'react';
import * as IconM from '@material-ui/icons/';
// Material Ui
import {
  CardContent,
  Grid,
  Card,
  Fab,
  Box,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router';
import { useStyles } from './Styles';
import { IPartial } from '../../interfaces/IPartial';
import Add from '../../assets/svg/Add.svg';
import gradeIcon from '../../assets/svg/gradeIcon.svg';
import Edit from '../../assets/svg/Edit.svg';
import Delete from '../../assets/svg/Delete.svg';
import { IScheme } from '../../interfaces/IScheme';
import MessageCard from '../MessageCard/MessageCard';
import { getSchemesByPartial } from '../../services/Scheme.Services';
import ButtonIcon from '../ButtonIcon/ButtonIcon';

interface ISchemeByPartialProps {
  partial: IPartial;
  index: number;
  handleClickMore: any;
  handleEditScheme: any;
  handleDeleteScheme: any;
  handleShowNotes: any;
  handleCreateReports: any;
}

interface Params {
  subjectId: string;
}

const SchemeByPartial: React.FC<ISchemeByPartialProps> = ( props ) => {
  const classes = useStyles();
  const params = useParams<Params>();
  const {
    partial,
    index,
    handleClickMore,
    handleEditScheme,
    handleDeleteScheme,
    handleShowNotes,
    handleCreateReports,
  } = props;

  const [scheme, setscheme] = useState<any>();
  const [percentage, setPercentage] = useState<number>( 0 );

  const handleGetData = async (
    indexToSearch: number,
  ): Promise<void> => {
    const data = await getSchemesByPartial( indexToSearch, params.subjectId );
    setscheme( data );
  };

  useEffect(() => {
    let isMountend = true;
    if ( isMountend ) {
      handleGetData( index );
    }
    return () => { isMountend = false; };
  }, [props]);

  useEffect(() => {
    if ( scheme && scheme.length > 0 ) {
      const percentages: number[] = [];
      scheme.forEach(( item: IScheme ) => {
        percentages.push( item.percent );
      });
      const total = percentages.reduce(
        ( a: number, b:number ) => Number( a ) + Number( b ), 0,
      );
      setPercentage( total );
    }
  }, [scheme]);

  return (
    <>
      <Grid item xs={12} sm={2}>
        <Card>
          <CardContent className={classes.cardContent}>
            <h4 className={classes.noMargin}>{partial.name}</h4>
            <Fab
              size="large"
              onClick={() => handleClickMore( partial.name, percentage )}
            >
              <img className={classes.svgAdd} src={Add} alt="newHomework" />
            </Fab>
            <div className={classes.sendContainer}>
              <ButtonIcon primary handleClick={handleCreateReports}>
                <IconM.Send className={classes.iconSend} />
              </ButtonIcon>
              <span>Generar</span>
              <span>Calificaciones</span>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Grid container spacing={2}>
          {scheme && scheme.length > 0
            ? scheme.map(( item: IScheme, i:number ) => (
              <Grid item xs={12} key={i.toString()}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContentInfo}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <Box display="flex" alignItems="baseline">
                        <Fab
                          className={classes.fabEdit}
                          onClick={() => handleEditScheme(
                            partial.name,
                            item.tasks,
                            item.id,
                            item.external,
                            100 - Number( item.percent ),
                          )}
                        >
                          <img
                            className={classes.svgEdit}
                            src={Edit}
                            alt="editSchema"
                          />
                        </Fab>
                        <Fab
                          className={classes.fabEdit}
                          onClick={() => handleShowNotes( item.id )}
                        >
                          <img
                            className={classes.svgEdit}
                            src={gradeIcon}
                            alt="editSchema"
                          />
                        </Fab>
                        <Fab
                          className={classes.fabEdit}
                          onClick={() => handleDeleteScheme(
                            item.id,
                          )}
                        >
                          <img
                            className={classes.svgDelete}
                            src={Delete}
                            alt="deleteSchema"
                          />
                        </Fab>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          noWrap
                        >
                          {item && item.name}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          noWrap
                          className={classes.marginLeft}
                        >
                          {item && item.description}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="baseline"
                      >
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          noWrap
                          className={classes.marginLeft}
                        >
                          {item && item.percent}
                          %
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )) : (
              <Grid item xs={12}>
                <MessageCard>
                  <h3>Aún no hay esquemas de calificación.</h3>
                </MessageCard>
              </Grid>
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default SchemeByPartial;
