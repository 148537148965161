import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: 'calc(100vh - 60px)',
  },
});

interface IContainerProp {
  // eslint-disable-next-line react/require-default-props
  children?: JSX.Element[] | JSX.Element;
}

const ContainerSections: React.FC<IContainerProp> = ( props ) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <Grid container className={classes.root}>
      { children }
    </Grid>
  );
};

export default ContainerSections;
