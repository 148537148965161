import React, { useEffect } from 'react';
// Interface
import { useDispatch, useSelector } from 'react-redux';
// Redux
import {
  Grid,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { RootState } from '../../../../redux/store';
import {
  getSubjectsByCourseIdThunk,
} from '../../../../redux/ducks/SubjectDucks';
import {
  actions as actionsTeachers,
} from '../../../../redux/ducks/TeacherDucks';
import {
  actions as actionsBooks,
} from '../../../../redux/ducks/BookDucks';
// Components
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
// eslint-disable-next-line max-len
import SubjectGradeItem from '../../../../components/SubjectGradeItemStudent/SubjectGradeItemStudent';
import { ISubject } from '../../../../interfaces/ISubject';
import { useStyles } from './Styles';
// eslint-disable-next-line max-len
import HeaderSubjectList from './components/HeaderSubjectList/HeaderSubjectList';
import MessageCard from '../../../../components/MessageCard/MessageCard';
import { auth } from '../../../../services/firebase';

const AllMyGrades: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { userData } = useSelector((
    state: RootState,
  ) => state.login );

  const { subjects } = useSelector(( state: RootState ) => state.subject );

  useEffect(() => {
    dispatch( actionsTeachers.getTeachers());
    dispatch( actionsBooks.getBooks());
    dispatch( getSubjectsByCourseIdThunk( userData.courseId ));
  }, []);

  const handleShowGrades = ( idSubject: string, idTeacher: string ): void => {
    if ( !auth.currentUser ) {
      return;
    }
    const { uid } = auth.currentUser;
    history.push(
      `/estudiante/calificaciones/detalle/${idSubject}/${uid}/${idTeacher}`,
    );
  };

  return (
    <>
      <ContainerBreadcrumbs>
        <Breadcrumbs
          back={false}
          itemsLinks={[
            { name: 'Calificaciones', url: 'estudiante/calificaciones' }]}
          itemsText={['Todas tus calificaciones']}
        />
      </ContainerBreadcrumbs>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          className={classes.itemSubject}
        >
          <HeaderSubjectList />
        </Grid>
        {subjects && subjects.length > 0 ? (
          subjects
            && subjects.map(( item: ISubject, i: number ) => (
              <Grid
                item
                xs={12}
                key={i.toString()}
                className={classes.itemSubject}
              >
                <SubjectGradeItem
                  subject={item}
                  onClick={handleShowGrades}
                />
              </Grid>
            ))
        ) : (
          <MessageCard>
            <h3>No hay tareas para hoy.</h3>
          </MessageCard>
        )}
      </Grid>
    </>
  );
};

export default AllMyGrades;
