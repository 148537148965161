import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  marginRight: {
    marginRight: 'auto',
  },
  svg: {
    maxWidth: '4em',
  },
});
