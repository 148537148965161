import React, { useEffect } from 'react';

// Material Ui
import {
  Card,
  CardActions,
  makeStyles,
  Grid,
  Box,
  Fab,
} from '@material-ui/core';

// Redux
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ISubject } from '../../interfaces/ISubject';
import { actions } from '../../redux/ducks/TeacherDucks';

// Icons
import gradeIcon from '../../assets/svg/gradeIcon.svg';
import bookIcon from '../../assets/svg/bookIcon.svg';
// eslint-disable-next-line max-len
import SubjectTaskStatusArray from '../SubjectTaskStatus/SubjectTaskStatusArray';
import { ITask } from '../../interfaces/ITask';
import ToolTip from '../ToolTip/ToolTip';

interface ISubjectClassTaskItemStudentProps {
  subject: ISubject;
  tasks: ITask[];
}

const useStyles = makeStyles(( theme: any ) => ({
  shadow: {
    boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
  },
  cardActions: {
    padding: '1em 2em 1em 2em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  card: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    marginRight: '1em',
  },
  exclamationText: {
    margin: '0',
    fontWeight: 'bold',
  },
  svg: {
    maxWidth: '2.5em',
    minWidth: '2.5em',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '2em',
      minWidth: '2em',
    },
  },
  containerTask: {
    [theme.breakpoints.down( 'md' )]: {
      textAlign: 'center',
      marginBottom: '1em',
    },
  },
}));

const SubjectClassTaskItemStudent:
React.FC<ISubjectClassTaskItemStudentProps> = ( props ) => {
  const {
    subject,
    tasks,
  } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const history = useHistory();

  const handleNavigateTo = ( route: string ): void => {
    if ( route ) {
      history.push( route );
    }
  };

  useEffect(() => {
    dispatch( actions.getTeacher( subject.teacherId ));
  }, []);

  return (
    <Grid item xs={12} sm={7} md={6}>
      <Card className={`${classes.shadow} ${classes.card}`}>
        <CardActions className={classes.cardActions}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className={classes.containerTask}
                display="flex"
                justifyContent="center"
              >
                <ToolTip title="Notas por parcial">
                  <Fab
                    className={classes.margin}
                    aria-label="calificaciones"
                  >
                    <img className={classes.svg} src={gradeIcon} alt="icon" />
                  </Fab>
                </ToolTip>
                <ToolTip title="¡Ir al libro!">
                  <Fab
                    aria-label="book"
                    onClick={
                      () => handleNavigateTo(
                        subject.books
                      && subject.books.length > 0
                          ? `/estudiante/libros/ver/${subject.books[0]}` : '',
                      )
                    }
                  >
                    <img className={classes.svg} src={bookIcon} alt="icon" />
                  </Fab>

                </ToolTip>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box display="flex" justifyContent="center">
                <SubjectTaskStatusArray
                  tasks={tasks && tasks}
                  iconClasses={classes.svg}
                  iconTextClasses={classes.exclamationText}
                />
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default SubjectClassTaskItemStudent;
