import React, { useState, ChangeEvent } from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';

interface NumberFormatCustomProps {
  // eslint-disable-next-line no-unused-vars
  inputRef: ( instance: NumberFormat | null ) => void;
  // eslint-disable-next-line no-unused-vars
  onChange: ( event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom:React.FC<NumberFormatCustomProps> = ( props ) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={( values ) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator="."
      isNumericString
    />
  );
};

interface IProps {
  onSave: any;
  valueInitial: string;
}

const InputFormatDecimal: React.FC<IProps> = ( props ) => {
  const { onSave, valueInitial } = props;
  const [values, setValues] = useState<string>( valueInitial );

  const handleChange = ( event: ChangeEvent<HTMLInputElement> ): void => {
    setValues( event.target.value );

    setTimeout(() => {
      onSave( parseFloat( event.target.value ) || 0 );
    }, 1000 );
  };

  return (
    <TextField
      variant="outlined"
      size="small"
      value={values}
      onChange={handleChange}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
      fullWidth
    />
  );
};

export default InputFormatDecimal;
