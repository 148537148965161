/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';

// Material Ui
import {
  Card,
  CardActions,
  Typography,
  Grid,
  Box,
  CardHeader,
  CardMedia,
  Fab,
} from '@material-ui/core';
// Icons
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { ITask } from '../../interfaces/ITask';
import {
  formatAmOrPm, formatDateDDMMYY, formatTime, getDateWithSeconds,
} from '../../helpers/convert-to-timestamp';
import { taskDeleteThunk } from '../../redux/ducks/TaskDucks';
import DialogConfirm from '../DialogConfirm/DialogConfirm';
import { IStudent } from '../../interfaces/IStudent';
import SubjectTaskStatus from '../SubjectTaskStatus/SubjectTaskStatus';
import { useStyles } from './Styles';
import Edit from '../../assets/svg/Edit.svg';
import Delete from '../../assets/svg/Delete.svg';
import gradeIcon from '../../assets/svg/gradeIcon.svg';
import ToolTip from '../ToolTip/ToolTip';

interface ISubjectTaskItemTeacherProps {
  task: ITask;
  students: IStudent[];
}

interface Params {
  id: string;
}

const SubjectTaskItemTeacher :
React.FC<ISubjectTaskItemTeacherProps> = ( props ) => {
  const classes = useStyles();
  const {
    task,
    students,
  } = props;

  const params = useParams<Params>();

  const [taskItem, setTaskItem] = useState<any>();

  const [open, setOpen] = useState( false );

  const [idDoc, setIdDoc] = useState( '' );

  const history = useHistory();

  const dispatch = useDispatch();

  const handleGetDate = (): string => (
    formatDateDDMMYY(
      getDateWithSeconds( taskItem && taskItem.dateEnd.seconds ),
    )
  );

  const handleGetTime = (): string => (
    `${formatTime(
      getDateWithSeconds( taskItem && taskItem.dateEnd.seconds ),
    )} \
    ${formatAmOrPm(
      getDateWithSeconds( taskItem && taskItem.dateEnd.seconds ),
    )}`
  );

  const handleNavigateTo = ( route: string ): void => {
    if ( route ) {
      history.push( route );
    }
  };

  const handleOpenConfirm = ( id: string ): void => {
    setIdDoc( id );
    setOpen( true );
  };

  const handleDelete = ( confirm: boolean ): void => {
    if ( confirm ) {
      dispatch( taskDeleteThunk( idDoc, params.id ));
    }
    setOpen( false );
  };

  useEffect(() => {
    setTaskItem( task );
  }, [props]);

  return (
    <>
      <Card className={`${classes.shadow} ${classes.card}`}>
        <CardHeader
          className={classes.cardHeader}
          title={(
            <>
              <Grid container>
                <Grid item xs={10}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.titleContainer}
                  >
                    <Typography
                      className={`${classes.title} textTruncate`}
                      color="textSecondary"
                      gutterBottom
                      component="span"
                    >
                      {taskItem && ( taskItem.title )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2} className={classes.paperColor}>
                  <ToolTip title="Editar la tarea">
                    <Fab
                      className={classes.fabEdit}
                      onClick={
                        () => handleNavigateTo(
                          taskItem ? `/docente/tareas/editar/${taskItem.id}` : '',
                        )
                      }
                    >
                      <img
                        className={classes.svgEdit}
                        src={Edit}
                        alt="editHomework"
                      />
                    </Fab>
                  </ToolTip>
                </Grid>
              </Grid>
            </>
          )}
        />
        <CardMedia
          className={classes.media}
          image="https://papers.co/wallpaper/papers.co-ag78-google-lollipop-january-blue-background-36-3840x2400-4k-wallpaper.jpg"
          title="Estudiantes"
          style={{ position: 'relative' }}
        >
          <div className={classes.overlay}>
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <SubjectTaskStatus
                students={students && students}
                task={task && task}
                key={task.id}
                iconClasses={classes.svg}
                iconTextClasses={classes.exclamationText}
              />
            </Box>
          </div>
        </CardMedia>
        <CardActions>
          <Grid container>
            <Grid item xs={12}>
              <Box p={1} display="flex" justifyContent="space-between">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.fabEditContainer}
                >
                  <ToolTip title="Calificar la tarea">
                    <Fab
                      className={classes.fabEdit}
                      onClick={
                        () => handleNavigateTo(
                          taskItem
                            ? `/docente/tareas/calificaciones/${taskItem.id}`
                            : '',
                        )
                      }
                    >
                      <img
                        className={classes.svgEdit}
                        src={gradeIcon}
                        alt="editHomework"
                      />
                    </Fab>
                  </ToolTip>
                </Box>
                <Box>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {`Entrega: ${handleGetDate()}`}
                  </Typography>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {`Hora: ${handleGetTime()}`}
                  </Typography>
                </Box>
                <ToolTip title="Eliminar la tarea">
                  <Fab
                    onClick={() => handleOpenConfirm( taskItem.id )}
                  >
                    <img
                      className={classes.svgDelete}
                      src={Delete}
                      alt="deleteHomework"
                    />
                  </Fab>

                </ToolTip>
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <DialogConfirm
        open={open}
        title="¿ Desea eliminar la tarea ?"
        onClose={handleDelete}
      />
    </>
  );
};

export default SubjectTaskItemTeacher;
