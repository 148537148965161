import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 10px',
    borderRadius: 10,
    backgroundColor: '#E7E6E6',
  },
  title: {
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
    fontSize: 18,
  },
  itemRubric: {
    marginBottom: 10,
  },
  comment: {
    height: 200,
  },
  footer: {
    marginTop: 10,
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: '10px 5px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    fontSize: 18,
  },
  iconCalc: {
    color: '#ffffff',
  },
}));

export default useStyles;
