import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box, Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getRoute } from '../../helpers/get-user-by-type';
import planning from '../../assets/svg/planning.svg';

// Menu
import { MENU } from './MenuLinks';
import { ILinks } from '../../interfaces/ILinks';

// MaterialUi

import Logo from '../../assets/img/LogoMyBook.png';
import { RootState } from '../../redux/store';
import { getInstituteThunk } from '../../redux/ducks/InstituteDucks';

const useStyles = makeStyles({
  mt1: {
    marginTop: 10,
  },
  mb1: {
    marginBottom: 0,
  },
  contentIcon: {
    backgroundColor: '#fff',
    width: 40,
    height: 40,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  link: {
    textDecoration: 'none!important',
    color: `rgba(0, 0, 0, 0.50)`,
  },
  whiteTextColor: {
    color: `white`,
    fontSize: 12,
    marginTop: 10,
    marginBottom: 20,
  },
  contentLogo: {
    backgroundColor: '#fff',
    width: 50,
    height: 50,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 30,
  },
  logo: {
    width: '80%',
    borderRadius: '100%',
  },
  svg: {
    maxWidth: '2em',
  },
});

const SidebarContent: React.FC = () => {
  const classes = useStyles();
  const {
    userData,
  } = useSelector(( state: RootState ) => state.login );
  const {
    formData,
  } = useSelector(( state: RootState ) => state.institute );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch( getInstituteThunk());
  }, []);
  return (
    <>
      <Box display="flex" justifyContent="center" mt="1em" mb="1em">
        <Link to={`/${getRoute( userData.type )}/inicio`}>
          <div className={classes.contentLogo}>
            <img
              className={classes.logo}
              src={Logo}
              alt="MyBookLogo"
            />
          </div>
        </Link>
      </Box>
      <Box display="block" textAlign="center">
        {userData && MENU.map(( item: ILinks, i: number ) => (
          userData.type === item.typeUser && (
            <Link
              to={`${item.link}`}
              key={i.toString()}
              className={classes.link}
            >
              <div className={`${classes.contentIcon}`}>
                <img className={classes.svg} src={item.icon} alt="" />
              </div>
              <Typography className={classes.whiteTextColor}>
                {item.name}
              </Typography>
            </Link>
          )
        ))}
        {userData.type === 2 && formData && formData.planning && (
          <a
            href={formData.planning}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <div className={`${classes.contentIcon}`}>
              <img className={classes.svg} src={planning} alt="planningIcon" />
            </div>
            <Typography className={classes.whiteTextColor}>
              Planificación
            </Typography>
          </a>
        )}
      </Box>
    </>
  );
};

export default SidebarContent;
