import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: ( isDragging ) => ({
    backgroundColor: isDragging ? '#000' : '',
    borderRadius: 5,
    marginBottom: 5,
    padding: 5,
    opacity: isDragging ? 0.6 : 1,
    color: isDragging ? '#fff' : '#000',
    border: '1px solid #000',
    textAlign: 'left',
  }),
  item: {
    width: '100%',
    display: 'block',
    cursor: 'pointer',
  },
}));

export default useStyles;
