import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import SubjectForm from '../../../components/SubjectForm/SubjectForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import { ISubject } from '../../../interfaces/ISubject';
import {
  getSubjectByIdThunk, updateSubjectThunk,
} from '../../../redux/ducks/SubjectDucks';
import { RootState } from '../../../redux/store';
import Card from '../../../components/Card/Card';
import Spinner from '../../../components/Spinner/Spinner';

interface IParams {
  id: string;
}

const SubjectEdit = ( props: any ): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formData, loading } = useSelector((
    state: RootState,
  ) => state.subject );
  const { id } = useParams<IParams>();

  const handlerSubmit = ( values: any ): void => {
    const subject = { ...values, id } as ISubject;
    dispatch( updateSubjectThunk( subject ));
    history.push( '/admin/materias' );
  };

  useEffect(() => {
    if ( id ) {
      dispatch( getSubjectByIdThunk( id ));
    }
  }, [props]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'Materias', url: 'admin/materias' }]}
              itemsText={['Editar una nueva Materia']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          { !loading
            ? <SubjectForm onSubmit={handlerSubmit} initialValues={formData} />
            : (
              <Spinner />
            )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default SubjectEdit;
