import { Dispatch } from 'redux';
import { existUserByEmail } from '../services/Users.Services';

export const existEmail = async (
  values: any, dispatch: Dispatch<any>, props: any,
): Promise<void> => {
  if ( props.initialValues ) {
    const { initialValues: { email } } = props;
    if ( email !== values.email ) {
      const exist = await existUserByEmail( values.email );
      if ( exist ) {
        // eslint-disable-next-line no-throw-literal
        throw {
          email: `El correo ingresado ya existe, ingrese otro por favor.`,
        };
      }
    }
  } else {
    const exist = await existUserByEmail( values.email );
    if ( exist ) {
      // eslint-disable-next-line no-throw-literal
      throw { email: `El correo ingresado ya existe, ingrese otro por favor.` };
    }
  }
};
