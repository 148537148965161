import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(( theme ) => ({
  marginLeft: {
    // marginLeft: '2em',
  },
  noMargin: {
    // marginBottom: '0!important',
    // marginTop: '0!important',
  },
  card: {
    minHeight: '100%',
    padding: '0px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  red: {
    color: '#FF2950',
    fontSize: '2em',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '2em',
    },
  },
  yellow: {
    color: '#F99307',
    fontSize: '2em',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '2em',
    },
  },
  green: {
    color: '#27890D',
    fontSize: '2em',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '2em',
    },
  },
  blue: {
    color: '#249FF2',
    fontSize: '2em',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '2em',
    },
  },
  svgEdit: {
    maxWidth: '1.8em',
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    whiteSpace: 'nowrap',
    maxWidth: '30em',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '8em',
    },
  },
  cardContent: {
    // padding: '0 1em 0 1em!important',
  },
}));
