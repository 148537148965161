import { ThunkAction } from 'redux-thunk';
import { Dispatch, AnyAction } from 'redux';
import { toast } from 'react-toastify';
import { RootState } from '../store';
import { db } from '../../services/firebase';
import { ITask } from '../../interfaces/ITask';
import { timeStampToStringDateTime } from '../../helpers/convert-to-timestamp';
import { asyncForEach } from '../../helpers/async-foreach';

const COLLECTION_TASKS = 'tasks';

export const actionType = {
  CREATE_TASK_REQUEST: 'CREATE_TASK_REQUEST',
  CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',
  CREATE_TASK_ERROR: 'CREATE_TASK_ERROR',
  GET_ALL_TASK_REQUEST: 'GET_ALL_TASK_REQUEST',
  GET_ALL_TASK_SUCCESS: 'GET_ALL_TASK_SUCCESS',
  GET_ALL_TASK_ERROR: 'GET_ALL_TASK_ERROR',
  GET_TASK_REQUEST: 'GET_TASK_REQUEST',
  GET_TASK_SUCCESS: 'GET_TASK_SUCCESS',
  GET_TASK_ERROR: 'GET_TASK_ERROR',
  UPDATE_TASK_REQUEST: 'UPDATE_TASK_REQUEST',
  UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',
  UPDATE_TASK_ERROR: 'UPDATE_TASK_ERROR',
  GET_TASK_BY_DAY_SUCCESS: 'GET_TASK_BY_DAY_SUCCESS',
  GET_TASK_FOR_GRADE_SUCCESS: 'GET_TASK_FOR_GRADE_SUCCESS',
  SET_ID_TASK: 'SET_ID_TASK',
  SET_INITIAL_VALUES_FORMTASK: 'SET_INITIAL_VALUES_FORMTASK',
  GET_NOTE_BY_STUDENT_REQUEST: 'GET_NOTE_BY_STUDENT_REQUEST',
  GET_NOTE_BY_STUDENT_SUCCESS: 'GET_NOTE_BY_STUDENT_SUCCESS',
  GET_NOTE_BY_STUDENT_ERROR: 'GET_NOTE_BY_STUDENT_ERROR',
  UPDATE_NOTE_REQUEST: 'UPDATE_NOTE_REQUEST',
  UPDATE_NOTE_SUCCESS: 'UPDATE_NOTE_SUCCESS',
  UPDATE_NOTE_ERROR: 'UPDATE_NOTE_ERROR',
  GET_ALL_TASK_NOTES_SUCCESS: 'GET_ALL_TASK_NOTES_SUCCESS',
  CLEAR_TASKS_NOTES: 'CLEAR_TASKS_NOTES',
};

export interface IInitialStateTask {
  loading: boolean;
  errors: string;
  formData: ITask | null;
  tasks?: ITask[];
  taskNotes?: any[];
  taskByDay: ITask[];
  idTask?: string;
  noteData: any;
}

const initialState: IInitialStateTask = {
  loading: false,
  errors: '',
  tasks: [],
  taskByDay: [],
  formData: null,
  noteData: null,
  taskNotes: [],
};

export default function taskReducer(
  state = initialState, action: AnyAction,
): any {
  const { type, payload } = action;

  switch ( type ) {
    case actionType.GET_ALL_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_ALL_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: payload,
      };
    case actionType.GET_ALL_TASK_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.CREATE_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.CREATE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.CREATE_TASK_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.UPDATE_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPDATE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.UPDATE_TASK_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.GET_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        formData: {
          ...payload,
          dateStart: timeStampToStringDateTime( payload.dateStart.seconds ),
          dateEnd: timeStampToStringDateTime( payload.dateEnd.seconds ),
        },
      };
    case actionType.GET_TASK_BY_DAY_SUCCESS:
      return {
        ...state,
        loading: false,
        taskByDay: payload,
      };
    case actionType.GET_TASK_FOR_GRADE_SUCCESS:
      return {
        ...state,
        loading: false,
        tasksForGrade: payload,
      };
    case actionType.GET_TASK_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.SET_ID_TASK:
      return {
        ...state,
        idTask: payload,
      };
    case actionType.SET_INITIAL_VALUES_FORMTASK:
      return {
        ...state,
        formData: null,
      };
    case actionType.GET_NOTE_BY_STUDENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_NOTE_BY_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        noteData: payload,
      };
    case actionType.GET_NOTE_BY_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.UPDATE_NOTE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.UPDATE_NOTE_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.GET_ALL_TASK_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        taskNotes: payload,
      };
    case actionType.CLEAR_TASKS_NOTES:
      return {
        ...state,
        taskNotes: [],
      };
    default:
      return state;
  }
}

export const actions = {
  createTaskRequest: (): AnyAction => (
    { type: actionType.CREATE_TASK_REQUEST }),
  createTaskSuccess: (): AnyAction => (
    { type: actionType.CREATE_TASK_SUCCESS }),
  createTaskError: ( payload: string ): AnyAction => ({
    type: actionType.CREATE_TASK_ERROR,
    payload,
  }),
  getAllTaskRequest: (): AnyAction => (
    { type: actionType.GET_ALL_TASK_REQUEST }),
  getAllTaskSuccess: ( payload: ITask[]): AnyAction => ({
    type: actionType.GET_ALL_TASK_SUCCESS,
    payload,
  }),
  getAllTaskError: ( payload: string ): AnyAction => ({
    type: actionType.GET_ALL_TASK_ERROR,
    payload,
  }),
  getAllTaskWithNotesSuccess: ( payload: any[]): AnyAction => ({
    type: actionType.GET_ALL_TASK_NOTES_SUCCESS,
    payload,
  }),
  updateTaskRequest: (): AnyAction => (
    { type: actionType.UPDATE_TASK_REQUEST }),
  updateTaskSuccess: (): AnyAction => (
    { type: actionType.UPDATE_TASK_SUCCESS }),
  updateTaskError: ( payload: string ): AnyAction => ({
    type: actionType.UPDATE_TASK_ERROR,
    payload,
  }),
  getTaskRequest: (): AnyAction => (
    { type: actionType.GET_TASK_REQUEST }),
  getTaskSuccess: ( payload?: ITask ): AnyAction => ({
    type: actionType.GET_TASK_SUCCESS,
    payload,
  }),
  getTaskByDaySuccess: ( payload?: ITask | any ): AnyAction => ({
    type: actionType.GET_TASK_BY_DAY_SUCCESS,
    payload,
  }),
  getTaskForGradesSuccess: ( payload?: ITask | any ): AnyAction => ({
    type: actionType.GET_TASK_FOR_GRADE_SUCCESS,
    payload,
  }),
  getTaskError: ( payload: string ): AnyAction => ({
    type: actionType.GET_TASK_ERROR,
    payload,
  }),
  setIdTask: ( payload: string ): AnyAction => ({
    type: actionType.SET_ID_TASK,
    payload,
  }),
  setInicitalFormTask: (): AnyAction => ({
    type: actionType.SET_INITIAL_VALUES_FORMTASK,
  }),
  clearTasksNotes: (): AnyAction => ({
    type: actionType.CLEAR_TASKS_NOTES,
  }),
  getNoteByStudentIdRequest: (): AnyAction => (
    { type: actionType.GET_NOTE_BY_STUDENT_REQUEST }),
  getNoteByStudentIdSuccess: ( payload: any ): AnyAction => ({
    type: actionType.GET_NOTE_BY_STUDENT_SUCCESS,
    payload,
  }),
  getNoteByStudentIdError: ( payload: string ): AnyAction => ({
    type: actionType.GET_NOTE_BY_STUDENT_ERROR,
    payload,
  }),
  updateNoteRequest: (): AnyAction => (
    { type: actionType.UPDATE_NOTE_REQUEST }),
  updateNoteSuccess: (): AnyAction => (
    { type: actionType.UPDATE_NOTE_SUCCESS }),
  updateNoteError: ( payload: string ): AnyAction => ({
    type: actionType.UPDATE_NOTE_ERROR,
    payload,
  }),
};

export const getTasksAllThunk = ():
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getAllTaskRequest());
  try {
    db.collection( COLLECTION_TASKS )
      .onSnapshot(( querySnapshot ) => {
        const docs: ITask[] = [];
        if ( !querySnapshot.empty ) {
          querySnapshot.forEach(( doc ) => {
            const task = doc.data() as ITask;
            task.id = doc.id;
            if ( !task.deleted ) {
              docs.push( task );
            }
          });

          dispatch( actions.getAllTaskSuccess( docs ));
        } else {
          dispatch( actions.getAllTaskSuccess( docs ));
          dispatch( actions.getAllTaskError( 'No existen datos' ));
        }
      });
  } catch ( error ) {
    dispatch( actions.getAllTaskError( 'No se pudo obtener los datos.' ));
  }
};

export const createTaskThunk = ( data: ITask ):
ThunkAction<
void, RootState, null, AnyAction
> => async ( dispatch: Dispatch<any> ) => {
  dispatch( actions.createTaskRequest());
  try {
    const task = { ...data, students: [], deleted: false };
    const { id } = await db.collection( COLLECTION_TASKS ).add( task );
    dispatch( actions.createTaskSuccess());
    dispatch( actions.setIdTask( id ));
    toast.success( 'Nueva tarea agregada.' );
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    dispatch( actions.createTaskError( 'No se pudo guardar los datos.' ));
  }
};

export const getTaskByIdThunk = ( id: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getTaskRequest());
  try {
    dispatch( actions.setInicitalFormTask());
    const doc = await db.collection( COLLECTION_TASKS ).doc( id ).get();
    if ( doc.exists ) {
      const task = { ...doc.data(), id: doc.id } as ITask;
      dispatch( actions.getTaskSuccess( task ));
    } else {
      dispatch( actions.getTaskError( 'La tarea no existe' ));
    }
  } catch ( error ) {
    dispatch( actions.getTaskError( 'No se pudo obtener los datos.' ));
  }
};

export const updateTaskThunk = ( data: ITask ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: any ) => {
  dispatch( actions.updateTaskRequest());
  try {
    await db.collection( COLLECTION_TASKS ).doc( data.id ).update( data );
    dispatch( actions.updateTaskSuccess());
    toast.success( 'Tarea editada correctamente.' );
  } catch ( error ) {
    dispatch(
      actions.updateTaskError( 'No se pudo eliminar la tarea.' ),
    );
  }
};

export const updateTaskActivitiesThunk = ( id: string, data: string[]):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.updateTaskRequest());
  try {
    await db.collection( COLLECTION_TASKS )
      .doc( id ).update({ activities: data });
    dispatch( actions.updateTaskSuccess());
    toast.success( 'Actividad editada correctamente.' );
  } catch ( error ) {
    dispatch(
      actions.updateTaskError(
        'No se pudo actualizar  los libros de la materia.',
      ),
    );
  }
};

export const updateTaskActivitiesBooksThunk = ( idTask: string, data: string[]):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: any ) => {
  dispatch( actions.updateTaskRequest());
  try {
    await db.collection( COLLECTION_TASKS )
      .doc( idTask ).update({ activitiesBook: data });
    dispatch( actions.updateTaskSuccess());
    dispatch( getTaskByIdThunk( idTask ));
    toast.success( 'Actividad editada correctamente.' );
  } catch ( error ) {
    dispatch(
      actions.updateTaskError(
        'No se pudo actualizar  los libros de la materia.',
      ),
    );
  }
};

export const taskDeleteThunk = ( id: string, idSubject?: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch ) => {
  dispatch( actions.updateTaskRequest());
  try {
    await db.collection( COLLECTION_TASKS ).doc( id ).update({ deleted: true });
    dispatch( actions.updateTaskSuccess());
    if ( idSubject ) {
      dispatch( getAllTaskBySubjectIdTeacherThunk( idSubject ));
    }
    toast.success( 'Tarea eliminada correctamente.' );
  } catch ( error ) {
    dispatch(
      actions.updateTaskError( 'No se pudo eliminar la tarea.' ),
    );
  }
};

export const getAllTaskBySubjectIdStudentThunk = (
  subjectId: string, studentId: string,
):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getAllTaskSuccess([]));
  dispatch( actions.clearTasksNotes());
  dispatch( actions.getAllTaskRequest());
  try {
    const dataRes: any = [];
    const tasks = await db.collection( COLLECTION_TASKS )
      .where( 'subjectId', '==', subjectId )
      .where( 'deleted', '==', false )
      .orderBy( 'creation', 'desc' )
      .get();
    if ( tasks.empty ) {
      dispatch( actions.getAllTaskSuccess( dataRes ));
      return;
    }
    const { docs } = tasks;
    await asyncForEach( docs, async ( doc: any ) => {
      const item = doc.data();
      const notes: any[] = [];
      const data = await db.collection( COLLECTION_TASKS )
        .doc( doc.id )
        .collection( 'notes' )
        .where( 'idStudent', '==', studentId )
        .get();
      if ( !data.empty ) {
        data.forEach(( note ) => {
          notes.push({ ...note.data(), id: note.id });
        });
      }
      dataRes.push({
        ...item, id: doc.id, notes,
      });
    });
    dispatch( actions.getAllTaskWithNotesSuccess( dataRes ));
  } catch ( error ) {
    dispatch( actions.getAllTaskError( 'No se pudo obtener los datos.' ));
  }
};

export const getAllTaskBySubjectIdTeacherThunk = ( subjectId: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  try {
    dispatch( actions.getAllTaskRequest());
    const dataRes: any = [];
    const tasks = await db.collection( COLLECTION_TASKS )
      .where( 'subjectId', '==', subjectId )
      .where( 'deleted', '==', false )
      .orderBy( 'creation', 'desc' )
      .get();
    if ( tasks.empty ) {
      dispatch( actions.getAllTaskSuccess( dataRes ));
      return;
    }
    const { docs } = tasks;
    await asyncForEach( docs, async ( doc: any ) => {
      const item = doc.data();
      const notes:any = [];
      const data = await db.collection( COLLECTION_TASKS )
        .doc( doc.id )
        .collection( 'notes' )
        .get();
      data.forEach(( note ) => (
        notes.push({ ...note.data(), id: note.id })
      ));
      dataRes.push({
        ...item, id: doc.id, notes,
      });
    });
    dispatch( actions.getAllTaskSuccess( dataRes ));
  } catch ( error ) {
    dispatch( actions.getAllTaskError( 'No se pudo obtener los datos.' ));
  }
};

export const getAllTaskByDayInCalendarThunk = (
  dayInCalendar: Date, idStudent: string, courseId: string,
):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getAllTaskRequest());
  try {
    const dayCopy = new Date( dayInCalendar );
    const dateStart = new Date(
      dayCopy.getFullYear(), dayCopy.getMonth(), dayCopy.getDate(), 0, 0, 0,
    );
    const dateEnd = new Date(
      dayCopy.getFullYear(), dayCopy.getMonth(), dayCopy.getDate(), 23, 59, 59,
    );
    const dataRes: any = [];
    const courses = await db.collection( 'subjects' )
      .where( 'courseId', '==', courseId )
      .get();
    if ( courses.empty ) {
      dispatch( actions.getAllTaskSuccess( dataRes ));
      return;
    }
    const { docs } = courses;
    await asyncForEach( docs, async ( doc: any ) => {
      const item = doc.data();
      const tasks = await db.collection( COLLECTION_TASKS )
        .where( 'dateStart', '>=', dateStart )
        .where( 'dateStart', '<=', dateEnd )
        .where( 'subjectId', '==', doc.id )
        .get();
      const { docs: docsTasks } = tasks;
      await asyncForEach( docsTasks, async ( task: any ) => {
        const notes: any = [];
        const itemTask = task.data();
        if ( !itemTask.deleted ) {
          const noteData = await db.collection( COLLECTION_TASKS )
            .doc( task.id )
            .collection( 'notes' )
            .where( 'idStudent', '==', idStudent )
            .get();
          noteData.forEach(( note ) => (
            notes.push({ ...note.data(), id: note.id })
          ));
          dataRes.push({ ...itemTask, subject: item?.name, notes });
        }
      });
    });
    dispatch( actions.getTaskByDaySuccess( dataRes ));
  } catch ( error ) {
    dispatch( actions.getAllTaskError( 'No se pudo obtener los datos.' ));
  }
};

export const getAllTaskByFirstDayCalendarThunk = ( courseId: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getTaskByDaySuccess([]));
  dispatch( actions.getAllTaskRequest());
  try {
    const dataRes: any = [];
    const date = new Date();
    const day = new Date( date.getFullYear(), date.getMonth(), 1 );
    const courses = await db.collection( 'subjects' )
      .where( 'courseId', '==', courseId )
      .get();
    if ( courses.empty ) {
      dispatch( actions.getAllTaskSuccess( dataRes ));
    }
    const { docs } = courses;
    await asyncForEach( docs, async ( doc: any ) => {
      const tasks = await db.collection( COLLECTION_TASKS )
        .where( 'dateStart', '>=', day )
        .where( 'subjectId', '==', doc.id )
        .get();
      const { docs: docsTasks } = tasks;
      await asyncForEach( docsTasks, async ( docTask: any ) => {
        const item = docTask.data();
        if ( item.deleted === false ) {
          const notes = await db.collection( COLLECTION_TASKS )
            .doc( docTask.id )
            .collection( 'notes' )
            .get();
          dataRes.push({ ...item, notes });
        }
      });
    });
    dispatch( actions.getAllTaskSuccess( dataRes ));
  } catch ( error ) {
    dispatch( actions.getAllTaskError( 'No se pudo obtener los datos.' ));
  }
};

export const getAllTaskByCurrentDayCalendarAndTeacherIdThunk = (
  teacherId: string,
):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getAllTaskRequest());
  try {
    const dataRes: any = [];
    const date = new Date();
    const dateStart = new Date(
      date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0,
    );
    const dateEnd = new Date(
      date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59,
    );
    const tasks = await db.collection( COLLECTION_TASKS )
      .where( 'dateEnd', '>=', dateStart )
      .where( 'dateEnd', '<=', dateEnd )
      .where( 'teacherId', '==', teacherId )
      .get();
    if ( tasks.empty ) {
      dispatch( actions.getTaskByDaySuccess( dataRes ));
    }
    const { docs } = tasks;

    await asyncForEach( docs, async ( doc: any ) => {
      const item = doc.data();
      const notes: any[] = [];
      const students: any[] = [];
      if ( !item.deleted ) {
        const dataNotes = await db.collection( COLLECTION_TASKS )
          .doc( doc.id )
          .collection( 'notes' )
          .get();
        dataNotes.forEach(( note ) => {
          notes.push({ ...note.data(), id: note.id });
        });
        const dataSubject = await db.collection( 'subjects' )
          .doc( item.subjectId )
          .get();
        const subject: any = dataSubject.data();
        const dataStudents = await db.collection( 'users' )
          .where( 'courseId', '==', subject.courseId )
          .where( 'type', '==', 3 )
          .get();
        dataStudents.forEach(( student ) => {
          students.push({ ...student.data(), id: student.id });
        });
        dataRes.push({
          ...item, notes, students, subject,
        });
      }
    });
    dispatch( actions.getTaskByDaySuccess( dataRes ));
  } catch ( error ) {
    dispatch( actions.getAllTaskError( 'No se pudo obtener los datos.' ));
  }
};

export const createGradeThunk = (
  id: string, data: any, task: ITask, idStudent: string,
):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: any ) => {
  dispatch( actions.updateTaskRequest());
  try {
    await db.collection( COLLECTION_TASKS )
      .doc( id ).collection( 'notes' )
      .doc( idStudent )
      .set( data );

    await db.collection( COLLECTION_TASKS )
      .doc( task.id ).update({ students: task.students });

    dispatch( actions.updateTaskSuccess());
    dispatch(
      getAllTaskBySubjectIdStudentThunk( task.subjectId, data.idStudent ),
    );
    toast.success( 'Tarea actualizada y nota generada' );
  } catch ( error ) {
    dispatch(
      actions.updateTaskError(
        'No se pudo actualizar la tarea.',
      ),
    );
  }
};

export const getAllNotesByTaskIdAndAddStudentsThunk = ( taskId: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getTaskRequest());
  try {
    const doc = await db.collection( COLLECTION_TASKS ).doc( taskId ).get();
    if ( !doc.exists ) {
      dispatch( actions.getTaskError( 'La tarea no existe' ));
    }
    const notes:any = [];
    const noteTask = await db.collection( COLLECTION_TASKS )
      .doc( doc.id )
      .collection( 'notes' )
      .get();
    const { docs } = noteTask;
    await asyncForEach( docs, async ( item: any ) => {
      const each = item.data();
      const student = await db.collection( 'users' )
        .doc( each.idStudent )
        .get();
      notes.push({ ...item.data(), id: item.id, student: student.data() });
    });
    const task = { ...doc.data(), id: doc.id, notes } as ITask;
    dispatch( actions.getTaskSuccess( task ));
  } catch ( error ) {
    dispatch( actions.getAllTaskError( 'No se pudo obtener los datos.' ));
  }
};

export const getNoteByStudentIdThunk = ( idTask: string, idStudent: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getNoteByStudentIdRequest());
  try {
    const note = await db.collection( COLLECTION_TASKS )
      .doc( idTask )
      .collection( 'notes' )
      .doc( idStudent )
      .get();
    if ( note.exists ) {
      const noteAux = note.data();
      dispatch( actions.getNoteByStudentIdSuccess( noteAux ));
    } else {
      dispatch( actions.getNoteByStudentIdSuccess( undefined ));
    }
  } catch ( error ) {
    // TODO:
    dispatch( actions.getNoteByStudentIdError(
      'Error al obtener la nota',
    ));
  }
};

export const updateNoteByIdThunk = ( idTask: string, note: any ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: any ) => {
  dispatch( actions.updateNoteRequest());
  try {
    await db.collection( COLLECTION_TASKS )
      .doc( idTask )
      .collection( 'notes' )
      .doc( note.id )
      .update( note );

    dispatch( actions.updateNoteSuccess());

    toast.success( 'Nota de la tarea actualizada.' );
  } catch ( error ) {
    dispatch(
      actions.updateNoteError(
        'No se pudo actualizar la Nota.',
      ),
    );
  }
};

export const getTasksAllByCoursePartialAndSubjectThunk = ( subjectId: string, partial: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getAllTaskRequest());
  try {
    const dataRes: any[] = [];
    const tasks = await db.collection( COLLECTION_TASKS )
      .where( 'subjectId', '==', subjectId )
      .where( 'partial', '==', partial )
      .where( 'deleted', '==', false )
      .get();
    if ( !tasks.empty ) {
      dispatch( actions.getTaskForGradesSuccess([]));
    }
    const { docs } = tasks;
    docs.forEach(( item ) => {
      dataRes.push({ ...item.data(), id: item.id });
    });
    dispatch( actions.getTaskForGradesSuccess( dataRes ));
  } catch ( error ) {
    dispatch( actions.getAllTaskError( 'No se pudo obtener los datos.' ));
  }
};
