import React from 'react';
import {
  List, ListItem, ListItemText, ListItemIcon,
} from '@material-ui/core';

interface IItemListProps {
  // eslint-disable-next-line react/require-default-props
  data?: any[];
  // eslint-disable-next-line react/require-default-props
  iconItem?: JSX.Element;
}

const ItemListData: React.FC<IItemListProps> = ( props ) => {
  const { data = [], iconItem } = props;
  return (
    <List dense component="div" role="list">
      {data.map(( item: any, index: number ) => (
        <ListItem key={index.toString()} role="listitem">
          <ListItemIcon>
            {iconItem}
          </ListItemIcon>
          <ListItemText
            primary={`${item.name} ${item.surname} - ${item.email}`}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ItemListData;
