import React from 'react';
import CheckboxMaterial from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import { WrappedFieldProps } from 'redux-form';

interface ICheckBoxProps {
  // eslint-disable-next-line react/require-default-props
  handleChange?: () => unknown;
  // eslint-disable-next-line react/require-default-props
  title?: string;
}

const Checkbox: React.FC<WrappedFieldProps & ICheckBoxProps> = ({
  meta, ...props
}) => {
  const { title, input } = props;
  const { touched, error, warning } = meta;

  return (
    <div>
      <FormControlLabel
        control={(
          <CheckboxMaterial
            {...input}
            name="onlyRead"
            color="primary"
          />
        )}
        label={title}
      />
      {touched
        && (( error && <span>{error}</span> )
          || ( warning && <span>{warning}</span> ))}
    </div>
  );
};

export default Checkbox;
