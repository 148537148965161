import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  iconAddAct: {
    height: 30,
    width: 30,
  },
  containerAdd: {
    maxWidth: 200,
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 10,
    cursor: 'pointer',
    borderRadius: 10,
  },
  titleActivitiesArea: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 20,
  },
}));

export default useStyles;
