import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import StudentForm from '../../../components/StudentForm/StudentForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { createStudentThunk } from '../../../redux/ducks/StudentDucks';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import { IStudent } from '../../../interfaces/IStudent';
import Card from '../../../components/Card/Card';

const StudentCreate = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handlerSubmit = ( values: any ): void => {
    const { photo, ...data } = values;
    const student: IStudent = { ...data };
    student.type = 3;
    dispatch( createStudentThunk( student, photo ));
    history.push( '/admin/estudiantes' );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'Estudiantes', url: 'admin/estudiantes' }]}
              itemsText={['Crear nuevo estudiante']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <StudentForm onSubmit={handlerSubmit} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default StudentCreate;
