import React, { useEffect, useState } from 'react';
import { formValueSelector } from 'redux-form';
import { Grid, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import TaskForm from '../../../../components/TaskForm/TaskForm';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import { ITask } from '../../../../interfaces/ITask';
import {
  updateTaskThunk, getTaskByIdThunk, updateTaskActivitiesBooksThunk,
} from '../../../../redux/ducks/TaskDucks';
import {
  actions as actionsActivity,
} from '../../../../redux/ducks/ActivityDucks';
import { RootState } from '../../../../redux/store';
import { stringToTimeStamp } from '../../../../helpers/convert-to-timestamp';
import { auth } from '../../../../services/firebase';
import Card from '../../../../components/Card/Card';
import Spinner from '../../../../components/Spinner/Spinner';
import Add from '../../../../assets/svg/Add.svg';
import useStyles from './Styles';
import Image from '../../../../components/Image/Image';
import CenterInLine from '../../../../components/Center/CenterInLine';
import Dialog from '../../../../components/Dialog/Dialog';
import ItemDialog from './components/ItemDialog/ItemDialog';
import ItemActivityList from './components/ItemActivityList/ItemActivityList';
import { IActivity } from '../../../../interfaces/IActivity';
import DialogConfirm from '../../../../components/DialogConfirm/DialogConfirm';
import { getActivitiesById } from '../../../../services/Activities.Services';
import BookActivity from '../../../../assets/svg/book_activity.svg';
import OwnActivity from '../../../../assets/svg/own_activity.svg';
import LabelInfo from '../../../../components/LabelInfo/LabelInfo';
// eslint-disable-next-line max-len
import TransferListActivities from './components/TransferListActivities/TransferListActivities';

interface IParams {
  id: string;
}

const TasksEdit: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { formData, loading } = useSelector((
    state: RootState,
  ) => state.task );
  const { activities, activitiesBook } = useSelector((
    state: RootState,
  ) => state.activity );
  const subject = useSelector(( state: RootState ) => state.subject.formData );

  const selector = formValueSelector( 'task' );
  const bookId = useSelector(
    ( state: RootState ) => selector( state, 'bookId' ),
  );
  const unitId = useSelector(
    ( state: RootState ) => selector( state, 'unitId' ),
  );

  const { id } = useParams<IParams>();
  const [openConfirm, setOpenConfirm] = useState( false );
  const [openDialog, setOpenDialog] = useState( false );
  const [idActDoc, setIdActDoc] = useState( '' );
  const [openAddActivity, setOpenAddActivity] = useState( false );
  const [actBookList, setActBookList] = useState<IActivity[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [closedTransfer, setClosedTransfer] = useState( false );

  useEffect(() => {
    handleGetData();
    // TODO: Verificar el porque debo consultar todas las actividades del libro
    // dispatch( actionsActivity.getActivities());
    if ( id ) {
      dispatch( getTaskByIdThunk( id ));
      dispatch( actionsActivity.getActivitiesByTask( id ));
    }
  }, []);

  useEffect(() => {
    handleGetData();
  }, [formData]);

  const handleGetData = async (): Promise<void> => {
    if ( formData && formData.unitId !== '' ) { dispatch( actionsActivity.getActivitiesByBookUnit( formData.unitId )); }
    if ( formData && formData.activitiesBook.length > 0 ) {
      const actList = await getActivitiesById( formData.activitiesBook );
      setActBookList( actList );
    } else {
      setActBookList([]);
    }
  };

  const handlerSubmit = ( values: any ): void => {
    if ( !auth.currentUser ) return;
    const { uid } = auth.currentUser;
    const task = { ...values } as ITask;
    task.teacherId = uid;
    task.dateStart = stringToTimeStamp( values.dateStart );
    task.dateEnd = stringToTimeStamp( values.dateEnd );
    dispatch( updateTaskThunk( task ));
    history.goBack();
  };

  const handleOpenDialog = (): void => {
    setOpenDialog( !openDialog );
  };

  const handleOpenAddActivities = (): void => {
    setOpenAddActivity( !openAddActivity );
    if ( openAddActivity === true ) {
      setClosedTransfer( true );
    }
  };

  const handleNewActivity = (): void => {
    history.push( '/docente/actividades/agregar' );
  };

  const handleEdit = ( idAct: string ): void => {
    history.push( `/docente/actividades/editar/${idAct}` );
  };

  const handleOpenConfirm = ( idAct: string ): void => {
    setIdActDoc( idAct );
    setOpenConfirm( true );
  };

  const handleDelete = ( confirm: boolean ): void => {
    if ( confirm ) {
      dispatch( actionsActivity.deleteActivity( idActDoc ));
      dispatch( actionsActivity.getActivitiesByTask( id ));
    }
    setOpenConfirm( false );
  };

  const handleSaveData = (
    activitiesId: string[], lastIds?: string[],
  ): void => {
    const idTask = formData.id;
    dispatch( updateTaskActivitiesBooksThunk( idTask, activitiesId ));
    // dispatch( actionsActivity.getActivities());
    if ( lastIds ) {
      dispatch( actionsActivity.addTaskIdThunk( lastIds, idTask, false ));
      return;
    }
    if ( activitiesId.length > 0 ) {
      dispatch( actionsActivity.addTaskIdThunk( activitiesId, idTask, true ));
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <ContainerBreadcrumbs>
              <Breadcrumbs
                itemsLinks={[
                  {
                    name: subject.name,
                    url: `docente/tareas/detalle/${subject.id}`,
                  }]}
                itemsText={['Tareas', 'Editar la tarea']}
              />
            </ContainerBreadcrumbs>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            { !loading
              ? <TaskForm onSubmit={handlerSubmit} initialValues={formData} />
              : (
                <Spinner />
              )}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.titleActivitiesArea}>Actividades</span>
          <CenterInLine>
            <Paper
              className={classes.containerAdd}
              elevation={5}
              onClick={handleOpenDialog}
            >
              <div className={classes.iconAddAct}>
                <Image src={Add} alt="newHomework" />
              </div>
              <span>Nueva actividad</span>
            </Paper>
          </CenterInLine>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <LabelInfo label="Actividades propias" text="" />
            {
              activities.map(( item: IActivity, i: number ) => (
                <div key={i.toString()}>
                  <ItemActivityList
                    title={item.name}
                    subtitle={item.description}
                    handleEdit={() => handleEdit( item.id || '' )}
                    handleDelete={() => handleOpenConfirm( item.id || '' )}
                    showEdit
                  />
                </div>
              ))
            }
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <div>
              <LabelInfo label="Actividades de libros" text="" />
              {
                actBookList.map(( item: IActivity, i: number ) => (
                  <div key={i.toString()}>
                    <ItemActivityList
                      title={item.name}
                      subtitle={item.description}
                      handleEdit={handleOpenAddActivities}
                      handleDelete={handleOpenAddActivities}
                    />
                  </div>
                ))
              }
            </div>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        title="Selecciona el tipo de actividad"
        open={openDialog}
        size="sm"
        onClose={handleOpenDialog}
      >
        <div className={classes.row}>
          <ItemDialog
            title="Actividades prediseñadas"
            image={BookActivity}
            subtitle="Acorde al libro y a la unidad seleccionada."
            onClick={handleOpenAddActivities}
          />
          <ItemDialog
            title="Actividades propias"
            image={OwnActivity}
            subtitle="Crea tus propias actividades segun tus necesidades."
            onClick={handleNewActivity}
          />
        </div>
      </Dialog>

      <Dialog
        open={openAddActivity}
        title="Agregar una actividad"
        onClose={handleOpenAddActivities}
        size="md"
      >
        <TransferListActivities
          bookId={bookId}
          unitId={unitId}
          itemsRIGHT={formData?.activitiesBook || []}
          itemsLEFT={activitiesBook || []}
          handleSaveData={handleSaveData}
          handleDeleteData={handleSaveData}
          loading={loading}
        />
      </Dialog>

      <DialogConfirm
        open={openConfirm}
        title="ELIMINAR ACTIVIDAD"
        description="Esta apunto de eliminar
        una actividad este proceso es
        irreversible. ¿Esta seguro que desea continuar?"
        onClose={handleDelete}
        size="xs"
        textOk="Si, eliminar"
        textCancel="Regresar"
      />
    </>
  );
};

export default TasksEdit;
