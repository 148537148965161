import firebase from 'firebase';
import * as firebaseService from './firebase';

const services = {
  ...firebaseService,
};

export interface IServices {
  db : firebase.firestore.Firestore
  auth : firebase.auth.Auth
  storage : firebase.storage.Storage
  timeStamp: firebase.firestore.Timestamp
}

export default services;
