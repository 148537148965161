import React from 'react';
import DialogMaterial from '@material-ui/core/Dialog';
import {
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  body: {
    padding: 10,
  },
  dialog: {
    minWidth: '80vw',
    maxWidth: '100vh',
  },
}));

type TypeSizeDialog = 'xs' | 'sm' | 'md';
type TypeScrollDialog = 'paper' | 'body' | undefined;

interface IDialogProps {
  onClose: any;
  // eslint-disable-next-line react/require-default-props
  open?: boolean;
  title: string;
  // eslint-disable-next-line react/require-default-props
  children?: any | any[];
  // eslint-disable-next-line react/require-default-props
  size?: TypeSizeDialog;
  // eslint-disable-next-line react/require-default-props
  onConfirm?: any | undefined;
  // eslint-disable-next-line react/require-default-props
  scroll?: TypeScrollDialog;
}

const Dialog : React.FC<IDialogProps> = ( props ) => {
  const classes = useStyles();
  const {
    onClose, open = false, title,
    children, size = 'sm', onConfirm,
    scroll = undefined,
  } = props;

  const handleClose = (): void => {
    onClose( !open );
  };

  return (
    <DialogMaterial
      onClose={() => handleClose()}
      aria-labelledby="simple-dialog-title"
      open={open}
      keepMounted
      fullWidth
      maxWidth={size}
      scroll={scroll}
    >
      <div className={classes.body}>
        <DialogTitle id="simple-dialog-title">
          { title }
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
      </div>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Cerrar
        </Button>
        {onConfirm && (
          <Button onClick={onConfirm} color="primary" autoFocus>
            Aceptar
          </Button>
        )}
      </DialogActions>
    </DialogMaterial>
  );
};

export default Dialog;
