import React from 'react';
import {
  Toolbar,
  AppBar,
  Typography,
  Paper,
  makeStyles,
  Theme,
} from '@material-ui/core';
import * as IconM from '@material-ui/icons/';

const useStyles = makeStyles(( theme: Theme ) => ({
  title: {
    fontSize: 14,
    padding: '0px 0px',
    color: '#fff',
    fontWeight: 600,
    cursor: 'pointer',
  },
  navbar: {
    minHeight: 60,
    backgroundColor: theme.palette.primary.main,
    display: 'grid',
    columnGap: 10,
    gridAutoRows: 'auto',
    gridTemplateColumns: 'repeat( 2, minmax( 50%, 1fr ))',
  },
  optionsLeft: {
    display: 'grid',
    columnGap: 10,
    gridTemplateColumns: 'repeat( auto-fill , minmax( min(100%, 12rem), 1fr))',
  },
  optionsRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gridColumn: 2,
    marginRight: '5%',
  },
  containerIcon: {
    width: 25,
    height: 25,
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: '80%',
    height: '80%',
  },
}));

const NavbarLogin = (): JSX.Element => {
  const classes = useStyles();

  return (
    <AppBar position="static">
      <Toolbar className={classes.navbar}>
        <div className={classes.optionsLeft}>
          <Typography variant="h1" className={classes.title}>
            ¿ Quienes somos ?
          </Typography>
          <Typography variant="h1" className={classes.title}>
            Soporte técnico
          </Typography>
          <Typography variant="h1" className={classes.title}>
            Contáctanos
          </Typography>
        </div>
        <div className={classes.optionsRight}>
          <Paper className={classes.containerIcon}>
            <IconM.Instagram className={classes.icon} />
          </Paper>
          <Paper className={classes.containerIcon}>
            <IconM.Facebook className={classes.icon} />
          </Paper>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavbarLogin;
