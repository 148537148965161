import React from 'react';
import { makeStyles, TextField, Theme } from '@material-ui/core';
import { WrappedFieldProps } from 'redux-form';
import ErrorInput from '../ErrorInput/ErrorInput';

const useStyles = makeStyles(( theme: Theme ) => ({
  input: {
    width: '100%',
    // height: 30,
    marginBottom: 10,
    // borderRadius: 5,
    // border: '2px solid #ddd',
    // padding: '10px 15px',
  },
  block: {
    display: 'block',
    marginBottom: 10,
    color: theme.palette.error.main,
  },
}));

interface IInputProps {
  // eslint-disable-next-line react/require-default-props
  placeholder?: string
  // eslint-disable-next-line react/require-default-props
  label?: string,
  // eslint-disable-next-line react/require-default-props
  type?: string,
  // eslint-disable-next-line react/require-default-props
  multiline?: boolean;
}

const Input: React.FC<WrappedFieldProps & IInputProps> = ( props ) => {
  const classes = useStyles();
  const {
    placeholder,
    type = 'text',
    input,
    meta,
    label,
    multiline,
  } = props;
  const {
    touched, error = '', warning, asyncValidating,
  } = meta;

  return (
    <div className={classes.input}>
      <TextField
        {...input}
        label={label}
        placeholder={placeholder}
        type={type}
        variant="outlined"
        size="small"
        multiline={multiline || false}
        error={error.length > 0 && touched}
        fullWidth
        margin="none"
      />
      <div className={asyncValidating ? 'async-validating' : ''}>
        {touched
        && <ErrorInput error={error} warning={warning} />}
      </div>
    </div>
  );
};

export default Input;
