import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { RootState } from '../../../redux/store';
import Image from '../../../components/Image/Image';
import { getInstituteThunk } from '../../../redux/ducks/InstituteDucks';
import CenterInLine from '../../../components/Center/CenterInLine';
import Card from '../../../components/Card/Card';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 40,
    fontWeight: 600,
  },
}));

const Institution: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formData } = useSelector(( state: RootState ) => state.institute );
  const { photo, name } = formData;

  useEffect(() => {
    dispatch( getInstituteThunk());
  }, []);

  return (
    <Grid container justify="center" spacing={2} className="container-init">
      <Grid item xs={12}>
        <Card>
          <CenterInLine>
            <Typography className={classes.title}>
              {name}
            </Typography>
          </CenterInLine>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <div style={{ maxWidth: 300, margin: 'auto' }}>
            <Image src={photo} />
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Institution;
