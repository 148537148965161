import React, { Fragment } from 'react';
import { GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import * as IconM from '@material-ui/icons/';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { IStudent } from '../../interfaces/IStudent';
import ContainerTable from '../Table/ContainerTable/ContainerTable';
import Table from '../Table/Table';
import CenterInLine from '../Center/CenterInLine';
import ToolTip from '../ToolTip/ToolTip';
import CourseName from './components/CourseName/CourseName';

interface ITableProps{
  students: IStudent[];
  handleEdit: any;
  handleDelete: any;
  handleNew: any;
  handleUpload: any;
  handleShow: any;
}

const TableStudent: React.FC<ITableProps> = ( props ) => {
  const {
    students, handleDelete, handleEdit, handleNew, handleUpload, handleShow,
  } = props;
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 300,
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Nombre',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'surname',
      headerName: 'Apellido',
      type: 'string',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'string',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'identification',
      headerName: 'Identificación',
      type: 'string',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'status',
      headerName: 'Estado',
      editable: false,
      minWidth: 150,
      valueFormatter: (
        { value },
      ) => ( value ? 'Activo' : 'Inactivo' ),
    },
    {
      field: 'courseId',
      headerName: 'Curso',
      editable: false,
      minWidth: 200,
      renderCell: (
        { value },
      ) => ( <CourseName courseId={value} /> ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 300,
      renderCell: ({ id }: GridValueGetterParams ) => (
        <CenterInLine isRow>
          <ToolTip title="Mostrar detalle de estudiante">
            <ButtonIcon handleClick={() => handleShow( id )}>
              <IconM.Visibility />
            </ButtonIcon>
          </ToolTip>
          <ToolTip title="Editar estudiante">
            <ButtonIcon handleClick={() => handleEdit( id )}>
              <IconM.Edit />
            </ButtonIcon>
          </ToolTip>
          <ToolTip title="Eliminar estudiante">
            <ButtonIcon handleClick={() => handleDelete( id )}>
              <IconM.Delete />
            </ButtonIcon>
          </ToolTip>
        </CenterInLine>
      ),
    },
  ];

  return (
    <>
      <CenterInLine isRow isWrap justify="flex-start">
        <ToolTip title="Agregar estudiante" placement="top">
          <ButtonIcon handleClick={handleNew}>
            <IconM.Add />
          </ButtonIcon>
        </ToolTip>
        <span>Agregar nuevo estudiante</span>
        <ToolTip title="Cargar datos" placement="top">
          <ButtonIcon handleClick={handleUpload}>
            <IconM.CloudUpload />
          </ButtonIcon>
        </ToolTip>
        <span>Cargar datos</span>
      </CenterInLine>
      <ContainerTable>
        <Table
          columns={columns}
          data={students}
        />
      </ContainerTable>
    </>
  );
};

export default TableStudent;
