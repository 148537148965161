import Institution from '../views/Admin/Institution/Institution';
import BookList from '../views/Admin/Book/BookList';
import BookCreate from '../views/Admin/Book/BookCreate';
import BookEdit from '../views/Admin/Book/BookEdit';
import BookView from '../views/Admin/Book/BookView';
import StudentList from '../views/Admin/Student/StudentList';
import StudentCreate from '../views/Admin/Student/StudentCreate';
import StudentUploadBatch from '../views/Admin/Student/StudentUploadBatch';
import StudentEdit from '../views/Admin/Student/StudentEdit';
import StudentView from '../views/Admin/Student/StudentView';
import UnitList from '../views/Admin/Unit/UnitList';
import UnitCreate from '../views/Admin/Unit/UnitCreate';
import UnitEdit from '../views/Admin/Unit/UnitEdit';
import UnitView from '../views/Admin/Unit/UnitView';
import TeacherList from '../views/Admin/Teacher/TeacherList';
import TeacherCreate from '../views/Admin/Teacher/TeacherCreate';
import TeacherEdit from '../views/Admin/Teacher/TeacherEdit';
import TeacherUploadBatch from '../views/Admin/Teacher/TeacherUploadBatch';
import CourseList from '../views/Admin/Course/CourseList';
import CourseView from '../views/Admin/Course/CourseView';
import CourseEdit from '../views/Admin/Course/CourseEdit';
import SubjectList from '../views/Admin/Subject/SubjectList';
import SubjectCreate from '../views/Admin/Subject/SubjectCreate';
import SubjectEdit from '../views/Admin/Subject/SubjectEdit';
import SubjectView from '../views/Admin/Subject/SubjectView';
import ActivityList from '../views/Teacher/Activity/ActivityList';
import ActivityCreate from '../views/Teacher/Activity/ActivityCreate';
import ActivityEdit from '../views/Teacher/Activity/ActivityEdit';
import ActivityView from '../views/Teacher/Activity/ActivityView';
import TasksList from '../views/Teacher/Tasks/TasksList';
import TasksCreate from '../views/Teacher/Tasks/TasksCreate';
import TasksEdit from '../views/Teacher/Tasks/TaskEdit/TasksEdit';
import TasksView from '../views/Teacher/Tasks/TasksView';
// eslint-disable-next-line max-len
import StudentBookView from '../views/Student/Book/StudentBookView/StudentBookView';
// eslint-disable-next-line max-len
import StudentSubjectView from '../views/Student/Subject/StudentSubjectView/StudentSubjectView';
// eslint-disable-next-line max-len
import StudentSubjectDetail from '../views/Student/Subject/StudentSubjectDetail/StudentSubjectDetail';
import Homework from '../views/Student/Tasks/Homework';
import StudentCalendar from '../views/Student/Calendar/StudentCalendar';
// eslint-disable-next-line max-len
import StudentActivity from '../views/Student/Book/StudentActivity/StudentActivity';
// eslint-disable-next-line max-len
import AllMySubjects from '../views/Student/Subject/AllMySubjects/AllMySubjects';
import TeacherView from '../views/Admin/Teacher/TeacherView';
import GradeDetail from '../views/Teacher/Grades/GradeDetail/GradeDetail';
// eslint-disable-next-line max-len
import TeacherSubjectView from '../views/Teacher/Subject/TeacherSubjectView/TeacherSubjectView';
// eslint-disable-next-line max-len
import TeacherSubjectDetail from '../views/Teacher/Subject/TeacherSubjectDetail/TeacherSubjectDetail';
import ActivityBookList from '../views/Admin/ActivityBook/ActivityBookList';
import ActivityBookCreate from '../views/Admin/ActivityBook/ActivityBookCreate';
import ActivityBookEdit from '../views/Admin/ActivityBook/ActivityBookEdit';
import ActivityBookView from '../views/Admin/ActivityBook/ActivityBookView';
// eslint-disable-next-line max-len
import AllMyGrades from '../views/Student/Grades/AllMyGrades/AllMyGrades';
import Homeworks from '../views/Teacher/Homeworks/Homeworks/Homeworks';
// eslint-disable-next-line max-len
import TeacherAllMyGrades from '../views/Teacher/Grades/TeacherAllMyGrades/TeacherAllMyGrades';
// eslint-disable-next-line max-len
import TeacherSubjectDay from '../views/Teacher/Subject/TeacherSubjectDay/TeacherSubjectDay';
import Rate from '../views/Teacher/Homeworks/Rate/Rate';
import HomeworkQualify from '../views/Teacher/Homeworks/Qualify/Homework';
// eslint-disable-next-line max-len
import TeacherGradesBySubject from '../views/Teacher/Grades/TeacherGradesBySubject/TeacherGradesBySubject';
import NotesScheme from '../views/Teacher/Grades/NotesScheme/NotesScheme';

export interface IRoute {
  type: number[];
  path: string;
  private: boolean;
  component: any | any[];
  name: string;
}
export const routesList: IRoute[] = [
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/inicio',
    private: true,
    component: Institution,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/libros',
    private: true,
    component: BookList,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/libros/agregar',
    private: true,
    component: BookCreate,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/libros/editar/:id',
    private: true,
    component: BookEdit,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/libros/ver/:id',
    private: true,
    component: BookView,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/estudiantes',
    private: true,
    component: StudentList,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/estudiantes/agregar',
    private: true,
    component: StudentCreate,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/estudiantes/carga-masiva',
    private: true,
    component: StudentUploadBatch,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/estudiantes/editar/:id',
    private: true,
    component: StudentEdit,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/estudiantes/ver/:id',
    private: true,
    component: StudentView,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/unidades',
    private: true,
    component: UnitList,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/unidades/agregar',
    private: true,
    component: UnitCreate,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/unidades/editar/:id',
    private: true,
    component: UnitEdit,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/unidades/ver/:id',
    private: true,
    component: UnitView,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/docentes',
    private: true,
    component: TeacherList,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/docentes/agregar',
    private: true,
    component: TeacherCreate,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/docentes/editar/:id',
    private: true,
    component: TeacherEdit,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/docentes/carga-masiva',
    private: true,
    component: TeacherUploadBatch,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/docentes/ver/:id',
    private: true,
    component: TeacherView,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/cursos',
    private: true,
    component: CourseList,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/cursos/ver/:id',
    private: true,
    component: CourseView,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/cursos/editar/:id',
    private: true,
    component: CourseEdit,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/materias',
    private: true,
    component: SubjectList,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/materias/agregar',
    private: true,
    component: SubjectCreate,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/materias/editar/:id',
    private: true,
    component: SubjectEdit,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/materias/ver/:id',
    private: true,
    component: SubjectView,
  },
  {
    type: [1],
    path: '/admin/libros/ver/:unitId/actividad/:topicId',
    private: true,
    component: StudentActivity,
    name: 'Tema',
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/actividades',
    private: true,
    component: ActivityBookList,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/actividades/agregar',
    private: true,
    component: ActivityBookCreate,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/actividades/editar/:id',
    private: true,
    component: ActivityBookEdit,
  },
  {
    name: 'Inicio',
    type: [1],
    path: '/admin/actividades/ver/:id',
    private: true,
    component: ActivityBookView,
  },
  {
    type: [2],
    path: '/docente/libros/ver/:unitId/actividad/:topicId',
    private: true,
    component: StudentActivity,
    name: 'Tema',
  },
  {
    type: [2],
    name: 'Inicio',
    path: '/docente/inicio',
    private: true,
    component: TeacherSubjectView,
  },
  {
    name: 'Inicio',
    type: [2],
    path: '/docente/actividades',
    private: true,
    component: ActivityList,
  },
  {
    name: 'Inicio',
    type: [2],
    path: '/docente/actividades/agregar',
    private: true,
    component: ActivityCreate,
  },
  {
    name: 'Inicio',
    type: [2],
    path: '/docente/actividades/editar/:id',
    private: true,
    component: ActivityEdit,
  },
  {
    name: 'Inicio',
    type: [2],
    path: '/docente/actividades/ver/:id',
    private: true,
    component: ActivityView,
  },
  {
    name: 'Inicio',
    type: [2],
    path: '/docente/tareas',
    private: true,
    component: TasksList,
  },
  {
    name: 'Agregar tarea',
    type: [2],
    path: '/docente/tareas/agregar',
    private: true,
    component: TasksCreate,
  },
  {
    name: 'Inicio',
    type: [2],
    path: '/docente/tareas/editar/:id',
    private: true,
    component: TasksEdit,
  },
  {
    name: 'Inicio',
    type: [2],
    path: '/docente/tareas/ver/:id',
    private: true,
    component: TasksView,
  },
  {
    name: 'Inicio',
    type: [3],
    path: '/estudiante/libros',
    private: true,
    component: StudentBookView,
  },
  {
    name: 'Inicio',
    type: [3],
    path: '/estudiante/inicio',
    private: true,
    component: StudentSubjectView,
  },
  {
    name: 'Inicio',
    type: [3],
    path: '/estudiante/materias/detalle/:id',
    private: true,
    component: StudentSubjectDetail,
  },
  {
    name: 'Inicio',
    type: [3],
    path: '/estudiante/materias/tareas/:id',
    private: true,
    component: Homework,
  },
  {
    type: [3],
    name: 'Calendario',
    path: '/estudiante/calendario',
    private: true,
    component: StudentCalendar,
  },
  {
    type: [3],
    name: 'Mis Materias',
    path: '/estudiante/mismaterias',
    private: true,
    component: AllMySubjects,
  },
  {
    name: 'Inicio',
    type: [2],
    path: '/docente/libros',
    private: true,
    component: StudentBookView,
  },
  {
    name: 'Inicio',
    type: [2],
    path: '/docente/libros/ver/:id',
    private: true,
    component: BookView,
  },
  {
    name: 'Inicio',
    type: [2],
    path: '/docente/materias/detalle/:id',
    private: true,
    component: TeacherSubjectDetail,
  },
  {
    name: 'Calificaciones',
    type: [2],
    path: '/docente/calificaciones',
    private: true,
    component: TeacherAllMyGrades,
  },
  {
    name: 'Calificaciones',
    type: [2],
    path: '/docente/tareas/detalle/:id',
    private: true,
    component: Homeworks,
  },
  {
    name: 'Calificaciones',
    type: [2],
    path: '/docente/calificaciones/detalle/:subjectId/:courseId',
    private: true,
    component: TeacherGradesBySubject,
  },
  {
    name: 'Calificaciones',
    type: [2],
    path: '/docente/calificaciones/parametro/:id',
    private: true,
    component: NotesScheme,
  },
  {
    name: 'Materias',
    type: [2],
    path: '/docente/materias/clases',
    private: true,
    component: TeacherSubjectDay,
  },
  {
    name: 'Calificaciones',
    type: [2],
    path: '/docente/tareas/calificaciones/:id',
    private: true,
    component: Rate,
  },
  {
    name: 'Calificaciones',
    type: [2],
    path: '/docente/calificaciones/finales/:idSubject/:idStudent',
    private: true,
    component: GradeDetail,
  },
  {
    name: 'Calificar tarea de un estudiante',
    type: [2],
    path: '/docente/tareas/calificaciones/estudiante/:studentId',
    private: true,
    component: HomeworkQualify,
  },
  {
    name: 'Inicio',
    type: [3],
    path: '/estudiante/libros/ver/:id',
    private: true,
    component: BookView,
  },
  {
    type: [3],
    path: '/estudiante/libros/ver/:unitId/actividad/:topicId',
    private: true,
    component: StudentActivity,
    name: 'Tema',
  },
  {
    name: 'Calificaciones',
    type: [3],
    path: '/estudiante/calificaciones',
    private: true,
    component: AllMyGrades,
  },
  {
    name: 'Calificaciones',
    type: [3],
    path: '/estudiante/calificaciones/detalle/:idSubject/:idStudent/:idTeacher',
    private: true,
    component: GradeDetail,
  },
];
