import React, { useEffect, useState } from 'react';

// Material Ui
import {
  Box,
} from '@material-ui/core';

// Icons
import alertIconRed from '../../assets/svg/alertIconRed.svg';
import alertIconYellow from '../../assets/svg/alertIconYellow.svg';
import alertIconGreen from '../../assets/svg/alertIconGreen.svg';
import { ITask } from '../../interfaces/ITask';
import { IStudent } from '../../interfaces/IStudent';
import ToolTip from '../ToolTip/ToolTip';

interface ISubjectTaskStatusProps {
  task: ITask | any;
  students: IStudent[];
  // eslint-disable-next-line react/require-default-props
  iconClasses?: any;
  // eslint-disable-next-line react/require-default-props
  iconTextClasses?: any;
}

const SubjectTaskStatus: React.FC<ISubjectTaskStatusProps> = ( props ): any => {
  const {
    task,
    students = [],
    iconClasses,
    iconTextClasses,
  } = props;

  const [completed, setCompleted] = useState( 0 );
  const [uncompleted, setUncompleted] = useState( 0 );
  const [lost, setLost] = useState( 0 );

  const filteringData = (): void => {
    let completedInTime = [];
    let noCompletedInTime = [];
    if ( task.notes.length > 0 ) {
      completedInTime = task.notes.filter(( item: any ) => new Date( item.fecha.toDate()) <= new Date( task.dateEnd.toDate()));
      noCompletedInTime = task.notes.filter(( item: any ) => new Date( item.fecha.toDate()) > new Date( task.dateEnd.toDate()));
    }
    const uncompletedArray = students.filter(
      ( item ) => !task.students?.includes( item.id ),
    );
    setCompleted( completedInTime.length );
    setUncompleted( uncompletedArray.length );
    setLost( noCompletedInTime.length );
  };

  useEffect(() => {
    filteringData();
  }, [props]);

  return (
    <>
      <ToolTip title="Completados con retraso">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gridRowGap={10}
        >
          <img className={iconClasses} src={alertIconRed} alt="" />
          <p className={iconTextClasses}>{lost}</p>
        </Box>
      </ToolTip>
      <ToolTip title="No completados">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gridRowGap={10}
        >
          <>
            <img className={iconClasses} src={alertIconYellow} alt="" />
            <p className={iconTextClasses}>{uncompleted}</p>
          </>
        </Box>
      </ToolTip>
      <ToolTip title="Completados">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gridRowGap={10}
        >
          <img className={iconClasses} src={alertIconGreen} alt="" />
          <p className={iconTextClasses}>{completed}</p>
        </Box>
      </ToolTip>
    </>
  );
};

export default SubjectTaskStatus;
