import React, { useState } from 'react';
import * as IconM from '@material-ui/icons/';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';
import { actions } from '../../redux/ducks/UnitDucks';
import CenterInLine from '../Center/CenterInLine';
import ToolTip from '../ToolTip/ToolTip';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import TopicForm from '../TopicForm/TopicForm';
import Dialog from '../Dialog/Dialog';
import TopicEditAndDelete from './TopicEditAndDelete';

interface ITopicProps {
  // eslint-disable-next-line react/require-default-props
  unitId?: any;
}

const Topic : React.FC<ITopicProps> = ( props ) => {
  const {
    unitId,
  } = props;

  const dispatch = useDispatch();

  const [openDialogCreateTopic, setOpenDialogCreateTopic] = useState( false );

  const handleCreateTopicDialog = ( id: string ): void => {
    dispatch( actions.getUnit( id ));
    setOpenDialogCreateTopic( true );
  };

  const handleSubmitAdd = ( values: any ): void => {
    dispatch( actions.createTopicForUnit( values, unitId ));
    dispatch( reset( 'topic' ));
    setOpenDialogCreateTopic( false );
  };

  const handleClose = ( ): void => {
    setOpenDialogCreateTopic( false );
  };

  return (
    <>
      <CenterInLine isRow>
        <ToolTip title="Crear tema">
          <ButtonIcon handleClick={() => handleCreateTopicDialog( unitId )}>
            <IconM.Add />
          </ButtonIcon>
        </ToolTip>
        <TopicEditAndDelete unitId={unitId} />
      </CenterInLine>
      <Dialog
        open={openDialogCreateTopic}
        title="Agregar tema"
        onClose={handleClose}
      >
        {openDialogCreateTopic && (
          <TopicForm onSubmit={handleSubmitAdd} />
        )}
      </Dialog>
    </>
  );
};

export default Topic;
