import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  marginLeft: {
    marginLeft: '2em',
  },
  noMargin: {
    margin: '0!important',
  },
  card: {
    minHeight: '100%',
    maxHeight: '6em',
  },
  cardContent: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 20,
  },
  cardContentInfo: {
    padding: '0.5em 1em 0.5em 1em!important',
  },
  svgAdd: {
    width: '2.5em',
  },
  fabEdit: {
    padding: '0!important',
    marginRight: '1em!important',
    maxHeight: '2.5em',
    maxWidth: '2.5em',
    minHeight: '2.5em',
    minWidth: '2.5em',
  },
  svgEdit: {
    maxWidth: '1.5em',
  },
  svgDelete: {
    maxWidth: '1em',
  },
  sendContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconSend: {
    color: '#ffffff',
  },
}));
