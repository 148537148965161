import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { toast } from 'react-toastify';
import { IUnit } from '../../interfaces/IUnit';
import { RootState } from '../store';
import { db } from '../../services/firebase';
import { IBook } from '../../interfaces/IBook';

const COLLECTION_UNITY = 'units';

// action types
export const actionType = {
  GET_UNIT_REQUEST: 'GET_UNIT_REQUEST',
  GET_UNITS_FROM_DB: 'GET_UNITS_FROM_DB',
  SET_CREATE_UNIT_SUCCESS: 'SET_CREATE_UNIT_SUCCESS',
  SET_UNIT_TO_EDIT_SUCCESS: 'SET_UNIT_TO_EDIT_SUCCESS',
  SET_DELETE_UNIT_SUCCESS: 'SET_DELETE_UNIT_SUCCESS',
  SET_UPDATE_UNIT_SUCCESS: 'SET_UPDATE_UNIT_SUCCESS',
  SET_LOADING_UNIT: 'SET_LOADING_UNIT',
  GET_TOPICS_FOR_UNIT_ID_FROM_DB: 'GET_TOPICS_FOR_UNIT_ID_FROM_DB',
  GET_TOPIC_BY_ID_FROM_DB: 'GET_TOPIC_BY_ID_FROM_DB',
};

// Interfaces
export interface UnitState {
  units: IUnit[] | null;
  unitById: null;
}

export interface setGetUnitsSuccess {
  type: typeof actionType.GET_UNITS_FROM_DB;
  payload: IUnit[];
}

export interface setCreateUnitSuccess {
  type: typeof actionType.SET_CREATE_UNIT_SUCCESS;
}

export interface setDeleteUnitSuccess {
  type: typeof actionType.SET_DELETE_UNIT_SUCCESS;
}

export interface setUnitToEditSuccess {
  type: typeof actionType.SET_UNIT_TO_EDIT_SUCCESS;
  payload: IUnit;
}

export interface setUnitUpdateSuccess {
  type: typeof actionType.SET_UPDATE_UNIT_SUCCESS;
}

export interface setLoadingUnit {
  type: typeof actionType.SET_LOADING_UNIT;
  payload: boolean;
}

export interface setGetTopicsForUnitIdSuccess {
  type: typeof actionType.GET_TOPICS_FOR_UNIT_ID_FROM_DB;
  payload: any;
}

export interface setGetTopicByIdSuccess {
  type: typeof actionType.GET_TOPIC_BY_ID_FROM_DB;
  payload: any;
}

// Thunk Types
type UnitActions =
  | setGetUnitsSuccess
  | setCreateUnitSuccess
  | setDeleteUnitSuccess
  | setUnitToEditSuccess
  | setLoadingUnit
  | setGetTopicsForUnitIdSuccess
  | setGetTopicByIdSuccess;

// initial state
const initialState = {
  units: [] as any,
  unitById: null,
  topicsByUnitId: [],
  topicById: null,
  loading: false,
};

// reducer
export default function unitReducer(
  state = initialState, action: AnyAction,
) : any {
  const { type, payload } = action;

  switch ( type ) {
    case actionType.GET_UNITS_FROM_DB:
      return {
        ...state,
        units: payload,
      };
    case actionType.SET_UNIT_TO_EDIT_SUCCESS:
      return {
        ...state,
        unitById: payload,
        loading: false,
      };
    case actionType.GET_UNIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SET_LOADING_UNIT:
      return {
        ...state,
        loading: payload,
      };
    case actionType.GET_TOPICS_FOR_UNIT_ID_FROM_DB:
      return {
        ...state,
        topicsByUnitId: payload,
      };
    case actionType.GET_TOPIC_BY_ID_FROM_DB:
      return {
        ...state,
        topicById: payload,
      };
    case actionType.SET_CREATE_UNIT_SUCCESS:
    case actionType.SET_DELETE_UNIT_SUCCESS:
      return state;
    default:
      return state;
  }
}

// actions creators
export const actions = {
  getUnits:
    (): ThunkAction<
    void, RootState, null, UnitActions> => async ( dispatch ) => {
      try {
        db.collection( COLLECTION_UNITY )
          .where( 'deleted', '==', false )
          .orderBy( 'name' )
          .orderBy( 'book.name' )
          .onSnapshot(( querySnapshot ) => {
            const units: IUnit[] = [];
            querySnapshot.forEach(( unit ) => {
              const doc = unit.data() as IUnit;
              doc.id = unit.id;
              doc.bookName = doc.book.name;
              units.push( doc );
            });
            dispatch({
              type: actionType.GET_UNITS_FROM_DB,
              payload: units,
            });
          });
      } catch ( err ) {
        // console.error( err );
      }
    },
  getUnit:
    ( id: string ): ThunkAction<
    void, RootState, null, UnitActions> => async ( dispatch ) => {
      try {
        const dataUnit = await db.collection( COLLECTION_UNITY )
          .doc( id )
          .get();
        const dataTopics = await db.collection( COLLECTION_UNITY )
          .doc( id )
          .collection( 'topics' )
          .get();
        const topics: any = [];
        dataTopics.forEach(( topic ) => (
          topics.push({ ...topic.data(), id: topic.id })
        ));
        if ( dataUnit.exists ) {
          const unitSelected = { ...dataUnit.data(), id, topics };
          dispatch({
            type: actionType.SET_UNIT_TO_EDIT_SUCCESS,
            payload: unitSelected,
          });
        }
      } catch ( err ) {
        // console.error( err );
      }
    },
  createUnit:
    ( data: IUnit, book: IBook ): ThunkAction<
    void, RootState, null, UnitActions> => async ( dispatch ) => {
      const dataToSave = {
        ...data,
        deleted: false,
      };
      dataToSave.book = {
        id: book.id,
        name: book.name,
      };
      try {
        await db.collection( COLLECTION_UNITY ).add( dataToSave );
        dispatch({
          type: actionType.SET_CREATE_UNIT_SUCCESS,
        });
        toast.success( 'Nueva unidad agregada.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  updateUnit:
    ( id: string, data: IUnit, book: IBook ): ThunkAction<
    void, RootState, null, UnitActions> => async ( dispatch ) => {
      const dataToSave = {
        ...data,
        deleted: false,
      };
      dataToSave.book = {
        id: book.id,
        name: book.name,
      };
      try {
        await db.collection( COLLECTION_UNITY ).doc( id ).update( dataToSave );
        dispatch({
          type: actionType.SET_UPDATE_UNIT_SUCCESS,
        });
        toast.success( 'Unidad editada correctamente.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  deleteUnit:
    ( id: string ): ThunkAction<
    void, RootState, null, UnitActions> => async ( dispatch ) => {
      try {
        await db
          .collection( COLLECTION_UNITY )
          .doc( id )
          .update({ deleted: true })
          .then(() => {
            dispatch({
              type: actionType.SET_DELETE_UNIT_SUCCESS,
            });
          });
        toast.success( 'Unidad eliminada correctamente.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  setLoadingUnits:
    ( state: boolean ): ThunkAction<
    void, RootState, null, UnitActions> => async ( dispatch ) => {
      dispatch({
        type: actionType.SET_LOADING_UNIT,
        payload: state,
      });
    },
  getTopicsForUnit:
    ( unitId: string ): ThunkAction<
    void, RootState, null, UnitActions> => async ( dispatch ) => {
      try {
        const topics: any = [];
        const data = await db.collection( COLLECTION_UNITY )
          .doc( unitId )
          .collection( 'topics' )
          .get();
        data.forEach(( topic ) => (
          topics.push({ ...topic.data(), id: topic.id })
        ));
        dispatch({
          type: actionType.GET_TOPICS_FOR_UNIT_ID_FROM_DB,
          payload: topics,
        });
      } catch ( err ) {
        // console.error( err );
      }
    },
  getTopicById:
    ( unitId: string, topicId: string ): ThunkAction<
    void, RootState, null, UnitActions> => async ( dispatch ) => {
      try {
        const data = await db.collection( COLLECTION_UNITY )
          .doc( unitId )
          .collection( 'topics' )
          .doc( topicId )
          .get();
        dispatch({
          type: actionType.GET_TOPIC_BY_ID_FROM_DB,
          payload: { ...data.data(), id: data.id },
        });
      } catch ( err ) {
        // console.error( err );
      }
    },
  createTopicForUnit:
    ( data: IUnit, unitId: string ): ThunkAction<
    void, RootState, null, UnitActions> => async ( dispatch ) => {
      const dataToSave = {
        ...data,
        unitId,
      };
      try {
        const batch = db.batch();
        const doc = db.collection( COLLECTION_UNITY )
          .doc( unitId )
          .collection( 'topics' )
          .doc();
        batch.set( doc, dataToSave );
        await batch.commit();
        dispatch({
          type: actionType.SET_CREATE_UNIT_SUCCESS,
        });
        toast.success( 'Nuevo tema agregado.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  updateTopicForUnit:
    ( data: IUnit, unitId: string, topicId: string ): ThunkAction<
    void, RootState, null, UnitActions> => async ( dispatch ) => {
      const dataToSave = {
        ...data,
        unitId,
      };
      try {
        const batch = db.batch();
        const doc = db.collection( COLLECTION_UNITY )
          .doc( unitId )
          .collection( 'topics' )
          .doc( topicId );
        batch.update( doc, dataToSave );
        await batch.commit();
        dispatch({
          type: actionType.SET_CREATE_UNIT_SUCCESS,
        });
        toast.success( 'Tema editado correctamente.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  deleteTopicForUnit:
    ( unitId: string, topicId: string ): ThunkAction<
    void, RootState, null, UnitActions> => async ( dispatch ) => {
      try {
        const batch = db.batch();
        const doc = db.collection( COLLECTION_UNITY )
          .doc( unitId )
          .collection( 'topics' )
          .doc( topicId );
        batch.delete( doc );
        await batch.commit();
        dispatch({
          type: actionType.SET_CREATE_UNIT_SUCCESS,
        });
        toast.success( 'Tema eliminado correctamente.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  getUnitsByBookId:
      ( bookId: string ): ThunkAction<
      void, RootState, null, UnitActions> => async ( dispatch ) => {
        const subCollectionsItems: any = [];
        try {
          await db.collection( COLLECTION_UNITY )
            .where( 'deleted', '==', false )
            .where( 'bookId', '==', bookId )
            .get()
            .then(
              ( all ) => {
                all.docs.forEach( async ( item ) => {
                  const topics: any = [];
                  const data = await db.collection( COLLECTION_UNITY )
                    .doc( item.id )
                    .collection( 'topics' )
                    .where( 'unitId', '==', item.id )
                    .get();
                  data.forEach(( topic ) => (
                    topics.push({ ...topic.data(), id: topic.id })
                  ));
                  subCollectionsItems.push({ ...item.data(), topics, id: item.id });
                });
              },
            );
          dispatch({
            type: actionType.GET_UNITS_FROM_DB,
            payload: subCollectionsItems,
          });
        } catch ( err ) {
          // console.error( err );
        }
      },
};
