import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { IQuestion } from '../../../../../interfaces/IQuestion';
import useStyles from './Styles';

interface IProps {
  data: IQuestion;
  onSave: any;
}

const ItemComplete: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const { data: { question = '', ...rest }, onSave } = props;
  const [valueInput, setValueInput] = useState<string>( '' );

  const onChange = ( e: any ): void => {
    if ( e.target.value === rest.answer ) {
      onSave( true, rest.id, e.target.value );
    } else {
      onSave( false, rest.id, e.target.value );
    }
    setValueInput( e.target.value );
  };

  const handleCreateQuestion = (): JSX.Element[] => {
    const str = question.split( ' ' );
    return (
      str.map(( item: string, i: number ) => (
        !item.includes( '**' )
          ? (
            <span key={i.toString()} className={classes.sentenceItem}>
              {item}
            </span>
          )
          : (
            <div key={i.toString()} className={classes.response}>
              <TextField
                value={valueInput}
                onChange={onChange}
                variant="outlined"
                size="small"
                className={classes.input}
                multiline
                fullWidth
              />
            </div>
          )
      ))
    );
  };

  return (
    <div className={classes.root}>
      {handleCreateQuestion()}
    </div>
  );
};

export default ItemComplete;
