/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  CssBaseline, makeStyles,
} from '@material-ui/core';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';

const drawerWidth = 110;

const useStyles = makeStyles(( theme ) => ({
  root: {
    display: 'block',
    backgroundColor: '#eee',
    '& main': {
      [theme.breakpoints.down( 'sm' )]: {
        marginLeft: '0!important',
        paddingLeft: '0!important',
        paddingRight: '0!important',
      },
    },
    '& main > div': {
      [theme.breakpoints.down( 'sm' )]: {
        width: 'auto!important',
        margin: '0 1em 0 1em!important',
      },
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing( 3 ),
    marginTop: 65,
    height: 'calc(100vh - 65px)',
    marginLeft: drawerWidth,
  },
  container: {
    backgroundColor: '#fff',
    padding: 10,
    borderRadius: 10,
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

interface ILayoutProps {
  children: JSX.Element | JSX.Element[];
}

const Layout: React.FC<ILayoutProps> = ( props ) => {
  const classes = useStyles();

  const [state, setState] = React.useState( false );

  const { children } = props;

  const onSidebarOpen = (): void => {
    setState( true );
  };

  const onSidebarClose = (): void => {
    setState( false );
  };

  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <Navbar handleOpen={onSidebarOpen} />
        <Sidebar state={state} handleClose={onSidebarClose} />
        <main className={classes.content}>
          { children }
        </main>
      </div>
    </>
  );
};

export default Layout;
