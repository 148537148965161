import React, { useState } from 'react';
import * as IconM from '@material-ui/icons/';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import { actions } from '../../redux/ducks/UnitDucks';
import ToolTip from '../ToolTip/ToolTip';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import TopicForm from '../TopicForm/TopicForm';
import Dialog from '../Dialog/Dialog';
import { RootState } from '../../redux/store';
import DialogConfirm from '../DialogConfirm/DialogConfirm';
import MessageCard from '../MessageCard/MessageCard';

interface ITopicProps {
  // eslint-disable-next-line react/require-default-props
  unitId?: any;
}

const TopicEditAndDelete : React.FC<ITopicProps> = ( props ) => {
  const {
    unitId,
  } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState( false );
  const [openDialogEditAndDeleteTopic,
    setOpenDialogEditAndDeleteTopic] = useState( false );
  const [openDialogEditTopic, setOpenDialogEditTopic] = useState( false );

  const handleEditAndDeleteTopicDialog = ( id: string ): void => {
    dispatch( actions.getTopicsForUnit( id ));
    setOpenDialogEditAndDeleteTopic( true );
  };

  const handleEditTopicDialog = ( id: string, topicId: string ): void => {
    setOpenDialogEditAndDeleteTopic( false );
    dispatch( actions.getTopicById( id, topicId ));
    setOpenDialogEditTopic( true );
  };

  const handleSubmitEdit = ( values: any ): void => {
    dispatch( actions.updateTopicForUnit( values, unitId, topicById.id ));
    dispatch( reset( 'topic' ));
    setOpenDialogEditTopic( false );
  };

  const handleClose = ( ): void => {
    setOpenDialogEditTopic( false );
    setOpenDialogEditAndDeleteTopic( false );
  };

  const handleDelete = ( confirm: boolean ): void => {
    if ( confirm ) {
      dispatch( actions.deleteTopicForUnit( unitId, topicById.id ));
    }
    setOpen( false );
  };

  const handleOpenConfirm = ( topicId: string ): void => {
    dispatch( actions.getTopicById( unitId, topicId ));
    setOpenDialogEditAndDeleteTopic( false );
    setOpen( true );
  };

  const topicsByUnitId = useSelector(
    ( state: RootState ) => state.unit.topicsByUnitId,
  );
  const topicById = useSelector(( state: RootState ) => state.unit.topicById );

  return (
    <>
      <ToolTip title="Editar tema">
        <ButtonIcon
          handleClick={() => handleEditAndDeleteTopicDialog( unitId )}
        >
          <IconM.Visibility />
        </ButtonIcon>
      </ToolTip>
      <Dialog
        open={openDialogEditAndDeleteTopic}
        title="Lista de temas"
        onClose={handleClose}
      >
        {openDialogEditAndDeleteTopic && (
          <>
            {topicsByUnitId.length > 0 ? (
              topicsByUnitId.map(( topic: any, i: number ) => (
                <div key={i.toString()}>
                  <span>{topic.name}</span>
                  <ButtonIcon
                    handleClick={
                      () => handleEditTopicDialog( unitId, topic.id )
                    }
                  >
                    <IconM.Edit />
                  </ButtonIcon>
                  <ButtonIcon handleClick={() => handleOpenConfirm( topic.id )}>
                    <IconM.Delete />
                  </ButtonIcon>
                </div>
              ))
            )
              : (
                <MessageCard>
                  <h3>No hay temas.</h3>
                </MessageCard>
              )}
          </>
        )}
      </Dialog>
      <Dialog
        open={openDialogEditTopic}
        title="Editar tema"
        onClose={handleClose}
      >
        {openDialogEditTopic && (
          <TopicForm onSubmit={handleSubmitEdit} initialValues={topicById} />
        )}
      </Dialog>
      <DialogConfirm
        open={open}
        title="¿ Desea eliminar éste tema ?"
        onClose={handleDelete}
      />
    </>
  );
};

export default TopicEditAndDelete;
