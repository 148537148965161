import esLocale from 'date-fns/locale/es';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { WrappedFieldProps } from 'redux-form';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import ErrorInput from '../ErrorInput/ErrorInput';

const useStyles = makeStyles(() => ({
  input: {
    borderRadius: 5,
    marginBottom: 10,
  },
}));

type typeSize = 'small' | 'medium';
type typeVariant = 'dialog' | 'inline' | 'static';

interface IInputDateTimeProps {
  // eslint-disable-next-line react/require-default-props
  label?: string;
  // eslint-disable-next-line react/require-default-props
  format?: string;
  // eslint-disable-next-line react/require-default-props
  variant?: typeVariant;
  // eslint-disable-next-line react/require-default-props
  disableToolbar?: boolean;
  // eslint-disable-next-line react/require-default-props
  size?: typeSize;
  // eslint-disable-next-line react/require-default-props
  value?: string;
}

const InputDateTime:
React.FC<WrappedFieldProps & IInputDateTimeProps> = ( props ) => {
  const classes = useStyles();
  const {
    format, variant, disableToolbar, meta, label,
    input: { onBlur, value, ...inputProps },
  } = props;
  const { touched, error = '', warning } = meta;

  const handleChange = ( date: any ): any => ( Date.parse( date )
    ? inputProps.onChange( date.toISOString())
    : inputProps.onChange( null ));

  return (
    <div className={classes.input}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <KeyboardDateTimePicker
          {...inputProps}
          label={label}
          value={value ? new Date( value ) : null}
          onChange={handleChange}
          onBlur={
            () => onBlur( value ? new Date( value ).toISOString() : null )
          }
          disableToolbar={disableToolbar || false}
          variant={variant || 'dialog'}
          margin="none"
          format={format || 'dd/MM/yyyy hh:mm a'}
          size="small"
          placeholder="Elija la fecha"
          inputVariant="outlined"
          error={error.length > 0 && touched}
          helperText={null} // text help
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          fullWidth
          cancelLabel="Cancelar"
          okLabel="Aceptar"
        />
      </MuiPickersUtilsProvider>
      {touched
        && <ErrorInput error={error} warning={warning} />}
    </div>
  );
};

export default InputDateTime;
