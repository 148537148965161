import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  center: ( data: any ) => ({
    display: 'flex',
    justifyContent: data.justify ? data.justify : 'center',
    alignItems: 'center',
    flexDirection: data.isRow ? 'row' : 'column',
    columnGap: 10,
    flexWrap: data.isWrap ? 'wrap' : 'nowrap',
  }),
}));

interface ICenterInLineProps {
  // eslint-disable-next-line react/require-default-props
  children?: JSX.Element | JSX.Element[] | any;
  // eslint-disable-next-line react/require-default-props
  isRow?: boolean;
  // eslint-disable-next-line react/require-default-props
  isWrap?: boolean;
  // eslint-disable-next-line react/require-default-props
  justify?: string;
}

const CenterInLine : React.FC<ICenterInLineProps> = ( props ) => {
  const {
    children,
    isRow,
    justify,
    isWrap,
  } = props;
  const classes = useStyles({ isRow, justify, isWrap });

  return (
    <div className={classes.center}>
      { children }
    </div>
  );
};

export default CenterInLine;
