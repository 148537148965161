import { db } from './firebase';
import { asyncForEach } from '../helpers/async-foreach';
import { ITask } from '../interfaces/ITask';

const COLLECTION_TASKS = 'tasks';

export const getTaskByIdSubject = async (
  subjectId: string, studentId: string,
): Promise<ITask[]> => {
  try {
    const dataRes: ITask[] = [];
    const tasks = await db.collection( COLLECTION_TASKS )
      .where( 'subjectId', '==', subjectId )
      .where( 'deleted', '==', false )
      .get();
    if ( tasks.empty ) {
      return dataRes;
    }
    const { docs } = tasks;
    await asyncForEach( docs, async ( doc: any ) => {
      const item = doc.data();
      const notes:any = [];
      const data = await db.collection( COLLECTION_TASKS )
        .doc( doc.id )
        .collection( 'notes' )
        .where( 'idStudent', '==', studentId )
        .get();
      data.forEach(( note ) => {
        notes.push({ ...note.data(), id: note.id });
      });
      dataRes.push({
        ...item, notes,
      });
    });
    return dataRes;
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    return [];
  }
};

export const getTaskFinished = ( tasks: ITask[], idUser: string ): number => {
  let total = 0;
  tasks.forEach(( item: ITask ) => {
    if ( item.students.includes( idUser )
    && new Date( item.notes[0].fecha.toDate()) <= new Date( item.dateEnd.toDate())) {
      total += 1;
    }
  });
  return total;
};

export const getTaskUnrealized = ( tasks: ITask[], idUser: string ): number => {
  let total = 0;
  tasks.forEach(( item: ITask ) => {
    if ( item.students.includes( idUser )
    && new Date( item.notes[0].fecha.toDate()) > new Date( item.dateEnd.toDate())) {
      total += 1;
    }
  });
  return total;
};

export const getTaskDelayed = ( tasks: ITask[]): number => {
  let total = 0;
  tasks.forEach(( item: ITask ) => {
    if ( item.students.length <= 0 ) {
      total += 1;
    }
  });
  return total;
};

export const getAverage = ( tasks: ITask[], idUser: string ): number => {
  let total = 0;
  tasks.forEach(( item: ITask ) => {
    if ( item.students.includes( idUser )) {
      const { notes = [] } = item;
      if ( notes.length > 0 ) {
        const sum = notes[0].noteFinal;
        total += sum;
      }
    }
  });
  if ( total !== 0 ) {
    total /= tasks.length;
  }
  return total;
};

export const getTotalTaskBySubject = async (
  subjectId: string,
): Promise<{ tasks: number, notes: number }> => {
  try {
    const tasks = await db.collection( COLLECTION_TASKS )
      .where( 'subjectId', '==', subjectId )
      .get();

    if ( tasks.empty ) {
      return { tasks: 0, notes: 0 };
    }

    let notesTotal = 0;
    await asyncForEach( tasks.docs, async ( docTask: any ) => {
      const allTasks = await db.collection( COLLECTION_TASKS )
        .doc( docTask.id )
        .collection( 'notes' )
        .get();
      allTasks.forEach(() => {
        notesTotal += 1;
      });
    });

    return { tasks: tasks.docs.length, notes: notesTotal };
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    return { tasks: 0, notes: 0 };
  }
};

export const getNoteById = async (
  idTask: string, idNote: string,
): Promise<any> => {
  try {
    const note = await db.collection( COLLECTION_TASKS )
      .doc( idTask )
      .collection( 'notes' )
      .doc( idNote )
      .get();
    if ( note.exists ) {
      const noteAux = note.data();
      return noteAux;
    }
    return {};
  } catch ( error ) {
    // TODO:
    return {};
  }
};
