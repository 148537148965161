import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

  },
  link: {
    textDecoration: 'none',
    color: '#0caff0',
    fontStyle: 'italic',
  },
}));

export default useStyles;
