import React, { useState, useEffect } from 'react';

// Material Ui
import {
  Avatar,
  Box,
  Grid,
  Card,
  Fab,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import gradeIcon from '../../../../../../assets/svg/gradeIcon.svg';
import { useStyles } from './Styles';
import { RootState } from '../../../../../../redux/store';
import { IStudent } from '../../../../../../interfaces/IStudent';
import { getAverageReports } from '../../../../../../services/Report.Services';

interface IProps {
  student: IStudent;
}

const TestComponent: React.FC<IProps> = ( props ): any => {
  const classes = useStyles();
  const { student } = props;
  const history = useHistory();
  const [average, setAverage] = useState<number>( 0 );

  const { formData: subject } = useSelector(
    ( state: RootState ) => state.subject,
  );

  const { formData } = useSelector(( state: RootState ) => state.institute );

  useEffect(() => {
    handleGetAverage();
  }, [formData, subject]);

  const handleGetAverage = async (): Promise<void> => {
    const total = await getAverageReports(
      formData.period.id,
      subject.id,
      student.id,
    );
    setAverage( total );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={10}>
        <Card className={classes.card}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            gridColumnGap={10}
          >
            <Box
              display="flex"
              alignItems="center"
              gridColumnGap={10}
              flexWrap="wrap"
            >
              <Avatar
                src={student.photo}
                alt="photoStudent"
              />
              <h4>
                {`${student.name} ${student.surname}`}
              </h4>
            </Box>
            <Fab
              size="small"
              onClick={
                () => history.push(
                  `/docente/calificaciones/finales/${subject.id}/${student.id}`,
                )
              }
            >
              <img
                src={gradeIcon}
                alt="editHomework"
                className={classes.svgEdit}
              />
            </Fab>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Card className={classes.card}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {average < 6 && (
              <span
                className={`${classes.red}`}
              >
                {average}
              </span>
            )}
            {average >= 6 && average <= 7 && (
              <span
                className={`${classes.yellow} ${classes.noMargin}`}
              >
                {average}
              </span>
            )}
            {average > 7 && average <= 9 && (
              <span
                className={`${classes.green} ${classes.noMargin}`}
              >
                {average}
              </span>
            )}
            {average > 9 && (
              <span
                className={`${classes.blue} ${classes.noMargin}`}
              >
                {average}
              </span>
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TestComponent;
