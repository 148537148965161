import { toast } from 'react-toastify';
import { db } from './firebase';
import { IScheme } from '../interfaces/IScheme';
import { IStudent } from '../interfaces/IStudent';
import { asyncForEach } from '../helpers/async-foreach';
import { getAllNotesInScheme } from './Scheme.Services';
import { getNoteById } from './Task.Services';
import { IReportCard } from '../interfaces/IReportCard';
import { stringToTimeStamp } from '../helpers/convert-to-timestamp';

const NOTE_BASE = process.env.REACT_APP_NOTE_BASE;
const COLLECTION_REPORT = 'reportCard';

export const generateReportsByStudents = async (
  students: IStudent[], schemes: IScheme[],
): Promise<any[]> => {
  try {
    const list: number[] = [];
    for ( let i = 0; i < 100; i++ ) {
      list.push( i );
    }
    const notesList : any[] = [];
    await asyncForEach( schemes, async ( scheme: IScheme ) => {
      if ( scheme.external ) {
        const notes = await getAllNotesInScheme( scheme.id );
        notes.forEach(( item: any ) => {
          notesList.push({
            idStudent: item.id,
            idScheme: scheme.id,
            noteFinal: item.noteFinal,
          });
        });
      }
    });

    await asyncForEach( schemes, async ( scheme: IScheme ) => {
      if ( !scheme.external ) {
        await asyncForEach( students, async ( student: IStudent ) => {
          let sumNotes = 0;
          await asyncForEach( scheme.tasks, async ( task: string ) => {
            const note = await getNoteById( task, student.id );
            sumNotes += note.noteFinal;
          });
          const noteFinal = ( sumNotes / scheme.tasks.length ).toFixed( 2 );
          notesList.push({
            idStudent: student.id,
            idScheme: scheme.id,
            noteFinal: parseFloat( noteFinal ),
          });
        });
      }
    });

    const batch = db.batch();

    students.forEach(( student: IStudent ) => {
      const report: IReportCard = {
        creation: stringToTimeStamp( new Date().toISOString()),
        idPeriod: '',
        idStudent: student.id,
        idSubject: '',
        indexPartial: 0,
        noteFinal: 0,
        notes: [],
      };

      schemes.forEach(( scheme: IScheme ) => {
        const noteData = notesList.find(
          ( x: any ) => x.idStudent === student.id && x.idScheme === scheme.id,
        );
        if ( noteData ) {
          const percent = scheme.percent / 10;
          const total = (( noteData.noteFinal || 3 ) * percent )
        / parseInt(( NOTE_BASE || 10 ).toString(), 10 );

          report.notes.push({
            idScheme: scheme.id,
            note: parseFloat( total.toFixed( 2 )),
          });

          report.noteFinal += parseFloat( total.toFixed( 2 ));
          report.idPeriod = scheme.idPeriod;
          report.indexPartial = scheme.indexPartial;
          report.idSubject = scheme.idSubject;
        }
      });

      const idNewDoc = `${report.idPeriod}${report.idSubject}${report.idStudent}${report.indexPartial}`;

      const reportDoc = db.collection( COLLECTION_REPORT )
        .doc( idNewDoc );
      batch.set( reportDoc, report );
    });

    await batch.commit();
    toast.success( `Se crearon los reportes para los estudiantes` );
    return [];
  } catch ( error ) {
    //
    toast.warning( `No se puedieron crear los reportes,
     vuelva a intentarlo en unos minutos por favor.`, {
      autoClose: false,
    });
    return [];
  }
};

export const getNoteReportById = async ( id: string ): Promise<any> => {
  try {
    const collection = await db.collection( COLLECTION_REPORT )
      .doc( id ).get();
    let report = {};
    if ( collection.exists ) {
      const data = collection.data();
      report = { ...data, id };
    }
    return report;
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    return {};
  }
};

export const getAverageReports = async (
  idPeriod: string, idSubject: string, idStudent: string,
): Promise<number> => {
  try {
    const reports = await db.collection( COLLECTION_REPORT )
      .where( 'idPeriod', '==', idPeriod )
      .where( 'idSubject', '==', idSubject )
      .where( 'idStudent', '==', idStudent )
      .get();

    if ( !reports.empty ) {
      let totalNoteFinal = 0;
      const totalReports = reports.size;
      reports.docs.forEach(( doc ) => {
        const data = doc.data() as IReportCard;
        totalNoteFinal += data.noteFinal;
      });
      const average = parseFloat(
        ( totalNoteFinal / totalReports ).toFixed( 2 ),
      );
      return average;
    }
    return 0;
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    return 0;
  }
};

export const getReportsByPeriodAndSubject = async (
  idPeriod: string, idSubject: string,
): Promise<IReportCard[]> => {
  try {
    const reports = await db.collection( COLLECTION_REPORT )
      .where( 'idPeriod', '==', idPeriod )
      .where( 'idSubject', '==', idSubject )
      .get();
    if ( !reports.empty ) {
      const data : IReportCard[] = [];
      reports.docs.forEach(( doc ) => {
        data.push({ ...doc.data() as IReportCard });
      });
      return data;
    }
    return [];
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    return [];
  }
};
