import React, { useEffect, useState } from 'react';
import {
  Avatar, Box, Grid, makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ITeacher } from '../../interfaces/ITeacher';
import { RootState } from '../../redux/store';
import { actions } from '../../redux/ducks/TeacherDucks';
import { IStudent } from '../../interfaces/IStudent';
import { getStudentByIdThunk } from '../../redux/ducks/StudentDucks';

interface IGradeUserCardProps {
  // eslint-disable-next-line react/require-default-props
  teacherId?: string;
  // eslint-disable-next-line react/require-default-props
  studentId?: string;
}

const useStyles = makeStyles({
  avatar: {
    width: '4em',
    height: '4em',
  },
});

const GradeUserCard: React.FC<IGradeUserCardProps> = ( props ) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { teacherId, studentId } = props;
  const [userData, setUserData] = useState<ITeacher | IStudent>();

  const teacher: ITeacher = useSelector(
    ( state: RootState ) => state.teacher.teacherById,
  );

  const student: IStudent = useSelector(
    ( state: RootState ) => state.student.formData,
  );

  useEffect(() => {
    if ( teacherId ) {
      dispatch( actions.getTeacher( teacherId ));
    }
    if ( studentId ) {
      dispatch( getStudentByIdThunk( studentId ));
    }
  }, [props]);

  useEffect(() => {
    if ( student && studentId ) {
      setUserData( student );
    }
    if ( teacher && teacherId ) {
      setUserData( teacher );
    }
  }, [student, teacher]);

  return (
    <Grid item xs={12}>
      <Box display="flex" justifyContent="center">
        {userData && (
          <Avatar
            className={classes.avatar}
            src={userData.photo}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="center">
        {userData && (
          <h4>{ userData.name }</h4>

        )}
      </Box>
    </Grid>
  );
};

export default GradeUserCard;
