import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    columnGap: 20,
    rowGap: 10,
    flexWrap: 'wrap',
  },
  card: {
    borderRadius: 10,
  },
  subject: {
    flex: 1,
    cursor: 'pointer',
    padding: '20px 50px 20px 20px',
  },
  calification: {
    padding: 10,
    flex: 0.2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 600,
  },
  titleTask: {
    fontSize: 11,
  },
  containerTask: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5,
  },
  containerIconsTasks: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10,
  },
  containerIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 5,
  },
  icon: {
    width: 25,
    height: 25,
  },
  avatar: {
    width: 70,
    height: 70,
  },
  titleCal: {
    fontWeight: 600,
    color: '#149E3D',
    fontSize: 25,
  },
});
