import React from 'react';
import { useDrop } from 'react-dnd';
import useStyles from './Styles';

interface IProps {
  children: any | any[];
  title: string;
  id: string;
}

const BoxQuestion: React.FC<IProps> = ( props ) => {
  const { children, title, id } = props;

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'ITEM',
    drop: () => ({ name: title, id }),
    collect: ( monitor ) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const classes = useStyles( isOver );

  const handleCreateQuestion = (): JSX.Element[] => {
    const str = title.split( ' ' );
    return (
      str.map(( item: string, i: number ) => (
        !item.includes( '**' )
          ? (
            <span key={i.toString()} className={classes.sentenceItem}>
              {item}
            </span>
          )
          : (
            <div key={i.toString()} className={classes.response}>
              { children }
            </div>
          )
      ))
    );
  };

  return (
    <div
      ref={drop}
      className={classes.root}
    >
      {handleCreateQuestion()}
    </div>
  );
};

export default BoxQuestion;
