import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import TaskForm from '../../../components/TaskForm/TaskForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import { ITask } from '../../../interfaces/ITask';
import {
  createTaskThunk,
  actions as actionsTask,
} from '../../../redux/ducks/TaskDucks';
import { stringToTimeStamp } from '../../../helpers/convert-to-timestamp';
import { auth, fieldValue } from '../../../services/firebase';
import Card from '../../../components/Card/Card';
import { getInstituteThunk } from '../../../redux/ducks/InstituteDucks';
import {
  actions as actionsUnit,
} from '../../../redux/ducks/UnitDucks';
import { RootState } from '../../../redux/store';

const TasksCreate: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { idTask } = useSelector(( state: RootState ) => state.task );
  const { formData } = useSelector(( state: RootState ) => state.subject );
  const subject = useSelector(( state: RootState ) => state.subject.formData );

  const handlerSubmit = ( values: any ): void => {
    if ( !auth.currentUser ) return;
    const { uid } = auth.currentUser;
    const task = { ...values } as ITask;
    task.teacherId = uid;
    task.subjectId = formData.id;
    task.activities = [];
    task.activitiesBook = [];
    task.reviewed = null;
    task.creation = fieldValue.serverTimestamp();
    task.dateStart = stringToTimeStamp( values.dateStart );
    task.dateEnd = stringToTimeStamp( values.dateEnd );
    dispatch( createTaskThunk( task ));
  };

  useEffect(() => {
    dispatch( actionsTask.setInicitalFormTask());
    dispatch( actionsTask.setIdTask( '' ));
    dispatch( getInstituteThunk());
    dispatch( actionsUnit.getUnits());
  }, []);

  useEffect(() => {
    if ( idTask && idTask.length > 0 ) {
      history.replace( `/docente/tareas/editar/${idTask}` );
    } else {
      history.replace( `/docente/tareas/agregar` );
    }
  }, [idTask]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[
                {
                  name: subject.name,
                  url: `docente/tareas/detalle/${subject.id}`,
                }]}
              itemsText={['Tareas', 'Crear nueva tarea']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <TaskForm onSubmit={handlerSubmit} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default TasksCreate;
