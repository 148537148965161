import React, { useEffect, useState } from 'react';
import * as IconM from '@material-ui/icons/';
// Interface
import { useDispatch, useSelector } from 'react-redux';
// Redux
import {
  Grid,
  Box,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router';
import { reset } from 'redux-form';
import { toast } from 'react-toastify';
import { RootState } from '../../../../redux/store';
import {
  getInstituteThunk,
} from '../../../../redux/ducks/InstituteDucks';
import {
  createScheme,
  getScheme,
  updateScheme,
  clearById,
  deleteScheme,
} from '../../../../redux/ducks/SchemeDucks';
import {
  getTasksAllByCoursePartialAndSubjectThunk,
} from '../../../../redux/ducks/TaskDucks';
import {
  actions,
} from '../../../../redux/ducks/CourseDucks';
// Components
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import MessageCard from '../../../../components/MessageCard/MessageCard';
// eslint-disable-next-line max-len
import SchemeByPartial from '../../../../components/SchemeByPartial/SchemeByPartial';
import { IPartial } from '../../../../interfaces/IPartial';
import TabsGrade from '../../../../components/TabsGrade/TabsGrade';
import Dialog from '../../../../components/Dialog/Dialog';
import CardGradeType from '../../../../components/CardGradeType/CardGradeType';
import TransferListTasks from '../../../../components/TransferList/TransferListTasks';
import SchemeForm from '../../../../components/SchemeForm/SchemeForm';
import { IInstitute } from '../../../../interfaces/IInstitute';
import {
  getSubjectByIdThunk, getStudentBySubjectThunk,
} from '../../../../redux/ducks/SubjectDucks';
import useStyles from './Styles';
import {
  getSchemesByPartial,
} from '../../../../services/Scheme.Services';
import ReportsList from './components/ReportsList/ReportsList';
import {
  generateReportsByStudents,
} from '../../../../services/Report.Services';
import ButtonIcon from '../../../../components/ButtonIcon/ButtonIcon';
import { writeFileStyle } from '../../../../helpers/write-excel';

interface Params {
  subjectId: string;
}

const TeacherGradesBySubject: React.FC = () => {
  const classes = useStyles();
  const params = useParams<Params>();
  const dispatch = useDispatch();
  const { formData } = useSelector(( state: RootState ) => state.institute );
  const [open, setOpen] = useState( false );
  const [partial, setPartial] = useState( '' );
  const [tasksForPass, setTasksForPass] = useState<string[]>();
  const [openTransferList, setOpenTransferList] = useState( false );
  const [openForm, setOpenForm] = useState( false );
  const [external, setExternal] = useState( false );
  const [percentage, setPercentage] = useState<number>( 0 );
  const [openAlert, setOpenAlert] = useState<boolean>( false );
  const [titleAlert, setTitleAlert] = useState<string>( '' );
  const [messageAlert, setMessageAlert] = useState<string>( '' );

  const history = useHistory();

  const { tasksForGrade } = useSelector(
    ( state: RootState ) => state.task,
  );

  const { schemeById } = useSelector(
    ( state: RootState ) => state.scheme,
  );

  const { formData: subject, studentList } = useSelector((
    state: RootState,
  ) => state.subject );

  const { formData: institute } = useSelector((
    state: RootState,
  ) => state.institute );

  const { courseById } = useSelector((
    state: RootState,
  ) => state.course );

  const handleClose = ( ): void => {
    setOpen( false );
    setOpenTransferList( false );
    setOpenForm( false );
    dispatch( clearById());
  };

  const handleOpen = (
    partialFromMapped: string,
    percentageFromMapped: number,
  ): void => {
    setPercentage( percentageFromMapped );
    if ( percentageFromMapped >= 100 ) {
      toast.warning( 'Ya haz alcanzado la máxima calificación.' );
    } else {
      setOpen( true );
    }
    setTasksForPass([]);
    setPartial( partialFromMapped );
    dispatch( reset( 'scheme' ));
    dispatch( clearById());
  };

  const handleDeleteTask = ( tasks: string[]): void => {
    setTasksForPass( tasks );
  };

  const handleSaveTask = ( tasks: string[]): void => {
    setTasksForPass( tasks );
  };

  const handleOpenTransferList = ( ): void => {
    setOpen( false );
    setExternal( false );
    setOpenTransferList( true );
    dispatch(
      getTasksAllByCoursePartialAndSubjectThunk( params.subjectId, partial ),
    );
  };

  const handleEditScheme = (
    partialToEdit: string,
    tasksNotebook: string[],
    notebookId: string,
    externalTScheme: boolean,
    percentageFromMapped: number,
  ): void => {
    setPercentage( percentageFromMapped );
    setOpen( false );
    if ( externalTScheme ) {
      setOpenForm( true );
    } else {
      setOpenTransferList( true );
    }
    setTasksForPass( tasksNotebook );
    setPartial( partialToEdit );
    dispatch(
      getTasksAllByCoursePartialAndSubjectThunk(
        params.subjectId, partialToEdit,
      ),
    );
    dispatch(
      getScheme( notebookId ),
    );
  };

  const handleOpenDirectForm = ( ): void => {
    setOpen( false );
    setExternal( true );
    setOpenForm( true );
  };

  const handleOpenForm = ( ): void => {
    setOpen( false );
    setOpenTransferList( false );
    setOpenForm( true );
  };

  const showToast = ( rest: number ): void => {
    toast.error( `Solo puedes de clarar un
     porcentage menor o igual a ${rest}` );
  };

  const handleSubmitScheme = ( values: any ): void => {
    const rest = 100 - percentage;
    if ( values.percent > rest ) {
      showToast( rest );
      return;
    }
    const index = formData.partial.findIndex(
      ( item: IInstitute ) => item.name === partial,
    );
    if ( values.id ) {
      dispatch( updateScheme(
        values.id, values, tasksForPass, index, formData.period.id,
      ));
      dispatch( reset( 'scheme' ));
    } else {
      dispatch( createScheme(
        values,
        tasksForPass,
        index,
        formData.period.id,
        params.subjectId,
        external,
      ));
      dispatch( reset( 'scheme' ));
    }
    dispatch( clearById());
    setOpenForm( false );
  };

  const handleDeleteScheme = ( id: string ): void => {
    dispatch( deleteScheme(
      id,
    ));
    dispatch( clearById());
    setOpenForm( false );
  };

  const handleShowNotes = ( id: string ): void => {
    history.push( `/docente/calificaciones/parametro/${id}` );
  };

  useEffect(() => {
    if ( subject ) {
      dispatch( actions.getCourse( subject.courseId ));
    }
  }, [subject]);

  useEffect(() => {
    dispatch( getSubjectByIdThunk( params.subjectId ));
    dispatch( getInstituteThunk());
    // get students by subject an course
    dispatch( getStudentBySubjectThunk( params.subjectId ));
  }, []);

  const handleGenerateReport = async ( index: number ): Promise<void> => {
    const data = await getSchemesByPartial( index, params.subjectId );
    if ( data ) {
      if ( data.length === 0 ) {
        const title = `No existen parametros`;
        const msg = `En el parcial elegido no se 
        han creado parametors o esquemas para poder
        generar calificaciones.`;
        handleOpenAlert( title, msg );
        return;
      }
      await generateReportsByStudents( studentList, data );
    }
  };

  const handleOpenAlert = ( title: string, message: string ): void => {
    setTitleAlert( title );
    setMessageAlert( message );
    setOpenAlert( !openAlert );
  };

  const handleDwonload = async (): Promise<void> => {
    if ( subject && courseById && institute ) {
      const { name: courseName } = courseById;
      const { name: subjectName } = subject;
      if ( courseName && subjectName && institute.period ) {
        const { name: periodName } = institute.period;
        let fileName = `${courseName}-${subjectName}-${periodName}`;
        fileName = fileName.replace( /\s/g, '-' );
        // eslint-disable-next-line no-unused-vars
        fileName = fileName.replace( /---/g, '-' );
        writeFileStyle( institute, subject, courseById, studentList, fileName );
      }
    }
  };

  return (
    <>
      <div className={classes.card}>
        <ContainerBreadcrumbs>
          <Breadcrumbs
            back={false}
            itemsLinks={[
              { name: 'Calificaciones', url: 'docente/calificaciones' }]}
            itemsText={[`${subject ? subject.name : ''}:
            parametros de calificación`]}
          />
        </ContainerBreadcrumbs>
        {
          subject && courseById && institute && (
            <div className={classes.send}>
              <ButtonIcon primary handleClick={handleDwonload}>
                <IconM.CloudDownload className={classes.iconSend} />
              </ButtonIcon>
              <span>Descargar</span>
            </div>
          )
        }
      </div>
      <TabsGrade
        tab1={(
          <Grid container spacing={2}>
            {formData && formData.partial.length > 0
              ? formData.partial.map(( item: IPartial, i: number ) => (
                <SchemeByPartial
                  partial={item}
                  key={i.toString()}
                  index={i}
                  handleClickMore={handleOpen}
                  handleEditScheme={handleEditScheme}
                  handleDeleteScheme={handleDeleteScheme}
                  handleShowNotes={handleShowNotes}
                  handleCreateReports={() => handleGenerateReport( i )}
                />
              )) : (
                <MessageCard>
                  <h3>No hay paciales</h3>
                </MessageCard>
              )}
          </Grid>
        )}
        tab2={(
          <ReportsList
            idSubject={params.subjectId}
          />
        )}
      />
      <Dialog
        open={open}
        title="Seleccionar tipo de calificación"
        onClose={handleClose}
        size="md"
      >
        <CardGradeType
          handleOpenTransferList={handleOpenTransferList}
          handleOpenDirectForm={handleOpenDirectForm}
        />
      </Dialog>
      <Dialog
        open={openTransferList}
        title={`Tareas ${partial}`}
        onClose={handleClose}
        size="md"
        onConfirm={handleOpenForm}
      >
        <>
          <TransferListTasks
            itemsLEFT={tasksForGrade}
            itemsRIGHT={tasksForPass}
            handleDeleteData={handleDeleteTask}
            handleSaveData={handleSaveTask}
          />
        </>
      </Dialog>
      <Dialog
        open={openForm}
        title="Seleccionar tipo de calificación"
        onClose={handleClose}
      >
        <SchemeForm initialValues={schemeById} onSubmit={handleSubmitScheme} />
      </Dialog>

      <Dialog
        open={openAlert}
        title={titleAlert}
        onClose={handleOpenAlert}
        size="xs"
      >
        <Box
          flexDirection="column"
          display="flex"
          gridRowGap={10}
        >
          <span>
            {messageAlert}
          </span>
        </Box>
      </Dialog>
    </>
  );
};

export default TeacherGradesBySubject;
