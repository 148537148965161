/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrums from '../../../components/Breadcrumbs/Breadcrumbs';
import { RootState } from '../../../redux/store';
import LabelInfo from '../../../components/LabelInfo/LabelInfo';
import { getTaskByIdThunk } from '../../../redux/ducks/TaskDucks';
import Card from '../../../components/Card/Card';

interface IParams {
  id: string;
}

const TasksView: React.FC = () => {
  const dispatch = useDispatch();
  const { formData, loading } = useSelector((
    state: RootState,
  ) => state.task );
  const { id } = useParams<IParams>();

  useEffect(() => {
    if ( id ) {
      dispatch( getTaskByIdThunk( id ));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrums
              itemsLinks={[{ name: 'Tareas', url: 'docente/tareas' }]}
              itemsText={['Información de la tarea']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          {!loading && formData && (
            <Grid container>
              <Grid item xs={12} sm={8}>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelInfo
                      label="Titulo"
                      text={formData.title}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelInfo
                      label="Descripción"
                      text={formData.description}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelInfo
                      label="Fecha de inicio"
                      text={formData.dateStart}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelInfo label="Fecha de fin" text={formData.dateEnd} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default TasksView;
