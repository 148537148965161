import React from 'react';
import { TableContainer, Paper } from '@material-ui/core';

interface ITableContainerProps {
  children: JSX.Element | JSX.Element[];
}

const ContainerTable: React.FC<ITableContainerProps> = ( props ) => {
  const { children } = props;

  return (
    <TableContainer component={Paper}>
      { children }
    </TableContainer>
  );
};

export default ContainerTable;
