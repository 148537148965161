import React from 'react';
import { Link, useHistory } from 'react-router-dom';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import BreadcrumbsMaterial from '@material-ui/core/Breadcrumbs';
import arrowLeft from '../../assets/svg/arrowLeft.svg';
// Styles
const useStyles = makeStyles(() => ({
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: `rgba(0, 0, 0, 0.65)`,
  },
  uppercase: {
    textTransform: `capitalize`,
  },
  centerArrow: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

interface IBreadcrumsProps {
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  maxItems?: number;
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  itemsAfterCollapse?: number;
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  itemsBeforeCollapse?: number;
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  separator?: string;
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  itemsLinks?: { name: string, url: string }[];
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  itemsText?: string[];
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  back?: boolean;
}

const Breadcrumbs: React.FC<IBreadcrumsProps> = ( props ) => {
  const {
    itemsAfterCollapse = 1,
    itemsBeforeCollapse = 3,
    separator = '/',
    itemsLinks = [],
    itemsText = [],
    back = true,
  } = props;

  const history = useHistory();

  const classes = useStyles();

  return (
    <BreadcrumbsMaterial
      aria-label="breadcrumb"
      itemsAfterCollapse={itemsAfterCollapse}
      itemsBeforeCollapse={itemsBeforeCollapse}
      separator={separator}
    >
      {back && (
        <Typography
          color="textPrimary"
          onClick={() => history.goBack()}
          className={`${classes.centerArrow}`}
        >
          <img src={arrowLeft} alt="Página anterior" />
        </Typography>
      )}
      {itemsLinks.map(( item, i: number ) => (
        <Link to={`/${item.url}`} key={i.toString()} className={classes.link}>
          <Box
            fontWeight="fontWeightBold"
            fontSize={25}
            className={classes.uppercase}
          >
            {item.name}
          </Box>
        </Link>
      ))}
      {itemsText.map(( item, i: number ) => (
        <Typography key={i.toString()} color="textPrimary">{item}</Typography>
      ))}
    </BreadcrumbsMaterial>
  );
};

export default Breadcrumbs;
