import React from 'react';
import { useStyles } from './Styles';

interface IColorGrade{
  number: string;
}

const ColorGrade: React.FC<IColorGrade> = ( props ) => {
  const { number } = props;
  const classes = useStyles();
  const colorer = (): JSX.Element => {
    let result;
    switch ( true ) {
      case ( Number( number ) < 6 ):
        result = (
          <span
            className={`${classes.red} ${classes.margin}`}
          >
            {number}
          </span>
        );
        break;
      case ( Number( number ) >= 6 && Number( number ) <= 7 ):
        result = (
          <span
            className={`${classes.yellow} ${classes.margin}`}
          >
            {number}
          </span>
        );
        break;
      case ( Number( number ) >= 7 && Number( number ) <= 9 ):
        result = (
          <span
            className={`${classes.green} ${classes.margin}`}
          >
            {number}
          </span>
        );
        break;
      case ( Number( number ) >= 9 ):
        result = (
          <span
            className={`${classes.blue} ${classes.margin}`}
          >
            {number}
          </span>
        );
        break;
      default:
        result = (
          <span
            className={`${classes.red} ${classes.margin}`}
          >
            0
          </span>
        );
        break;
    }
    return result;
  };
  return (
    <>
      {colorer()}
    </>
  );
};

export default ColorGrade;
