import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import AccordionItem from './components/AccordionItem/AccordionItem';
import {
  getNoteReportById,
} from '../../../../../../services/Report.Services';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useStyles,
} from './Styles';
import MessageCard from '../../../../../../components/MessageCard/MessageCard';
import ColorGrade from '../../../../../../components/ColorGrade/ColorGrade';

interface IGradeAccordion {
  partial: any;
  index: number;
  idReport: string;
}

const GradeAccordion: React.FC<IGradeAccordion> = ( props ) => {
  const classes = useStyles();
  const { partial, index, idReport } = props;
  const [report, setReport] = useState<any>();
  const [expanded, setExpanded] = React.useState<string | false>( 'panel1' );
  const handleChange = ( panel: string ) => (
    event: React.ChangeEvent<any>,
    isExpanded: boolean,
  ) => {
    setExpanded( isExpanded ? panel : false );
  };

  const handleGetData = async (
    indexToSearch: number,
  ): Promise<void> => {
    const data = await getNoteReportById(
      `${idReport}${indexToSearch}`,
    );
    setReport( data );
  };

  useEffect(() => {
    let isMountend = false;
    if ( !isMountend ) {
      handleGetData( index );
    }
    return () => { isMountend = true; };
  }, [props]);

  return (
    <Grid item xs={12}>
      <Accordion
        square
        expanded={expanded === 'panel1'}
        onChange={handleChange( 'panel1' )}
        className={classes.accordion}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                className={classes.namePartial}
              >
                <Typography>{partial.name}</Typography>
              </AccordionSummary>
            </Grid>
            <Grid item xs={4}>
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                className={classes.averagePartial}
              >
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <Typography>
                    Promedio
                  </Typography>
                  <ColorGrade number={report && parseFloat( report.noteFinal ?? '0' ).toFixed( 2 )} />
                </Box>
              </AccordionSummary>
            </Grid>
          </Grid>
        </Box>
        <AccordionDetails>
          <div className={classes.squemeList}>
            {report && report.notes?.length > 0
              ? report.notes?.map(( item: any, i: number ) => (
                <AccordionItem report={item} key={i.toString()} />
              )) : (
                <MessageCard>
                  <h3>Aún no hay notas para este parcial.</h3>
                </MessageCard>
              )}
          </div>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default GradeAccordion;
