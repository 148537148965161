import React, { useEffect } from 'react';
// Interface
import { useDispatch, useSelector } from 'react-redux';
// Redux
import {
  Grid,
} from '@material-ui/core';
import { RootState } from '../../../../redux/store';
import {
  getSubjectsByTeacherIdThunk,
} from '../../../../redux/ducks/SubjectDucks';
import {
  actions as actionsTeachers,
} from '../../../../redux/ducks/TeacherDucks';
import {
  actions as actionsBooks,
} from '../../../../redux/ducks/BookDucks';
// Components
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import SubjectGradeItemTeacher from '../../../../components/SubjectGradeItemTeacher/SubjectGradeItemTeacher';
import { ISubject } from '../../../../interfaces/ISubject';
import MessageCard from '../../../../components/MessageCard/MessageCard';
import Spinner from '../../../../components/Spinner/Spinner';

const TeacherAllMyGrades: React.FC = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((
    state: RootState,
  ) => state.login );

  const { subjects, loading } = useSelector(( state: RootState ) => state.subject );

  useEffect(() => {
    dispatch( actionsTeachers.getTeachers());
    dispatch( actionsBooks.getBooks());
    dispatch( getSubjectsByTeacherIdThunk( userData.id ));
  }, []);

  return (
    <>
      <ContainerBreadcrumbs>
        <Breadcrumbs
          back={false}
          itemsLinks={[
            { name: 'Calificaciones', url: 'estudiante/calificaciones' }]}
          itemsText={['Todas tus calificaciones']}
        />
      </ContainerBreadcrumbs>
      <Grid container direction="row" justify="center">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {subjects && subjects.length > 0 ? (
              <>
                {subjects
            && subjects.map(( item: ISubject, i: number ) => (
              <Grid item xs={12} key={i.toString()}>
                <SubjectGradeItemTeacher subject={item} />
              </Grid>
            ))}
              </>
            ) : (
              <MessageCard>
                <h3>No hay materias asignadas.</h3>
              </MessageCard>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default TeacherAllMyGrades;
