import { db } from './firebase';
import { IActivity } from '../interfaces/IActivity';
import { asyncForEach } from '../helpers/async-foreach';

export const getActivityById = async ( id: string ): Promise<any> => {
  try {
    const collection = await db.collection( 'activities' )
      .doc( id ).get();
    let activity = {};
    if ( collection.exists ) {
      const data = collection.data() as IActivity;
      activity = { ...data, id };
    }
    return activity;
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    return {};
  }
};

export const getQuestions = async (
  idAct: string, type: string,
): Promise<any> => {
  try {
    const questions = await db.collection( 'activities' )
      .doc( idAct )
      .collection( 'questions' )
      .where( 'type', '==', type )
      .get();
    const data: any[] = [];
    if ( questions.docs.length > 0 ) {
      questions.docs.forEach(( doc ) => {
        data.push({ ...doc.data(), id: doc.id });
      });
    }
    return data;
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    return [];
  }
};

export const getActivitiesById = async ( ids: string[]): Promise<any[]> => {
  try {
    const actList: any[] = [];
    await asyncForEach( ids, async ( item: string ) => {
      const res = await db.collection( 'activities' ).doc( item ).get();
      const data = { ...res.data(), calification: 0, id: res.id };
      if ( res.exists ) {
        actList.push( data );
      }
    });
    return actList.sort(( a, b ) => {
      if ( a.name > b.name ) {
        return 1;
      }
      if ( a.name < b.name ) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    return [];
  }
};
