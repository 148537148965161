import React from 'react';

// Components
import { useSelector } from 'react-redux';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import ProfileDetail from '../../components/ProfileDetail/ProfileDetail';
import { RootState } from '../../redux/store';
import { getRoute } from '../../helpers/get-user-by-type';

const Profile: React.FC = () => {
  const userData = useSelector(( state: RootState ) => state.login.userData );

  return (
    <>
      <ContainerBreadcrumbs>
        <Breadcrumbs
          back={false}
          itemsLinks={[{
            name: 'Tú perfil',
            url: userData ? `${getRoute( userData.type )}/inicio` : '',
          }]}
        />
      </ContainerBreadcrumbs>
      {userData && <ProfileDetail user={userData} />}
    </>
  );
};

export default Profile;
