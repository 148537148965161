import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { RootState } from '../../../../redux/store';
import { getScheme } from '../../../../redux/ducks/SchemeDucks';
import { IStudent } from '../../../../interfaces/IStudent';
import ItemNote from './components/ItemNote/ItemNote';

interface IParams {
  id: string;
}

const NotesScheme: React.FC = () => {
  const params = useParams<IParams>();
  const dispatch = useDispatch();
  const { formData, studentList } = useSelector(
    ( state: RootState ) => state.subject,
  );
  const { schemeById } = useSelector(
    ( state: RootState ) => state.scheme,
  );

  useEffect(() => {
    if ( params.id ) {
      dispatch( getScheme( params.id ));
      // dispatch( getStudentBySubjectThunk( formData.id ));
    }
  }, []);

  return (
    <div>
      <ContainerBreadcrumbs>
        <Breadcrumbs
          back={false}
          itemsLinks={[
            { name: 'Calificaciones', url: 'docente/calificaciones' },
            {
              name: 'Parametros',
              url: formData
                ? `docente/calificaciones/detalle/${formData.id}/${formData.idCourse}`
                : '/',
            }]}
          itemsText={[schemeById ? `${schemeById.name}` : 'Notas']}
        />
      </ContainerBreadcrumbs>
      <div>
        {
          studentList.map(( item: IStudent, i: number ) => (
            <div key={i.toString()}>
              <ItemNote
                idScheme={params.id}
                student={item}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default NotesScheme;
