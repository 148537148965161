import React, { useState } from 'react';
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@material-ui/core';
import {
  change,
  initialize,
} from 'redux-form';
import { useDispatch } from 'react-redux';
import * as IconM from '@material-ui/icons/';
import IconButton from '@material-ui/core/IconButton';
import QuestionForm from './QuestionForm';
import { IQuestion } from '../../../interfaces/IQuestion';

const useStyles = makeStyles(() => ({
  containerList: {
    maxHeight: 200,
    overflowY: 'scroll',
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10,
  },
}));

interface IListQuestionsProps {
  // eslint-disable-next-line react/require-default-props
  handleSave?: any;
  questions: IQuestion[];
  // eslint-disable-next-line react/require-default-props
  handleUpdate?: any;
  // eslint-disable-next-line react/require-default-props
  handleDelete?: any;
}

const ListQuestions: React.FC<IListQuestionsProps> = ( props ) => {
  const classes = useStyles();
  const {
    handleSave, questions = [], handleUpdate, handleDelete,
  } = props;
  const [form, setForm] = useState<any>( undefined );
  const [idSudDoc, setIdSudDoc] = useState( '' );
  const dispatch = useDispatch();

  const handleSaveQuestion = ( values: any ): void => {
    const question: IQuestion = {
      id: '',
      sentence: values.sentence,
      question: getQuestions( values.sentence ) || '',
      answer: getAnswers( values.sentence ) || '',
      type: 'complete',
    };
    if ( !idSudDoc ) {
      handleSave( question );
    } else {
      question.id = idSudDoc;
      handleUpdate( question );
    }
    dispatch( initialize( 'question', undefined ));
  };

  const getAnswers = ( str: string ): string => {
    const vector = str.split( '((' );
    let dataStr = '';
    for ( let i = 0; i < vector.length; i++ ) {
      if ( vector[i].indexOf( '))' ) > -1 ) {
        const item = ( vector[i].split( ')' )[0]).trim();
        dataStr += item;
      }
    }
    return dataStr;
  };

  const getQuestions = ( str: string ): string => {
    let vector = str.split( '((' );
    let dataStr = vector.length > 0 ? vector[0] : '';
    if ( vector.length > 1 ) {
      vector = vector[1].split( '))' );
      dataStr += '**';
      dataStr += vector.length > 1 ? vector[1] : '';
    }
    /*
    for ( let i = 0; i < vector.length; i++ ) {
      if ( vector[i].indexOf( '))' ) > -1 ) {
        const item = ( vector[i].split( '))' )[1]).trim();
        if ( item.trim().length > 0 && i > 0 ) {
          dataStr += `* ${item.trim()}`;
        }
      } else {
        const item = `${vector[i].trim()} *`;
        dataStr += item;
      }
    }
     */
    return dataStr;
  };

  const handleEdit = ( data: IQuestion ): void => {
    setIdSudDoc( data.id || '' );
    if ( form?.sentence !== data.sentence ) {
      dispatch( initialize( 'question', undefined ));
      setForm({ sentence: data.sentence });
    } else {
      dispatch( change( 'question', 'sentence', data.sentence ));
    }
  };

  const handleRemove = ( id: string ): void => {
    handleDelete( id );
  };

  return (
    <div>
      <QuestionForm onSubmit={handleSaveQuestion} initialValues={form} />
      <div className={classes.containerList}>
        {questions.map(( item: IQuestion, i: number ) => (
          <div key={i.toString()}>
            <List dense component="span">
              <ListItem>
                <ListItemIcon>
                  {`${i + 1}.-`}
                </ListItemIcon>
                <ListItemText primary={item.sentence} />
                <ListItemIcon>
                  <IconButton onClick={() => handleEdit( item )}>
                    <IconM.Edit />
                  </IconButton>
                </ListItemIcon>
                <ListItemIcon>
                  <IconButton onClick={() => handleRemove( item.id || '' )}>
                    <IconM.Delete />
                  </IconButton>
                </ListItemIcon>
              </ListItem>
            </List>
            <Divider />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListQuestions;
