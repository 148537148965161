import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  send: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: '2em',
    padding: '0 1em 1em 1em',
  },
  whiteText: {
    color: 'white',
  },
  dividerPartial: {
    fontWeight: 600,
    fontSize: 16,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    columnGap: 10,
  },
  labelDivider: {
    flex: 1,
    padding: '10px 20px',
    borderRadius: 10,
    textAlign: 'center',
  },
  line: {
    flex: 1,
    backgroundColor: 'red',
  },
}));

export default useStyles;
