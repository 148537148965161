import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  containerForm: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: '20px 0px',
    opacity: 0.9,
    maxHeight: 450,
    maxWidth: 400,
  },
  image: {
    width: '80%',
    height: 100,
  },
  link: {
    cursor: 'pointer',
  },

  version: {
    fontSize: 10,
    color: 'gray',
  },
}));

export default useStyles;
