import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  reduxForm, InjectedFormProps, Field,
} from 'redux-form';
import { Grid } from '@material-ui/core';
import { RootState } from '../../redux/store';
import {
  required, minLength5, maxLength30,
} from '../../helpers/validate-forms';
import Input from '../Input/Input';
import Button from '../Button/Button';
import CenterLine from '../Center/CenterInLine';
import Select from '../Select/Select';
import { actions } from '../../redux/ducks/BookDucks';
import ContainerForm from '../ContainerForm/ContainerForm';
import FormRedux from '../FormRedux/FormRedux';
import ContainerInputs from '../ContainerInputs/ContainerInputs';

const UnitForm: React.FC<InjectedFormProps> = ( props ) => {
  const {
    handleSubmit, submitting,
  } = props;
  const booksInStore = useSelector(( state: RootState ) => state.book.books );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch( actions.getBooks());
  }, []);

  return (
    <ContainerForm>
      <FormRedux
        onSubmit={handleSubmit}
      >
        <ContainerInputs>
          <Grid item xs={12} sm={6}>
            <Field
              name="name"
              type="text"
              label="Nombre de la unidad"
              component={Input}
              placeholder="Nombre de la unidad"
              validate={[required, minLength5, maxLength30]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="bookId"
              label="Libros"
              component={Select}
              validate={[required]}
              options={booksInStore}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CenterLine>
              <Button type="submit" disabled={submitting}>
                Enviar
              </Button>
            </CenterLine>
          </Grid>
        </ContainerInputs>
      </FormRedux>
    </ContainerForm>
  );
};

export default reduxForm({
  form: 'unit',
  enableReinitialize: true,
})( UnitForm );
