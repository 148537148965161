import React from 'react';
import { useDrop } from 'react-dnd';
import useStyles from './Styles';

interface IProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  id: string;
}

const BoxOptions: React.FC<IProps> = ( props ) => {
  const { children, title, id } = props;

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'ITEM',
    drop: () => ({ name: title, id }),
  }));

  const classes = useStyles( isOver );

  return (
    <div
      ref={drop}
      className={classes.root}
    >
      {children}
    </div>
  );
};

export default BoxOptions;
