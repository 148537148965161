import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Material UI
import {
  Grid,
} from '@material-ui/core';

// Components
import Spinner from '../../../components/Spinner/Spinner';

// Redux
import { actions } from '../../../redux/ducks/ActivityDucks';
import { RootState } from '../../../redux/store';

// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrums from '../../../components/Breadcrumbs/Breadcrumbs';
import LabelInfo from '../../../components/LabelInfo/LabelInfo';
// eslint-disable-next-line max-len
import IntegrateLabelLink from '../../../components/IntegrateLabelLink/IntegrateLabelLink';
import Card from '../../../components/Card/Card';
import { getRoute } from '../../../helpers/get-user-by-type';
import { IQuestion } from '../../../interfaces/IQuestion';

interface IParams {
  id: string;
}

const ActivityBookView = (): JSX.Element => {
  const dispatch = useDispatch();
  const { activityById, loading, questions } = useSelector((
    state: RootState,
  ) => state.activity );
  const {
    userData,
  } = useSelector(( state: RootState ) => state.login );
  const { id } = useParams<IParams>();

  useEffect(() => {
    if ( id ) {
      dispatch( actions.getActivity( id ));
      dispatch( actions.getQuestionsThunk( id, activityById.subtype ));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrums
              itemsLinks={[
                {
                  name: 'Actividades',
                  url: `${getRoute( userData.type )}/actividades`,
                }]}
              itemsText={['Ver Actividad']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          {!loading && activityById ? (
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Nombre" text={activityById.name} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo
                      label="Descripción"
                      text={activityById.description}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Rubrica" text={activityById.rubric} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo
                      label="Nota máxima"
                      text={activityById.maxGrade}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {activityById.guideUrl
                      ? (
                        <IntegrateLabelLink
                          label="Archivo"
                          href={activityById.guideUrl}
                          title="Descargar documento guía"
                          text=""
                        />
                      )
                      : (
                        <LabelInfo
                          label="Archivo"
                          text="Sin documento"
                        />
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
            : <Spinner />}
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <LabelInfo label="Preguntas" text="" />
          {
            questions.map(( item: IQuestion, i: number ) => (
              <div key={i.toString()}>
                <span>{item.sentence}</span>
              </div>
            ))
          }
        </Card>
      </Grid>
    </Grid>
  );
};

export default ActivityBookView;
