import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as IconM from '@material-ui/icons';
import { Grid, Paper } from '@material-ui/core';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import {
  createStudentBatchThunk, actions,
} from '../../../redux/ducks/StudentDucks';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import { IStudent } from '../../../interfaces/IStudent';
import { handleReadFile } from '../../../helpers/excel-to-json';
import { RootState } from '../../../redux/store';
import ItemListData from '../../../components/ItemListData/ItemListData';
// eslint-disable-next-line max-len
import IntegrationUpload from '../../../components/IntegrationUpload/IntegrationUpload';
import Card from '../../../components/Card/Card';

const StudentUploadBatch = (): JSX.Element => {
  const dispatch = useDispatch();

  const { loading, uploadSuccess } = useSelector((
    state: RootState,
  ) => state.student );
  const [listUpload, setListUpload] = useState<IStudent[]>([]);

  const handleUpload = (): void => {
    if ( listUpload.length > 0 ) {
      dispatch( createStudentBatchThunk( listUpload ));
    }
  };

  const handleSetFile = async ( file: File ): Promise<any> => {
    const data = await handleReadFile( file, 'estudiantes' );
    setListUpload( data );
  };

  useEffect(() => {
    if ( listUpload.length > 0 ) {
      setListUpload([]);
    }
  }, [loading]);

  useEffect(() => {
    if ( uploadSuccess ) {
      dispatch( actions.changeStatusSuccessButton( false ));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'Estudiantes', url: 'admin/estudiantes' }]}
              itemsText={['Carga masiva']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <IntegrationUpload
            handleSetFile={handleSetFile}
            loading={loading}
            handleUpload={handleUpload}
            uploadSuccess={uploadSuccess}
          />
          <Paper>
            <ItemListData data={listUpload} iconItem={<IconM.PersonAdd />} />
          </Paper>
        </Card>
      </Grid>
    </Grid>
  );
};

export default StudentUploadBatch;
