import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 100,
    minHeight: 30,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
