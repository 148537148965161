import React, { useEffect, useState } from 'react';

// Material Ui
import {
  Card,
  CardActions,
  Typography,
  Box,
  CardHeader,
  Fab,
  Avatar,
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ISubject } from '../../interfaces/ISubject';
import { ITeacher } from '../../interfaces/ITeacher';
import { actions } from '../../redux/ducks/TeacherDucks';
import { RootState } from '../../redux/store';
import { useStyles } from './Styles';
import Image from '../Image/Image';
import ExclamationIcon1 from '../../assets/svg/alertIconRed.svg';
import ExclamationIcon2 from '../../assets/svg/alertIconYellow.svg';
import ExclamationIcon3 from '../../assets/svg/alertIconGreen.svg';
import { auth } from '../../services/firebase';
import {
  getTaskByIdSubject,
  getTaskFinished,
  getTaskDelayed,
  getTaskUnrealized,
} from '../../services/Task.Services';

interface ISubjectStudentItemProps {
  subject: ISubject;
}

const SubjectStudentItem: React.FC<ISubjectStudentItemProps> = ( props ) => {
  const {
    subject,
  } = props;

  const teachers: ITeacher[] = useSelector(
    ( state: RootState ) => state.teacher.teachers,
  );

  const [teacherById, setTeacherById] = useState<any>({});
  const [finished, setFinished] = useState( 0 );
  const [delayed, setDelayed] = useState( 0 );
  const [unrealized, setUnrealized] = useState( 0 );

  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();

  const findTeacher = (): void => {
    const teacher = teachers.find(
      ( item: ITeacher ) => item.id === subject.teacherId,
    );
    setTeacherById( teacher );
  };

  useEffect(() => {
    let isMounted = true;
    if ( auth.currentUser && isMounted ) {
      const { uid } = auth.currentUser;
      if ( teachers.length <= 0 ) {
        dispatch( actions.getTeachers());
      }
      findTeacher();
      handleGetTaskByIdSubject( subject.id, uid );
    }
    return () => {
      isMounted = false;
    };
  }, [props]);

  const handleGetTaskByIdSubject = async (
    idSubject: string, idUser: string,
  ): Promise<void> => {
    const resTask = await getTaskByIdSubject( idSubject, idUser );
    setFinished( getTaskFinished( resTask, idUser ));
    setDelayed( getTaskDelayed( resTask ));
    setUnrealized( getTaskUnrealized( resTask, idUser ));
  };

  return (
    <Card className={`${classes.shadow} ${classes.card}`}>
      <CardHeader
        className={classes.cardHeader}
        title={(
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {subject.name}
              </Typography>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {teacherById && ( <span>{teacherById.name}</span> )}
              </Typography>
            </Box>
            <Avatar src={teacherById && ( teacherById.photo )} />
          </Box>
        )}
      />
      <CardActions className={classes.cardActions}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.rowBody}
        >
          <Box display="block" textAlign="center">
            <Fab
              className={classes.playButton}
              aria-label="add"
              onClick={
                () => history.push(
                  `/estudiante/materias/detalle/${subject.id}`,
                )
              }
            >
              <PlayArrowIcon />
            </Fab>
            <p className={classes.playButtonText}>Ingresar</p>
          </Box>
          <Box className={classes.containerTask}>
            <span className={classes.titleTask}>Estado de tareas</span>
            <Box className={classes.containerIconsTasks}>
              <Box className={classes.containerIcon}>
                <Box className={classes.icon}>
                  <Image src={ExclamationIcon1} />
                </Box>
                <span>{unrealized}</span>
              </Box>
              <Box className={classes.containerIcon}>
                <Box className={classes.icon}>
                  <Image src={ExclamationIcon2} />
                </Box>
                <span>{delayed}</span>
              </Box>
              <Box className={classes.containerIcon}>
                <Box className={classes.icon}>
                  <Image src={ExclamationIcon3} />
                </Box>
                <span>{finished}</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

export default SubjectStudentItem;
