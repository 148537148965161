import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import * as IconM from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import Button from '../Button/Button';

const useStyles = makeStyles(( theme: Theme ) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing( 1 ),
    position: 'relative',
  },
  fab: {
    color: '#fff',
    backgroundColor: '#0caff0',
    '&:hover': {
      backgroundColor: '#0B81AF',
    },
  },
  buttonSuccess: {
    color: '#fff',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -5,
    left: -5,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface IButtonProgressProps {
  // eslint-disable-next-line react/require-default-props
  loading?: boolean;
  // eslint-disable-next-line react/require-default-props
  onClick?: any;
  // eslint-disable-next-line react/require-default-props
  title?: string;
  // eslint-disable-next-line react/require-default-props
  success?:boolean;
}

const ButtonProgress: React.FC<IButtonProgressProps> = ( props ) => {
  const classes = useStyles();
  const {
    loading,
    onClick,
    title = 'ok',
    success = false,
  } = props;

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const handleButtonClick = (): void => {
    onClick();
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Fab
          aria-label="save"
          color="primary"
          className={success ? buttonClassname : classes.fab}
          onClick={handleButtonClick}
          style={{ width: 40, height: 40 }}
        >
          {success ? <CheckIcon /> : <IconM.CloudUpload />}
        </Fab>
        {loading
        && <CircularProgress size={50} className={classes.fabProgress} />}
      </div>
      <div className={classes.wrapper}>
        <Button
          disabled={loading}
          onClick={handleButtonClick}
          size="small"
        >
          {title}
        </Button>
        {loading
        && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
};

export default ButtonProgress;
