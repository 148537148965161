import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { toast } from 'react-toastify';
import { RootState } from '../store';
import { db } from '../../services/firebase';
import { IScheme } from '../../interfaces/IScheme';

const COLLECTION_SCHEME = 'scheme';

// action types
export const actionType = {
  GET_SCHEME_REQUEST: 'GET_SCHEME_REQUEST',
  GET_SCHEMES_FROM_DB: 'GET_SCHEMES_FROM_DB',
  SET_CREATE_SCHEME_SUCCESS: 'SET_CREATE_SCHEME_SUCCESS',
  SET_SCHEME_TO_EDIT_SUCCESS: 'SET_SCHEME_TO_EDIT_SUCCESS',
  SET_DELETE_SCHEME_SUCCESS: 'SET_DELETE_SCHEME_SUCCESS',
  SET_UPDATE_SCHEME_SUCCESS: 'SET_UPDATE_SCHEME_SUCCESS',
  SET_LOADING_SCHEME: 'SET_LOADING_SCHEME',
  CLEAR_SCHEME_BY_ID: 'CLEAR_SCHEME_BY_ID',
};

// Interfaces
interface SchemeState {
  schemes: IScheme[] | null;
  schemeById: null;
}

export interface setGetSchemesSuccess {
  type: typeof actionType.GET_SCHEME_REQUEST;
  payload: IScheme[];
}

export interface setCreateSchemeSuccess {
  type: typeof actionType.SET_CREATE_SCHEME_SUCCESS;
}

export interface setDeleteSchemeSuccess {
  type: typeof actionType.SET_DELETE_SCHEME_SUCCESS;
}

export interface setSchemeToEditSuccess {
  type: typeof actionType.SET_SCHEME_TO_EDIT_SUCCESS;
  payload: IScheme;
}

export interface setSchemeUpdateSuccess {
  type: typeof actionType.SET_UPDATE_SCHEME_SUCCESS;
}

export interface setILoadingScheme {
  type: typeof actionType.SET_LOADING_SCHEME;
  payload: boolean;
}

export interface setClearScheme {
  type: typeof actionType.CLEAR_SCHEME_BY_ID;
  payload: boolean;
}

// Thunk Types
type SchemeActions =
  | setGetSchemesSuccess
  | setCreateSchemeSuccess
  | setDeleteSchemeSuccess
  | setSchemeToEditSuccess
  | setILoadingScheme
  | setClearScheme;

// initial state
const initialState: SchemeState = {
  schemes: [],
  schemeById: null,
};

// reducer
export default function schemeReducer(
  state = initialState, action: AnyAction,
) : any {
  const { type, payload } = action;

  switch ( type ) {
    case actionType.GET_SCHEMES_FROM_DB:
      return {
        ...state,
        schemes: payload,
      };
    case actionType.SET_SCHEME_TO_EDIT_SUCCESS:
      return {
        ...state,
        schemeById: payload,
        loading: false,
      };
    case actionType.GET_SCHEME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SET_LOADING_SCHEME:
      return {
        ...state,
        loading: payload,
      };
    case actionType.CLEAR_SCHEME_BY_ID:
      return {
        ...state,
        schemeById: payload,
      };
    case actionType.SET_CREATE_SCHEME_SUCCESS:
    case actionType.SET_DELETE_SCHEME_SUCCESS:
      return state;
    default:
      return state;
  }
}

// actions creators
export const getSchemes = (): ThunkAction<
void, RootState, null, SchemeActions> => async ( dispatch ) => {
  try {
    db.collection( COLLECTION_SCHEME )
      .onSnapshot(( querySnapshot ) => {
        const schemes: any = [];
        querySnapshot.forEach(( scheme ) => {
          schemes.push({ ...scheme.data(), id: scheme.id });
        });
        dispatch({
          type: actionType.GET_SCHEMES_FROM_DB,
          payload: schemes,
        });
      });
  } catch ( err ) {
    // console.error( err );
  }
};

export const getScheme = ( id: string ): ThunkAction<
void, RootState, null, SchemeActions> => async ( dispatch ) => {
  try {
    const data = await db.collection( COLLECTION_SCHEME )
      .doc( id )
      .get();
    if ( data.exists ) {
      const scheme = { ...data.data(), id };
      dispatch({
        type: actionType.SET_SCHEME_TO_EDIT_SUCCESS,
        payload: scheme,
      });
    }
  } catch ( err ) {
    // console.error( err );
  }
};
export const createScheme = (
  data: any,
  tasks: string[] | undefined,
  indexPartial: number,
  idPeriod: string,
  idSubject: string,
  external: boolean,
)
: ThunkAction<void, RootState, null, SchemeActions> => async ( dispatch ) => {
  const dataToSave = {
    ...data,
    deleted: false,
    tasks,
    indexPartial,
    idPeriod,
    idSubject,
    external,
  };
  try {
    await db.collection( COLLECTION_SCHEME ).add( dataToSave );
    dispatch({
      type: actionType.SET_CREATE_SCHEME_SUCCESS,
    });
    toast.success( 'Nuevo esquema agregada.' );
  } catch ( err ) {
    // console.error( err );
  }
};
export const updateScheme = ( id: string, data: any, tasks: string[] | undefined, indexPartial: number, idPeriod: string ): ThunkAction<
void, RootState, null, SchemeActions> => async ( dispatch ) => {
  const dataToSave = {
    ...data,
    deleted: false,
    tasks,
    indexPartial,
    idPeriod,
  };
  try {
    await db.collection( COLLECTION_SCHEME ).doc( id ).update( dataToSave );
    dispatch({
      type: actionType.SET_UPDATE_SCHEME_SUCCESS,
    });
    toast.success( 'Esquema editado correctamente.' );
  } catch ( err ) {
    // console.error( err );
  }
};
export const deleteScheme = ( id: string ): ThunkAction<
void, RootState, null, SchemeActions> => async ( dispatch ) => {
  try {
    await db
      .collection( COLLECTION_SCHEME )
      .doc( id )
      .update({ deleted: true })
      .then(() => {
        dispatch({
          type: actionType.SET_DELETE_SCHEME_SUCCESS,
        });
      });
    toast.success( 'Esquema eliminado correctamente.' );
  } catch ( err ) {
    // console.error( err );
  }
};
export const setLoadingScheme = ( state: boolean ): ThunkAction<
void, RootState, null, SchemeActions> => async ( dispatch ) => {
  dispatch({
    type: actionType.SET_LOADING_SCHEME,
    payload: state,
  });
};

export const clearById = ():
ThunkAction<void, RootState, null, SchemeActions> => async ( dispatch ) => {
  dispatch({
    type: actionType.CLEAR_SCHEME_BY_ID,
    payload: {},
  });
};
