import React, { useEffect, useState } from 'react';

// Material Ui
import {
  Typography,
  Box,
  Avatar,
  Paper,
} from '@material-ui/core';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ISubject } from '../../interfaces/ISubject';
import { ITeacher } from '../../interfaces/ITeacher';
import { actions } from '../../redux/ducks/TeacherDucks';
import { RootState } from '../../redux/store';
import { useStyles } from './Styles';
import CenterInLine from '../Center/CenterInLine';
import Image from '../Image/Image';
import ExclamationIcon1 from '../../assets/svg/alertIconRed.svg';
import ExclamationIcon2 from '../../assets/svg/alertIconYellow.svg';
import ExclamationIcon3 from '../../assets/svg/alertIconGreen.svg';
import { auth } from '../../services/firebase';
import {
  getTaskByIdSubject,
  getTaskFinished,
  getTaskDelayed,
  getTaskUnrealized,
  getAverage,
} from '../../services/Task.Services';

interface ISubjectItemProps {
  subject: ISubject
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  student?: boolean;
  onClick: any;
}

const SubjectGradeItemStudent: React.FC<ISubjectItemProps> = ( props ) => {
  const {
    subject,
    onClick,
  } = props;

  const teachers: ITeacher[] = useSelector(
    ( state: RootState ) => state.teacher.teachers,
  );

  const [teacherById, setTeacherById] = useState<ITeacher>();
  const [finished, setFinished] = useState( 0 );
  const [delayed, setDelayed] = useState( 0 );
  const [unrealized, setUnrealized] = useState( 0 );
  const [average, setAverage] = useState( 0 );

  const classes = useStyles();

  const dispatch = useDispatch();

  const findTeacher = (): void => {
    const teacher = teachers.find(
      ( item: ITeacher ) => item.id === subject.teacherId,
    );
    setTeacherById( teacher );
  };

  useEffect(() => {
    let isSubscribed = true;
    if ( auth.currentUser && isSubscribed ) {
      const { uid } = auth.currentUser;
      if ( teachers.length <= 0 ) {
        dispatch( actions.getTeachers());
      }
      findTeacher();
      handleGetTaskByIdSubject( subject.id, uid );
      isSubscribed = false;
    }
    return () => {
      isSubscribed = false;
    };
  }, [props]);

  const handleGetTaskByIdSubject = async (
    idSubject: string, idUser: string,
  ): Promise<void> => {
    const resTask = await getTaskByIdSubject( idSubject, idUser );
    setFinished( getTaskFinished( resTask, idUser ));
    setDelayed( getTaskDelayed( resTask ));
    setUnrealized( getTaskUnrealized( resTask, idUser ));
    setAverage( getAverage( resTask, idUser ));
  };

  const handleOnClick = (): void => {
    if ( teacherById ) {
      onClick( subject.id, teacherById.id );
    }
  };

  return (
    <Box
      className={classes.root}
    >
      <Paper
        className={`${classes.card} ${classes.subject}`}
        elevation={5}
        onClick={() => handleOnClick()}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <Typography
              className={classes.title}
            >
              {subject.name}
            </Typography>
            <Typography
              className={classes.subtitle}
            >
              {teacherById && ( <span>{teacherById.name}</span> )}
            </Typography>
          </Box>
          <Avatar
            src={teacherById && ( teacherById.photo )}
            className={classes.avatar}
          />
          <Box className={classes.containerTask}>
            <span className={classes.titleTask}>Estado de tareas</span>
            <Box className={classes.containerIconsTasks}>
              <Box className={classes.containerIcon}>
                <Box className={classes.icon}>
                  <Image src={ExclamationIcon1} />
                </Box>
                <span>{unrealized}</span>
              </Box>
              <Box className={classes.containerIcon}>
                <Box className={classes.icon}>
                  <Image src={ExclamationIcon2} />
                </Box>
                <span>{delayed}</span>
              </Box>
              <Box className={classes.containerIcon}>
                <Box className={classes.icon}>
                  <Image src={ExclamationIcon3} />
                </Box>
                <span>{finished}</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Paper
        className={`${classes.card} ${classes.calification}`}
        elevation={5}
      >
        <CenterInLine>
          <span className={classes.titleCal}>
            {average.toFixed( 2 )}
          </span>
        </CenterInLine>
      </Paper>
    </Box>
  );
};

export default SubjectGradeItemStudent;
