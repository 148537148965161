import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    columnGap: 20,
    rowGap: 10,
    flexWrap: 'wrap',
  },
  subject: {
    flex: 1,
  },
  calification: {
    flex: 0.2,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
  },
});
