import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { toast } from 'react-toastify';
import { ITeacher } from '../../interfaces/ITeacher';
import { RootState } from '../store';
import { db, storage, auth } from '../../services/firebase';
import { api } from '../../services/api-functions';
import { deleteUserAccountThunk } from './LoginDucks';

const COLLECTION_DB = 'users';

// action types
export const actionType = {
  GET_TEACHER_REQUEST: 'GET_TEACHER_REQUEST',
  GET_TEACHERS_FROM_DB: 'GET_TEACHERS_FROM_DB',
  SET_CREATE_TEACHER_SUCCESS: 'SET_CREATE_TEACHER_SUCCESS',
  SET_TEACHER_TO_EDIT_SUCCESS: 'SET_TEACHER_TO_EDIT_SUCCESS',
  SET_DELETE_TEACHER_SUCCESS: 'SET_DELETE_TEACHER_SUCCESS',
  SET_UPDATE_TEACHER_SUCCESS: 'SET_UPDATE_TEACHER_SUCCESS',
  UPLOAD_TEACHER_BATCH_REQUEST: 'UPLOAD_TEACHER_BATCH_REQUEST',
  UPLOAD_TEACHER_BATCH_SUCCESS: 'UPLOAD_TEACHER_BATCH_SUCCESS',
  UPLOAD_TEACHER_BATCH_ERROR: 'UPLOAD_TEACHER_BATCH_ERROR',
  CHANGE_STATUS_BUTTON_SUCCESS: 'CHANGE_STATUS_BUTTON_SUCCESS',
  SET_LOADING_TEACHER: 'SET_LOADING_TEACHER',
};

// Interfaces
export interface BookState {
  teachers: ITeacher[] | null;
  teacherById: null;
}

export interface setGetTeachersSuccess {
  type: typeof actionType.GET_TEACHERS_FROM_DB;
  payload: ITeacher[];
}

export interface setCreateTeacherSuccess {
  type: typeof actionType.SET_CREATE_TEACHER_SUCCESS;
}

export interface setDeleteTeacherSuccess {
  type: typeof actionType.SET_CREATE_TEACHER_SUCCESS;
}

export interface setTeacherToEditSuccess {
  type: typeof actionType.SET_TEACHER_TO_EDIT_SUCCESS;
  payload: ITeacher;
}

export interface setTeacherUpdateSuccess {
  type: typeof actionType.SET_UPDATE_TEACHER_SUCCESS;
}

export interface setLoadingTeacher {
  type: typeof actionType.SET_LOADING_TEACHER;
  payload: boolean;
}

// Thunk Types
type TeacherActions =
  | setGetTeachersSuccess
  | setCreateTeacherSuccess
  | setDeleteTeacherSuccess
  | setTeacherToEditSuccess
  | setLoadingTeacher;

// initial state
const initialState = {
  teachers: [] as any,
  teacherById: null,
  loading: false,
  uploadSuccess: false,
};

// reducer
export default function bookReducer(
  state = initialState, action: AnyAction,
): any {
  const { type, payload } = action;

  switch ( type ) {
    case actionType.GET_TEACHERS_FROM_DB:
      return {
        ...state,
        teachers: payload,
      };
    case actionType.SET_TEACHER_TO_EDIT_SUCCESS:
      return {
        ...state,
        teacherById: payload,
        loading: false,
      };
    case actionType.GET_TEACHER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SET_CREATE_TEACHER_SUCCESS:
    case actionType.SET_UPDATE_TEACHER_SUCCESS:
    case actionType.SET_DELETE_TEACHER_SUCCESS:
      return state;
    case actionType.UPLOAD_TEACHER_BATCH_REQUEST:
      return {
        ...state,
        loading: true,
        uploadSuccess: false,
      };
    case actionType.UPLOAD_TEACHER_BATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadSuccess: true,
      };
    case actionType.UPLOAD_TEACHER_BATCH_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.CHANGE_STATUS_BUTTON_SUCCESS:
      return {
        ...state,
        uploadSuccess: payload,
      };
    case actionType.SET_LOADING_TEACHER:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}

// actions creators
export const actions = {
  uploadTeacherBatchRequest: (): any => (
    { type: actionType.UPLOAD_TEACHER_BATCH_REQUEST }),
  uploadTeacherBatchSuccess: (): AnyAction => (
    { type: actionType.UPLOAD_TEACHER_BATCH_SUCCESS }),
  uploadTeacherBatchError: ( payload: string ): AnyAction => ({
    type: actionType.UPLOAD_TEACHER_BATCH_ERROR,
    payload,
  }),
  changeStatusSuccessButton: ( payload: boolean ): AnyAction => ({
    type: actionType.CHANGE_STATUS_BUTTON_SUCCESS,
    payload,
  }),
  getTeacherByIdRequest: (): AnyAction => (
    { type: actionType.GET_TEACHER_REQUEST }),
  getTeachers:
    (): ThunkAction<
    void, RootState, null, TeacherActions> => async ( dispatch ) => {
      try {
        db.collection( COLLECTION_DB )
          .onSnapshot(( querySnapshot ) => {
            const teachers: ITeacher[] = [];
            querySnapshot.forEach(( doc ) => {
              const teacher = doc.data() as ITeacher;
              teacher.id = doc.id;
              if ( !teacher.deleted && teacher.type === 2 ) {
                teachers.push( teacher );
              }
            });
            dispatch({
              type: actionType.GET_TEACHERS_FROM_DB,
              payload: teachers,
            });
          });
        // console.log( 'Docentes cargados Correctamente' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  getTeacher:
    ( id: string ): ThunkAction<
    void, RootState, null, TeacherActions> => async ( dispatch ) => {
      dispatch( actions.getTeacherByIdRequest());
      try {
        const data = await db.collection( COLLECTION_DB ).doc( id ).get();
        if ( data.exists ) {
          const teacherSelected = { ...data.data(), id };
          dispatch({
            type: actionType.SET_TEACHER_TO_EDIT_SUCCESS,
            payload: teacherSelected,
          });
          // console.log( 'Docente cargado Correctamente' );
        }
      } catch ( err ) {
        // console.error( err );
      }
    },
  createTeacher:
    ( data: ITeacher, file: File ): ThunkAction<
    void, RootState, null, TeacherActions> => async ( dispatch ) => {
      const dataToSave = {
        ...data,
        deleted: false,
      };
      try {
        const result = await api.post(
          '/api/create-user-account', {
            ...data,
          },
        );
        const { id } = result.data;
        const userNew = { ...dataToSave, id };
        toast.success( 'Nuevo docente agregado.' );
        dispatch( actions.updateTeacher( id, userNew, file ));
      } catch ( err ) {
        // console.error( err );
      }
    },
  updateTeacher:
    ( id: string, data: ITeacher, file?: File ): ThunkAction<
    void, RootState, null, TeacherActions> => async ( dispatch ) => {
      try {
        let teacher;
        let url;
        if ( file ) {
          const storageRef = storage.ref();
          const response = await storageRef
            .child( `teachers` )
            .child( `${id}.jpg` )
            .put( file );
          url = await response.ref.getDownloadURL();
          const { type, ...rest } = data;
          teacher = rest;
          teacher.photo = url;
          toast.success( 'Docente editado correctamente.' );
        } else {
          const { photo, type, ...rest } = data;
          teacher = rest;
        }

        await db.collection( COLLECTION_DB ).doc( id ).update( teacher );
        dispatch({
          type: actionType.SET_UPDATE_TEACHER_SUCCESS,
        });
        // console.log( 'Actualizado Correctamente' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  deleteTeacher:
    ( id: string ): ThunkAction<
    void, RootState, null, TeacherActions> => async ( dispatch ) => {
      try {
        if ( !auth.currentUser ) {
          return;
        }
        await db
          .collection( COLLECTION_DB )
          .doc( id )
          .update({ deleted: true })
          .then(() => {
            dispatch({
              type: actionType.SET_DELETE_TEACHER_SUCCESS,
            });
          });
        dispatch( deleteUserAccountThunk( id ));
        toast.success( 'Docente eliminado correctamente.' );
        // console.log( 'Eliminado Correctamente' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  createTeachersBatchThunk:
    ( data: ITeacher[]): ThunkAction<
    void, RootState, null, TeacherActions> => async ( dispatch ) => {
      dispatch( actions.uploadTeacherBatchRequest());
      try {
        const res = await api.post(
          '/api/create-users-batch', {
            users: data,
          },
        );
        if ( res.data.error !== undefined ) {
          // eslint-disable-next-line no-console
          console.log( res.data.error );
          if ( res.data.error.code === 'auth/invalid-email' ) {
            toast.error( `El formato de un correo es 
            incorrecto reviselos por favor.`, {
              autoClose: false,
            });
            if ( res.data.item !== undefined ) {
              toast.error( `El formato del correo
              ${res.data.item.email} es incorrecto.`, {
                autoClose: false,
              });
            }
          }

          if ( res.data.error.code === 'auth/email-already-exists' ) {
            toast.error( `Uno de los correos ya esta 
            registrado con otro usuario.`, {
              autoClose: false,
            });
            if ( res.data.item !== undefined ) {
              toast.error( `El correo ${res.data.item.email}
              ya esta registrado, modifiquelo y vuelva a intentarlo.`, {
                autoClose: false,
              });
            }
          }
          dispatch(
            actions.uploadTeacherBatchError(
              'Error al cargar los datos.',
            ),
          );
          return;
        }
        dispatch( actions.uploadTeacherBatchSuccess());
      } catch ( error ) {
        dispatch(
          actions.uploadTeacherBatchError(
            'No se pudo cargar la lista de docentes.',
          ),
        );
      }
    },
  setLoadingTeachers:
    ( state: boolean ): ThunkAction<
    void, RootState, null, TeacherActions> => async ( dispatch ) => {
      dispatch({
        type: actionType.SET_LOADING_TEACHER,
        payload: state,
      });
    },
};
