import React from 'react';
import { Paper } from '@material-ui/core';
import useStyles from './Styles';

interface IProps {
  // eslint-disable-next-line react/no-unused-prop-types
  idAct: string;
  name: string;
  rubric: string;
  calification: string;
}

const ItemTaskNote:React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const {
    name, rubric,
    calification,
  } = props;

  return (
    <div className={classes.itemRubric}>
      <span className={classes.titleItem}>
        {name}
      </span>
      <div className={classes.row}>
        <span className={classes.rubric}>
          {rubric}
        </span>
        <Paper className={classes.calification} elevation={1}>
          <span>
            {calification}
          </span>
        </Paper>
      </div>
    </div>
  );
};

export default ItemTaskNote;
