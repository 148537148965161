import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Grid } from '@material-ui/core';
import IItem from '../../interfaces/IItem';
import ItemMovable from '../ItemMovable/ItemMovable';
import BoxResponse from '../BoxQuestion/BoxQuestion';
import BoxOptions from '../BoxOptions/BoxOptions';
import { IQuestion } from '../../../../../interfaces/IQuestion';
import useStyles from './Styles';

interface IProps {
  data: IQuestion;
  onSave: any;
}

const ItemDragAndDrop: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const { data, onSave } = props;

  const [items, setItems] = useState<IItem[]>([
    {
      id: 1, name: data.optionCorrect, column: 'options', response: true,
    },
    {
      id: 2, name: data.optionIncorrect1, column: 'options', response: false,
    },
    {
      id: 3, name: data.optionIncorrect2, column: 'options', response: false,
    },
    {
      id: 4, name: data.optionIncorrect3, column: 'options', response: false,
    },
  ]);

  const returnItemsForColumn = (
    columnName: string,
  ): JSX.Element | JSX.Element[] => {
    const itemsCopy = items.sort(() => Math.random() - 0.5 );
    const list = itemsCopy.filter(
      ( item: IItem ) => item.column === columnName
      && item.name !== undefined,
    );
    return (
      list.map(( item: IItem ) => (
        <ItemMovable
          key={item.id}
          id={item.id || 0}
          name={item.name || ''}
          setItems={setItems}
          onSave={handleGetCalification}
        />
      ))
    );
  };

  const handleGetCalification = ( res: string, id: number ): void => {
    const item = items.find(( x ) => x.id === id );
    if ( item?.response ) {
      onSave( true, data.id, res );
    } else {
      onSave( false, data.id, res );
    }
  };

  return (
    <div className={classes.root}>
      <DndProvider backend={HTML5Backend}>
        <Grid container wrap="wrap">
          <Grid item xs={12} sm={8}>
            <BoxResponse title={data.sentence} id="question">
              {returnItemsForColumn( 'question' )}
            </BoxResponse>
          </Grid>
          <Grid item xs={12} sm={4}>
            <BoxOptions title="Opciones" id="options">
              {returnItemsForColumn( 'options' )}
            </BoxOptions>
          </Grid>
        </Grid>
      </DndProvider>
    </div>
  );
};

export default ItemDragAndDrop;
