import React, { useEffect, useState } from 'react';
// Interface
import { useSelector } from 'react-redux';
// Redux
import {
  Box,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import { useParams } from 'react-router';
import { RootState } from '../../../../redux/store';
// Components
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { ISubject } from '../../../../interfaces/ISubject';
import GradeUserCard from '../../../../components/GradeUserCard/GradeUserCard';
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import GradeAccordion from './components/GradeAccordion/GradeAccordion';
import { getAverageReports } from '../../../../services/Report.Services';
import useStyles from './Styles';

interface Params {
  idSubject: string;
  idStudent: string;
  idTeacher?: string;
}

const GradeDetail: React.FC = () => {
  const params = useParams<Params>();
  const classes = useStyles();
  const [average, setAverage] = useState<number>( 0 );

  const subject: ISubject = useSelector(
    ( state: RootState ) => state.subject.formData,
  );

  const { formData } = useSelector(( state: RootState ) => state.institute );

  useEffect(() => {
    handleGetAverage();
  }, [formData]);

  const handleGetAverage = async (): Promise<void> => {
    const total = await getAverageReports(
      formData.period.id,
      params.idSubject,
      params.idStudent,
    );
    setAverage( total );
  };

  return (
    <>
      <div className={classes.card}>
        <ContainerBreadcrumbs>
          {
            !params.idTeacher
              ? (
                <Breadcrumbs
                  itemsLinks={[
                    { name: 'Calificaciones', url: 'docente/calificaciones' },
                    {
                      name: subject ? subject.name : 'Materia',
                      url: subject
                        ? `docente/calificaciones/detalle/${subject.id}/${subject.courseId}`
                        : '',
                    },
                  ]}
                  itemsText={['Calificaciones']}
                />
              )
              : (
                <Breadcrumbs
                  itemsLinks={[
                    {
                      name: 'Calificaciones',
                      url: 'estudiante/calificaciones',
                    },
                  ]}
                  itemsText={[subject ? subject.name : 'Calificaciones']}
                />

              )
          }
        </ContainerBreadcrumbs>
        {/* <div className={classes.send}>
          <ButtonIcon primary handleClick={handleCreatePdf}>
            <IconM.CloudDownload className={classes.iconSend} />
          </ButtonIcon>
          <span>Descargar</span>
        </div> */}
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <Card>
            <CardContent>
              <Grid container>
                <GradeUserCard
                  studentId={params.idStudent}
                  teacherId={params.idTeacher}
                />
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <h4>{subject && ( subject.name )}</h4>
                    <h2 className={classes.orange}>Promedio</h2>
                    <h2>{average.toFixed( 2 )}</h2>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container spacing={2}>
            {formData && formData.partial.map(( item: any, i: number ) => (
              <GradeAccordion
                idReport={
                  `${formData.period.id}${params.idSubject}${params.idStudent}`
                }
                partial={item}
                key={i.toString()}
                index={i}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GradeDetail;
