/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, useState } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import * as IconM from '@material-ui/icons/';
import ToolTip from '../ToolTip/ToolTip';

const useStyles = makeStyles(( ) => ({
  containerInput: {
    padding: 10,
  },
  input: {
    display: 'none',
  },
  button: {
    textTransform: 'initial',
  },
  block: {
    display: 'block',
    marginBottom: 10,
  },
}));

interface IPhotoUploadProps {
  // eslint-disable-next-line react/require-default-props
  title?: string;
  // eslint-disable-next-line react/require-default-props
  setFile?: any;
}

const PhotoUpload: React.FC<IPhotoUploadProps> = ( props ) => {
  const classes = useStyles();
  const { title, setFile } = props;
  const [valueInput, setValueInput] = useState( '' );
  const [labelValue, setLabelValue] = useState( title );

  const handleChange = ( e: ChangeEvent<HTMLInputElement> ): void => {
    if ( e.target.files && e.target.files.length > 0 ) {
      setLabelValue( e.target.files[0].name );
      setFile( e.target.files[0]);
    }
    setValueInput( '' );
    // onChange( e.target.files ? e.target.files[0] : e.target.files );
  };

  return (
    <div>
      <input
        type="file"
        value={valueInput}
        onChange={handleChange}
        className={classes.input}
        multiple
        id="contained-button-file"
      />
      <label htmlFor="contained-button-file">
        <ToolTip title={labelValue || ''}>
          <IconButton
            component="span"
          >
            <IconM.AddAPhoto />
          </IconButton>
        </ToolTip>
      </label>
    </div>
  );
};

export default PhotoUpload;
