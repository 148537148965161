import React, { useState, useEffect } from 'react';
import * as IconM from '@material-ui/icons/';
import CenterInLine from '../../../../../../components/Center/CenterInLine';
import { IQuestion } from '../../../../../../interfaces/IQuestion';
import useStyles from './Styles';

interface IProps {
  data: IQuestion;
  response: string;
}

const ItemComplete: React.FC<IProps> = ( props ) => {
  const { data: { question = '', ...rest }, response } = props;
  const [correct, setCorrect] = useState<boolean>( false );
  const classes = useStyles( correct );

  const handleCreateQuestion = (): JSX.Element[] => {
    const str = question.split( ' ' );
    return (
      str.map(( item: string, i: number ) => (
        !item.includes( '**' )
          ? (
            <span key={i.toString()} className={classes.sentenceItem}>
              {item}
            </span>
          )
          : (
            <div
              key={i.toString()}
              className={classes.contentResponse}
            >
              <div className={classes.response}>
                <CenterInLine>
                  <span>{response}</span>
                </CenterInLine>
              </div>
              <span>
                {rest.answer}
              </span>
              {
                correct
                  ? <IconM.CheckCircle />
                  : <IconM.HighlightOff />
              }
            </div>
          )
      ))
    );
  };

  useEffect(() => {
    setCorrect( isCorrect());
  }, [rest]);

  const isCorrect = (): boolean => {
    const { answer } = rest;
    return answer?.toUpperCase() === response?.toUpperCase();
  };

  return (
    <div className={classes.root}>
      {handleCreateQuestion()}
    </div>
  );
};

export default ItemComplete;
