import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import * as IconM from '@material-ui/icons/';
import ToolTip from '../../../../../../components/ToolTip/ToolTip';
import useStyles from './Styles';

interface IProps {
  title: string;
  subtitle: string;
  handleEdit: any;
  handleDelete: any;
  // eslint-disable-next-line react/require-default-props
  showEdit?: boolean;
}

const ItemActivityList: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const {
    title,
    subtitle,
    handleDelete,
    handleEdit,
    showEdit,
  } = props;

  return (
    <List dense component="span">
      <ListItem>
        <ListItemIcon>
          <ListItemText primary={title} />
        </ListItemIcon>
        <ListItemText
          primary={`${subtitle}`}
          className={classes.subtitle}
        />
        {
          showEdit && (
            <ListItemIcon>
              <ToolTip title="Editar">
                <IconButton onClick={handleEdit}>
                  <IconM.Edit />
                </IconButton>
              </ToolTip>
            </ListItemIcon>
          )
        }
        <ListItemIcon>
          <ToolTip title="Eliminar">
            <IconButton onClick={handleDelete}>
              <IconM.Delete />
            </IconButton>
          </ToolTip>
        </ListItemIcon>
      </ListItem>
    </List>
  );
};

export default ItemActivityList;
