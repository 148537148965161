import React from 'react';

// Material Ui
import {
  Avatar,
  Box,
  Grid,
  Card,
  Fab,
} from '@material-ui/core';
import gradeIcon from '../../assets/svg/gradeIcon.svg';
import { useStyles } from './Styles';

interface ITaskRateGradeProps {
  note: any;
  onQualify: any;
}

const TaskRateGrade: React.FC<ITaskRateGradeProps> = ( props ): any => {
  const classes = useStyles();
  const {
    note, onQualify,
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={10}>
        <Card className={classes.card}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              gridColumnGap={10}
            >
              <Avatar src={note.student.photo} alt="photoStudent" />
              <h4>
                {note.student.name}
              </h4>
            </Box>
            <Box>
              <Fab
                size="small"
                onClick={onQualify}
              >
                <img
                  src={gradeIcon}
                  alt="editHomework"
                  className={classes.svgEdit}
                />
              </Fab>
            </Box>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Card className={classes.card}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.itemCalification}
          >
            {note.noteFinal < 6 && (
              <span
                className={`${classes.red}`}
              >
                {note && note.noteFinal}
              </span>
            )}
            {note.noteFinal >= 6 && note.noteFinal <= 7 && (
              <span
                className={`${classes.yellow}`}
              >
                {note && note.noteFinal}
              </span>
            )}
            {note.noteFinal > 7 && note.noteFinal <= 9 && (
              <span
                className={`${classes.green}`}
              >
                {note && note.noteFinal}
              </span>
            )}
            {note.noteFinal > 9 && (
              <span
                className={`${classes.blue}`}
              >
                {note && note.noteFinal}
              </span>
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TaskRateGrade;
