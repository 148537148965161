import React, { useEffect } from 'react';

// Material Ui
import {
  Box, CardContent, Grid, Paper, makeStyles,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import {
  actions as actionsBook,
} from '../../redux/ducks/BookDucks';
import {
  actions as actionsUnit,
} from '../../redux/ducks/UnitDucks';

// Icons¿
import { ITask } from '../../interfaces/ITask';
import Card from '../Card/Card';
import { RootState } from '../../redux/store';

interface ITaskRateInfoProps {
  task: ITask | any;
}

const useStyles = makeStyles(() => ({
  paper: {
    padding: '1em',
    marginLeft: '1em',
    maxWidth: '15em',
  },
}));

const TaskRateInfo: React.FC<ITaskRateInfoProps> = ( props ): any => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    task,
  } = props;

  const { bookById } = useSelector(( state: RootState ) => state.book );
  const { unitById } = useSelector(( state: RootState ) => state.unit );

  useEffect(() => {
    dispatch( actionsBook.getBook( task && task.bookId ));
    dispatch( actionsUnit.getUnit( task && task.unitId ));
  }, [props]);

  return (
    <Card>
      <CardContent>
        <h3>Detalles de la tarea</h3>
        <Box display="flex" alignItems="center">
          <Grid container>
            <Grid item xs={12} sm={2}>
              <h4>Fecha y hora de entrega: </h4>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper elevation={3} className={classes.paper}>
                {task ? task.dateEnd : 'N/A'}
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={2}>
            <h4>Parcial: </h4>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.paper}>
              {task && task.partial ? task.partial : 'N/A'}
            </Paper>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={2}>
            <h4>Libro: </h4>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} className={classes.paper}>
              {bookById ? bookById.name : 'N/A'}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={2}>
            <h4>Unidad: </h4>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} className={classes.paper}>
              {unitById ? unitById.name : 'N/A'}
            </Paper>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
};

export default TaskRateInfo;
